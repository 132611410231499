import React from "react";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter, Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
import TextField from "../common/TextField";
import TablePaginationActionsWrapped from "../common/Paginator";
import Icon from "@mdi/react";
import { ProjectForm } from "../wells/models";
import { mdiUndo, mdiPlus } from "@mdi/js";
import { createSelector } from "../common/orm";

const projForm = ProjectForm.selectAll();

const repeatLastStatus = createSelector((session, form) => {
	return session.ProjectRepeatableTable.all()
		.orderBy("id", "desc")
		.toRefArray();
});
const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	deleteWidth: {
		minWidth: 34,
		width: 34,
		marginRight: 8,
	},
	table: {
		width: "100%",
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	actionCell: {
		minWidth: 90,
	},
});

const columnData = [
	{ id: "actions", numeric: false, label: "Actions", allowSort: false },
	{
		id: "project_id",
		numeric: false,
		label: "Project ID",
		allowSort: true,
	},
	{
		id: "project_name",
		numeric: false,
		label: "Project Name",
		allowSort: true,
	},
	{
		id: "source_gsa_label",
		numeric: false,
		label: "GSA",
		allowSort: true,
	},
	{
		id: "record_type",
		numeric: false,
		label: "Record Type",
		allowSort: true,
	},
	{
		id: "project_status",
		numeric: false,
		label: "Project Status",
		allowSort: true,
	},
	{
		id: "project_mechanism",
		numeric: false,
		label: "Project Mechanism",
		allowSort: true,
	},
];

class AdminProjects extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: null,
			order: "asc",
			orderBy: null,
			page: 0,
			rowsPerPage: 25,
			showDelete: false,
			deleteId: null,
			deleteName: null,
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		if (
			authState &&
			authState.user &&
			authState.user.role !== "Administrator"
		) {
			history.push("/dashboard");
		}
	}

	filterByValue = (array, string) => {
		return array.filter((o) =>
			Object.keys(o).some(
				(k) =>
					o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())
			)
		);
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		//  null values break the sorting so set null values to ""
		this.props.projforms.forEach((s) => {
			if (s[orderBy] === null) {
				s[orderBy] = "";
			}
		});

		order === "desc"
			? this.props.projforms.sort((a, b) =>
					b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1
			  )
			: this.props.projforms.sort((a, b) =>
					a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1
			  );

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	render() {
		const {
			classes,
			history,
			projforms,
			ormProjectFormDelete,
			repeatlaststatus,
		} = this.props;
		const {
			order,
			orderBy,
			filter,
			page,
			rowsPerPage,
			showDelete,
			deleteName,
			deleteId,
		} = this.state;

		var filtered_items;
		if (filter) {
			filtered_items = this.filterByValue(projforms, filter);
		} else {
			filtered_items = projforms;
			if (repeatlaststatus) {
				//get last status to add to record on table
				let temp = [];
				filtered_items.forEach((fi) => {
					let projstatus = repeatlaststatus.find((i) =>
						i.projectform_id === fi.id ? i : null
					);
					if (projstatus) fi.projectstatus = projstatus.project_status;
					temp.push(fi);
				});
				filtered_items = temp;
			}
		}

		return (
			<AppContainer authenticated>
				<Grid container spacing={24}>
					<Grid item xs={12} className={classes.breadCrumb}>
						<BreadcrumbNav
							level1="Administration"
							level1link="/administration"
							level2="Manage Projects or Management Actions"
							level2link="/adminprojects"
						/>
					</Grid>
					<Grid item xs={12} mg={9} lg={6} xl={3}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={() => history.push("/administration")}>
							<Icon path={mdiUndo} size={1} color="white"></Icon>
							&nbsp;&nbsp;&nbsp; Return to Administration
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6">Keyword Filter</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={7}
						lg={8}
						style={{ backgroundColor: "#ddd", marginLeft: 12 }}>
						<Form dontValidateOnMount={true} validateOnSubmit={true}>
							{(formApi) => (
								<form style={{ float: "left" }} onSubmit={formApi.submitForm}>
									<TextField
										rounded
										style={{ borderRadius: 114 }}
										placeholder="Filter Projects"
										startAdornment={
											<InputAdornment style={{ marginRight: 8 }}>
												<SearchIcon />
											</InputAdornment>
										}
										eventHandle={(val) => {
											this.setState({ filter: val });
										}}
										field="filter"
										label=""
									/>
								</form>
							)}
						</Form>
					</Grid>
					<Grid item xs={8} md={4} lg={3} style={{ marginTop: 8 }}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							component={Link}
							to={"/adminprojects/new"}>
							<Icon path={mdiPlus} size={1} color="white"></Icon>
							&nbsp;&nbsp;&nbsp; Add Projects or Management Action
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Table className={classes.table}>
							<EnhancedTableHead
								columnData={columnData}
								order={order}
								orderBy={orderBy}
								onRequestSort={this.handleRequestSort}
							/>
							<TableBody>
								{filtered_items
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((item) => (
										<TableRow key={item.id}>
											<TableCell className={classes.actionCell}>
												<Tooltip title="Edit Project">
													<Button
														className={classes.deleteWidth}
														component={Link}
														to={"/adminprojects/" + item.id}>
														<EditIcon color="primary" />
													</Button>
												</Tooltip>
												<Tooltip title="Delete Project">
													<Button
														className={classes.deleteWidth}
														onClick={() =>
															this.setState({
																deleteName: item.dms_site_id,
																deleteId: item.id,
																showDelete: true,
															})
														}>
														<DeleteIcon style={{ color: "#b20000" }} />
													</Button>
												</Tooltip>
											</TableCell>
											<TableCell>{item.project_id}</TableCell>
											<TableCell>{item.project_name}</TableCell>
											<TableCell>{item.source_gsa_label}</TableCell>
											<TableCell>{item.record_type}</TableCell>
											<TableCell>{item.projectstatus}</TableCell>

											<TableCell>{item.project_mechanism}</TableCell>
										</TableRow>
									))}
								{filtered_items.length < 1 && (
									<TableRow>
										<TableCell colSpan={4} className={classes.centerAlign}>
											No Projects Found
										</TableCell>
									</TableRow>
								)}
							</TableBody>
							{filtered_items.length > 25 && (
								<TableFooter>
									<TableRow>
										<TablePagination
											colSpan={4}
											count={filtered_items.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={this.handleChangePage}
											onChangeRowsPerPage={this.handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActionsWrapped}
										/>
									</TableRow>
								</TableFooter>
							)}
						</Table>
					</Grid>
				</Grid>
				<WarningDialog
					confirmAction={() => {
						ormProjectFormDelete(deleteId);
						this.setState({ showDelete: false });
					}}
					cancelAction={() => this.setState({ showDelete: false })}
					open={showDelete}
					title="Delete Project"
					confirmText="Delete Project"
					text={"Are you sure you wish to delete " + deleteName + "?"}
				/>
			</AppContainer>
		);
	}
}

AdminProjects = connect(
	(state, ownProps) => ({
		projforms: projForm(state),
		repeatlaststatus: repeatLastStatus(state),
		authState: state.auth,
	}),
	{
		...ProjectForm.actions,
	}
)(AdminProjects);
export default withStyles(styles)(withRouter(AdminProjects));
