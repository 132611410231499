import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import { Form } from "react-form";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "../common/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MuiTextField from "@material-ui/core/TextField";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import Typography from "@material-ui/core/Typography";
import TextField from "../common/TextField";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@mdi/react";
import { WaterYear } from "./models";
import { mdiUndo, mdiFileDocument } from "@mdi/js";

const getOptions = WaterYear.getOptions();
const waterYear = WaterYear.selectByUrlId();
const allWaterYears = WaterYear.selectAll();

// const isLocalhost = Boolean(
//   window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

// const LOCAL_URL = "http://localhost:3000";
// const PROD_URL = "https://dms2.houstoneng.net";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  deleteWidth: {
    minWidth: 34,
    width: 34,
    marginRight: 16,
  },
  table: {
    width: "100%",
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  checkAll: {
    marginTop: "-13px",
  },
  checkAllLbl: {
    paddingTop: "10px",
  },
  progressActive: {
    display: "block",
  },
  progressInactive: {
    display: "none",
  },
  selectWaterYrLbl: {
    marginLeft: "15px",
    marginRight: "15px",
    bottom: "6px",
  },
  selectImportDateLbl: {
    marginLeft: "15px",
    marginRight: "15px",
    bottom: "6px",
  },
});

class AdminEditWaterYears extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      waterYear: null,
      importStart: null,
      importEnd: null,
      waterYearInit: false,
      callOptions: false,
    };

    this.is_new = false;
    if (props.match.params["id"] === "new") this.is_new = true;
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  handleChangeWaterYear = (event) => {
    const { wateryr } = this.props;
    let activeId = wateryr.find((w) => w.id === event.target.value);

    this.setState({
      waterYear: activeId.id,
      importStart:
        activeId.import_start != null
          ? activeId.import_start
          : activeId.water_year_start,
      importEnd:
        activeId.import_end != null
          ? activeId.import_end
          : activeId.water_year_end,
    });
  };

  handleChangeImportStart = (event) => {
    this.setState({ importStart: event.target.value });
  };

  handleChangeImportEnd = (event) => {
    this.setState({ importEnd: event.target.value });
  };

  initWaterYear() {
    const { wateryr } = this.props;
    const { waterYearInit } = this.state;

    if (!waterYearInit && wateryr.length > 0) {
      let activeId = wateryr.find((w) => w.is_active === true);

      this.setState({
        waterYear: activeId.id,
        importStart:
          activeId.import_start != null
            ? activeId.import_start
            : activeId.water_year_start,
        importEnd:
          activeId.import_end != null
            ? activeId.import_end
            : activeId.water_year_end,
        waterYearInit: true,
      });
    }
  }

  setActiveWaterYear() {
    const { waterYear, importStart, importEnd } = this.state;
    const { ormWaterYearUpdate, ormWaterYearUpdateLocalOnly, wateryr } =
      this.props;

    wateryr.map((w) => {
      ormWaterYearUpdateLocalOnly({
        ...w,
        is_active: false,
      });
    });

    ormWaterYearUpdate({
      id: waterYear,
      import_start: importStart,
      import_end: importEnd,
      is_active: true,
    });
  }

  MAP_OPTIONS = (table) => {
    return table.map((row) => ({
      label: row.display_name,
      value: row.value,
    }));
  };

  MAKE_OPTIONS = (table) => {
    return table.map((row) => ({
      label: row.name,
      value: row.id,
    }));
  };

  errorValidator = (values) => {
    const validateName = (name) => {
      return !name ? "Field is required" : null;
    };

    var valObj = {};

    return valObj;
  };

  submitForm = (values) => {
    const {
      allwateryrs,
      ormWaterYearCreate,
      ormWaterYearUpdate,
      ormWaterYearUpdateLocalOnly,
      match,
      history,
    } = this.props;

    var active = values.s_is_active === "true" ? true : false;

    if (active) {
      allwateryrs.map((w) => {
        ormWaterYearUpdateLocalOnly({
          ...w,
          is_active: false,
        });
      });
    }

    if (match.params["id"] === "new") {
      var newVals = {
        water_year: values.water_year != undefined ? values.water_year : null,
        water_year_start:
          values.water_year_start != undefined ? values.water_year_start : null,
        water_year_end:
          values.water_year_end != undefined ? values.water_year_end : null,
        import_start:
          values.import_start != undefined ? values.import_start : null,
        import_end: values.import_end != undefined ? values.import_end : null,
        is_active: values.is_active != undefined ? values.is_active : null,
        year_type: values.year_type != undefined ? values.year_type : null,
      };

      ormWaterYearCreate(newVals);
    } else {
      ormWaterYearUpdate({
        id: values.id,
        ...values,
        is_active: active,
      });
      ormWaterYearUpdateLocalOnly({
        id: values.id,
        ...values,
        is_active: active,
      });
    }
    history.push("/adminwateryears");
  };

  render() {
    const { classes, history, wateryr, match, optionsReq } = this.props;
    const {
      wsChoices,
      callOptions,
      checked,
      loading,
      waterYear,
      importStart,
      importEnd,
    } = this.state;

    const tfopts = [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ];

    //OPTIONS comes back as a promise
    if (optionsReq && !callOptions) {
      optionsReq.then((data) => {
        this.setState({
          wsChoices: data,
          callOptions: true,
        });
      });
    }

    if (wateryr != null) {
      if (wateryr.is_active === true) {
        wateryr.s_is_active = "true";
      } else {
        wateryr.s_is_active = "false";
      }
    }

    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Manage Water Years"
              level1link="/adminwateryears"
              level2="Set Water Year"
              level2link={"/adminwateryears/" + match.params["id"]}
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/adminwateryears")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Water Years
            </Button>
          </Grid>
          <Form
            dontValidateOnMount="true"
            validateOnSubmit="true"
            defaultValues={wateryr}
            validateError={this.errorValidator}
            onChange={this.onChange}
            onSubmit={this.submitForm}
          >
            {(formApi) => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      classes={{ root: classes.expandedMargin }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <LocalDrinkIcon style={{ marginTop: 10 }} />
                      <Typography style={{ paddingLeft: 32 }} variant="h6">
                        Water Year
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid
                        container
                        spacing={16}
                        className={classes.borderContainer}
                      >
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="water_year"
                            label="Water Year"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <Select
                            disabled={this.is_new}
                            field="s_is_active"
                            label="Is Active"
                            options={tfopts}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="water_year_start"
                            label="Water Year Start"
                            type="date"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="water_year_end"
                            label="Water Year End"
                            type="date"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="import_start"
                            label="Data Import Start"
                            type="date"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="import_end"
                            label="Data Import End"
                            type="date"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          {wsChoices ? (
                            <Select
                              field="year_type"
                              label="Year Type"
                              options={this.MAP_OPTIONS(
                                wsChoices.year_type.choices
                              )}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <Grid
                    container
                    spacing={40}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
        </Grid>
      </AppContainer>
    );
  }
}

AdminEditWaterYears = connect(
  (state, ownProps) => ({
    optionsReq: getOptions(state, ownProps),
    wateryr: waterYear(state, ownProps),
    allwateryrs: allWaterYears(state, ownProps),
    authState: state.auth,
  }),
  {
    ...WaterYear.actions,
  }
)(AdminEditWaterYears);
export default withStyles(styles)(withRouter(AdminEditWaterYears));
