import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Form } from "react-form";
import moment from "moment";

import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
	ReferenceLine,
} from "recharts";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import PollIcon from "@material-ui/icons/Poll";
import PowerIcon from "@material-ui/icons/Power";
import RoomIcon from "@material-ui/icons/Room";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import ChevronRight from "@material-ui/icons/ChevronRight";
import MapIcon from "@material-ui/icons/Map";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import NotReady from "../common/NotReady";
import WellSiteDetails from "../../api/wellsiteDetailsHOC";

import Select from "../common/Select";
import TextField from "../common/TextField";
import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import WaterLevelDetailMap from "../common/map/WellSiteDetailMap";

import Icon from "@mdi/react";
import { mdiAlphaP, mdiAndroidStudio } from "@mdi/js";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

import { saveAs } from "file-saver";

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	expansionBorder: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
	},
	lastBorder: {
		[theme.breakpoints.up("xl")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	lastBorder2: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	lastBorder3: {
		[theme.breakpoints.down("lg")]: {
			display: "none",
		},
	},
	expandedMargin: {
		marginTop: "12px",
		minHeight: "48px !important",
		maxHeight: 48,
		backgroundColor: "#eee",
	},
	borderContainer: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
		borderRight: "2px solid " + theme.palette.primary.light,
		borderLeft: "2px solid " + theme.palette.primary.light,
	},
	borderContainer2: {
		border: "1px solid rgb(221, 221, 221)",
	},
	buttonSelected: {
		border: "3px solid " + theme.palette.primary.main,
		minWidth: "44px",
	},
	buttonNotSelected: {
		border: "3px solid #666",
		minWidth: "44px",
	},
	iconSelected: {
		color: theme.palette.primary.main,
	},
	iconNotSelected: {
		color: "#666",
	},
	divideButton: {
		marginBottom: 16,
		width: "calc(50% - 16px)",
		marginTop: 16,
		minWidth: 44,
	},
	divideContainer: {
		backgroundColor: "#ddd",
		textAlign: "center",
		margin: "0 -4px",
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		minWidth: 500,
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	marginLeft: {
		marginLeft: -16,
	},
	chartTitle: {
		backgroundColor: "rgb(221, 221, 221)",
		textAlign: "center",
		padding: "8px 4px !important",
	},
	marginTop: {
		marginTop: 8,
		marginRight: 8,
	},
});

const columnData = [
	{ id: "measurement_date", numeric: false, label: "Date", allowSort: true },
	{ id: "measurement_time", numeric: false, label: "Time", allowSort: true },
	{
		id: "measurement_depth",
		numeric: true,
		label: (
			<>
				Meas. Depth
				<br />
				from RP (ft)
			</>
		),
		allowSort: true,
	},
	{
		id: "surf_elev",
		numeric: true,
		label: (
			<>
				Water Surface
				<br />
				Elevation (ft)
			</>
		),
		allowSort: true,
	},
	{
		id: "vert_datum",
		numeric: false,
		label: "Vertical Datum",
		allowSort: true,
	},
	{ id: "meas_method", numeric: false, label: "Meas. Method", allowSort: true },
	{
		id: "meas_accuracy",
		numeric: false,
		label: "Meas. Accuracy",
		allowSort: true,
	},
	{ id: "no_measurement", numeric: false, label: "No Meas.", allowSort: true },
	{
		id: "quest_meas",
		numeric: false,
		label: "Questionable Meas.",
		allowSort: true,
	},
	{ id: "measured_by", numeric: false, label: "Meas. By", allowSort: true },
	{ id: "meas_comments", numeric: false, label: "Comments", allowSort: true },
];

class WaterLevelDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonState: "chart",
			order: "desc",
			orderBy: "measurement_date",
			page: 0,
			rowsPerPage: 10,
			y_axis: "surf_elev",
			y_axis_label: "Surface Elevation",
			start_date: null,
			end_date: null,
			min_value: null,
			max_value: null,
			measurements: [],
		};
		this.formKey = window.performance.now();
		this.printChart = this.printChart.bind(this);
	}

	componentDidUpdate() {
		const { start_date, measurements } = this.state;
		if (measurements.length > 0 && !start_date) {
			this.setState({
				start_date: this.getMinDate(measurements).getFullYear(),
				end_date: new Date().getFullYear(),
			});
			this.formKey = window.performance.now();
		}
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		this.state.measurements.sort(function (a, b) {
			var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
			if (numeric) {
				if (order === "desc")
					return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
				else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
			} else {
				if (order === "desc")
					return (b[orderBy] || "").toUpperCase() <
						(a[orderBy] || "").toUpperCase()
						? -1
						: 1;
				else
					return (a[orderBy] || "").toUpperCase() <
						(b[orderBy] || "").toUpperCase()
						? -1
						: 1;
			}
		});

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	formatDate(dateString) {
		if (!dateString) {
			return null;
		}
		const d = new Date(dateString + " 12:00:00");
		return d.toLocaleDateString();
	}

	formatTime(timeString) {
		if (!timeString) {
			return null;
		}
		const d = new Date("2000-01-01 " + timeString);
		return d.toLocaleTimeString();
	}

	getMinDate(arr) {
		return new Date(
			arr.reduce(
				(min, p) =>
					new Date(p.measurement_date + " 12:00:00") < min
						? new Date(p.measurement_date + " 12:00:00")
						: min,
				new Date(arr[0].measurement_date + " 12:00:00")
			)
		);
	}

	getMaxDate(arr) {
		return new Date(
			arr.reduce(
				(max, p) =>
					new Date(p.measurement_date + " 12:00:00") > max
						? new Date(p.measurement_date + " 12:00:00")
						: max,
				new Date(arr[0].measurement_date + " 12:00:00")
			)
		);
	}

	getMinMaxVal(arr, val, site) {
		let value = val === "surf_elev" ? "surf_elev" : "measurement_depth";
		let min = 1000000000,
			max = 0;

		for (let i = 1, len = arr.length; i < len; i++) {
			let v = arr[i][value];
			min = v < min ? v : min;
			max = v > max ? v : max;
		}

		if (value === "surf_elev") {
			if (site.gsp_threshold > max) {
				max = site.gsp_threshold;
			}
			if (
				site.measurable_objective &&
				parseFloat(site.measurable_objective) > max
			) {
				max = parseFloat(site.measurable_objective);
			}
			if (site.ground_surface_elev > max) {
				max = site.ground_surface_elev;
			}

			if (site.gsp_threshold < min) {
				min = site.gsp_threshold;
			}
			if (
				site.measurable_objective &&
				parseFloat(site.measurable_objective) < min
			) {
				min = parseFloat(site.measurable_objective);
			}
			if (site.ground_surface_elev < min) {
				min = site.ground_surface_elev;
			}
		}

		if (min && min !== 0) {
			min = this.round(min - 5, 0);
		}

		max = this.round(max + 5, 0);

		return { min, max };
	}

	round(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	printChart = () => {
		const { site } = this.state;
		var printWindow = window.open("", "PrintMap", "width=1200,height=850");
		printWindow.document.writeln(
			"DMS Site ID: " +
				site.dms_site_id +
				this.refs.chartContainer.container.innerHTML
		);
		printWindow.document.close();
	};

	handleAPIChange(data) {
		this.setState({ site: data.site, measurements: data.site.measurements });
	}

	exportData = (data, key) => {
		var huh = "Date,Water Surface Elevation,Water Depth\n";

		data.forEach((d) => {
			huh = `${huh}${d["measurement_date"]},${
				d["surf_elev"] ? d["surf_elev"] : ""
			},${d["measurement_depth"] ? d["measurement_depth"] : ""}\n`;
		});

		var filename = `${this.state.site.dms_site_id}_water_levels.csv`;
		var blob = new Blob([huh], { type: "text/plain;charset=utf-8" });
		saveAs(blob, filename);
	};

	render() {
		const { classes } = this.props;
		const {
			buttonState,
			page,
			rowsPerPage,
			order,
			orderBy,
			min_value,
			max_value,
			y_axis,
			y_axis_label,
			start_date,
			end_date,
			site,
			measurements,
		} = this.state;

		let siteId = parseInt(this.props.match.params["id"]);

		let level1 = "Well Level Monitoring";
		let level1link = "/waterlevellist";
		let level2link = "/waterleveldetail/" + siteId;

		// Custom formatting of values for chart
		if (measurements && measurements.length > 0) {
			var filtered_measurements = measurements.slice();

			filtered_measurements.forEach(function (m) {
				if (!m.chart_date && m.measurement_date) {
					var d;
					if (m.measurement_time && m.meaurement_time !== "")
						d = new Date(m.measurement_date + " " + m.measurement_time);
					else d = new Date(m.measurement_date + " 12:00:00");
					m.chart_date = d.getTime();
				}
				if (m.measurement_depth) {
					m.measurement_depth = parseFloat(m.measurement_depth);
				}
				if (m.surf_elev) {
					m.surf_elev = parseFloat(m.surf_elev);
				}
			});

			filtered_measurements.sort((m, n) => m.chart_date - n.chart_date);
			var minDate = this.getMinDate(filtered_measurements);
			var maxDate = this.getMaxDate(filtered_measurements);
			var yearoptions = [];
			var y = minDate.getFullYear();
			var z = new Date().getFullYear();
			while (y <= z) {
				yearoptions.push({ label: y, value: y });
				y++;
			}
			if (start_date) {
				filtered_measurements = filtered_measurements.filter(
					(m) =>
						new Date(m.measurement_date).getFullYear() >= parseInt(start_date)
				);
			}
			if (end_date) {
				filtered_measurements = filtered_measurements.filter(
					(m) =>
						new Date(m.measurement_date).getFullYear() <= parseInt(end_date)
				);
			}
		}

		if (site === undefined) {
			return (
				<AppContainer authenticated>
					<WellSiteDetails
						apitarget={"wellsitemeasurements"}
						siteid={siteId}
						handler={this.handleAPIChange.bind(this)}
					/>
					<NotReady message="Loading..." />
				</AppContainer>
			);
		} else {
			return (
				<AppContainer authenticated>
					<Grid container spacing={24}>
						<Grid item xs={12} className={classes.breadCrumb}>
							<BreadcrumbNav
								level1={level1}
								level1link={level1link}
								level2={"DMS ID: " + site.dms_site_id}
								level2link={level2link}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">Monitoring Site Details</Typography>
						</Grid>
						<Grid item xs={12}>
							<ExpansionPanel defaultExpanded>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<ContactMail style={{ marginTop: 5 }} />
									<Typography style={{ paddingLeft: 32 }} variant="h6">
										Identification
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>DMS Site ID</b>: {site.dms_site_id}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Local Site ID or Name</b>: {site.local_site_id}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Alternative Site ID or Name</b>: {site.alt_site_id}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>State Well Number</b>: {site.dwr_well_id}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>CASGEM Well Number</b>: {site.casgem_well_id}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Well Owner or Operator</b>: {site.well_owner_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Monitored By</b>: {site.monitor_by_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Source GSA</b>: {site.source_gsa_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Source GSP</b>: {site.source_gsp_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Well Use Type</b>: {site.well_use}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Well Status</b>: {site.well_status}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Well Completion Type</b>: {site.well_completion_type}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Well Completion Report Number</b>:{" "}
												{site.well_completion_number}
											</Typography>
										</Grid>

										<Grid
											className={classNames(
												classes.expansionBorder,
												classes.lastBorder2
											)}
											item
											xs={6}
											xl={4}></Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>

						<Grid item xs={12}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<RoomIcon style={{ marginTop: 5 }} />
									<Typography style={{ paddingLeft: 32 }} variant="h6">
										Location
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Township</b>: {site.township}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Range</b>: {site.range}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Section</b>: {site.section}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Quarter Section</b>: {site.quarter_section}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Latitude</b>:{" "}
												{site.geometry && site.geometry.coordinates[1]}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Longitude</b>:{" "}
												{site.geometry && site.geometry.coordinates[0]}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Horizontal Datum</b>: {site.horz_datum}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Coordinate Collection Method</b>:{" "}
												{site.coordinate_method}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Coordinate Accuracy (ft)</b>:{" "}
												{site.coordinate_accuracy}
											</Typography>
										</Grid>
										<Grid
											className={classNames(
												classes.expansionBorder,
												classes.lastBorder2
											)}
											item
											xs={6}
											xl={4}></Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						<Grid item xs={12}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<Icon
										path={mdiAndroidStudio}
										className={classes.marginTop}
										size={1}></Icon>
									<Typography
										style={{ paddingLeft: 16, marginTop: 8 }}
										variant="h6">
										{" "}
										Ground Surface and Reference Point Information
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Ground Surface Elevation (ft)</b>:{" "}
												{site.ground_surface_elev}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Reference Point Elevation</b>: {site.rpe}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Reference Point Description</b>: {site.rp_desc}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Vertical Datum</b>: {site.vert_datum}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>RP and GS Elevation Measurement Date</b>:{" "}
												{site.meas_elev_date}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Elevation Collection Method</b>:{" "}
												{site.elevation_source}
											</Typography>
										</Grid>
										<Grid
											className={(classes.expansionBorder, classes.lastBorder2)}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Elevation Accuracy</b>: {site.elevation_accuracy}
											</Typography>
										</Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						<Grid item xs={12}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<Icon
										path={mdiAlphaP}
										className={classes.marginLeft}
										size={2}></Icon>
									<Typography
										style={{ paddingLeft: 16, marginTop: 8 }}
										variant="h6">
										{" "}
										Plan Information
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Water Level Monitoring</b>: {site.wl_monitoring}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Water Quality Monitoring</b>: {site.wq_monitoring}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Interconnected Monitoring</b>:{" "}
												{site.interconnected_monitoring}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>GSP Monitoring Network</b>:{" "}
												{site.gsp_mon_network ? "Yes" : "No"}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>SGMA Site Monitoring Type</b>:{" "}
												{site.monitor_site_type}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Aquifer Designation / Principal Aquifer</b>:{" "}
												{site.aquifer_designation}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>GSP WL Threshold Elevation</b>: {site.gsp_threshold}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>GSP WL Measurable Objective Elevation</b>:{" "}
												{site.measurable_objective}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Year 5 Interim Milestone (2025)</b>:{" "}
												{site.yr5_milestone}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Year 10 Interim Milestone (2030)</b>:{" "}
												{site.yr10_milestone}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Year 15 Interim Milestone (2035)</b>:{" "}
												{site.yr15_milestone}
											</Typography>
										</Grid>
										<Grid
											className={(classes.expansionBorder, classes.lastBorder3)}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Well Notes</b>: {site.well_notes}
											</Typography>
										</Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						<Grid item xs={12}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<LocalDrinkIcon style={{ marginTop: 10 }} />
									<Typography
										style={{ paddingLeft: 16, marginTop: 8 }}
										variant="h6">
										{" "}
										Drilling Information
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Drill Date</b>: {site.date_drilled}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Total Well Depth (ft)</b>: {site.total_well_depth}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Intervals for Screen (ft)</b>:{" "}
												{site.perforated_intervals_screen}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 1 (ft)</b>:{" "}
												{site.perforated_intervals_range_1}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 2 (ft)</b>:{" "}
												{site.perforated_intervals_range_2}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 3 (ft)</b>:{" "}
												{site.perforated_intervals_range_3}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 4 (ft)</b>:{" "}
												{site.perforated_intervals_range_4}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 5 (ft)</b>:{" "}
												{site.perforated_intervals_range_5}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 6 (ft)</b>:{" "}
												{site.perforated_intervals_range_6}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 7 (ft)</b>:{" "}
												{site.perforated_intervals_range_7}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 8 (ft)</b>:{" "}
												{site.perforated_intervals_range_8}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 9 (ft)</b>:{" "}
												{site.perforated_intervals_range_9}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Perforated Interval Range 10 (ft)</b>:{" "}
												{site.perforated_intervals_range_10}
											</Typography>
										</Grid>
										<Grid
											className={classNames(
												classes.expansionBorder,
												classes.lastBorder2
											)}
											item
											xs={6}
											xl={4}></Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						<Grid item xs={12}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<PowerIcon style={{ marginTop: 10 }} />
									<Typography
										style={{ paddingLeft: 16, marginTop: 8 }}
										variant="h6">
										{" "}
										Motor/Pump Information
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Power Type</b>: {site.power_type}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Volumetric Pumping Calculation Type</b>:{" "}
												{site.volumetric_calc_type}
											</Typography>
										</Grid>
										<Grid
											className={classNames(
												classes.expansionBorder,
												classes.lastBorder3
											)}
											item
											xs={4}></Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						<Grid item xs={8}>
							<Typography variant="h6">
								Measurement Data{" "}
								<Button
									title="Table"
									onClick={() =>
										this.setState({ mapState: !this.state.mapState })
									}>
									<MapIcon
										className={
											this.state.mapState
												? classes.iconSelected
												: classes.iconNotSelected
										}
									/>
								</Button>
								<Button
									// fullWidth
									variant="contained"
									color="primary"
									onClick={() =>
										this.exportData(filtered_measurements, y_axis)
									}>
									<Typography
										style={{
											textAlign: "left",
											width: "100%",
											color: "#fff",
										}}>
										Export Tabular Well Data
									</Typography>
								</Button>
							</Typography>{" "}
						</Grid>
						<Grid item xs={4}>
							<Typography variant="h6">Select View</Typography>
						</Grid>
						<Grid item xs={8}>
							{site !== null && site.geometry && this.state.mapState && (
								<ResponsiveContainer
									width="100%"
									height={150}
									ref="mapContainer">
									<WaterLevelDetailMap
										geometry={site.geometry.coordinates}
										height={"150px"}
										width={"100%"}
										zoom={7}
									/>
								</ResponsiveContainer>
							)}
						</Grid>
						<Grid item xs={4}>
							<div className={classes.divideContainer}>
								<Button
									title="Table"
									onClick={() => this.setState({ buttonState: "table" })}
									className={classNames(
										classes.divideButton,
										buttonState === "table"
											? classes.buttonSelected
											: classes.buttonNotSelected
									)}>
									<ListIcon
										className={
											buttonState === "table"
												? classes.iconSelected
												: classes.iconNotSelected
										}
									/>
								</Button>
								<Button
									title="Chart"
									onClick={() => this.setState({ buttonState: "chart" })}
									className={classNames(
										classes.divideButton,
										buttonState === "chart"
											? classes.buttonSelected
											: classes.buttonNotSelected
									)}>
									<PollIcon
										className={
											buttonState === "chart"
												? classes.iconSelected
												: classes.iconNotSelected
										}
									/>
								</Button>
							</div>
						</Grid>
						{buttonState === "table" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={columnData}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{measurements
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>
															{this.formatDate(item.measurement_date)}
														</TableCell>
														<TableCell>
															{this.formatTime(item.measurement_time)}
														</TableCell>
														<TableCell>{item.measurement_depth}</TableCell>
														<TableCell>{item.surf_elev}</TableCell>
														<TableCell>{item.vert_datum}</TableCell>
														<TableCell>{item.meas_method}</TableCell>
														<TableCell>{item.meas_accuracy}</TableCell>
														<TableCell>{item.no_measurement}</TableCell>
														<TableCell>{item.quest_meas}</TableCell>
														<TableCell>{item.measured_by}</TableCell>
														<TableCell>{item.meas_comments}</TableCell>
													</TableRow>
												))}
											{measurements.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Measurements Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{measurements.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={measurements.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{buttonState === "chart" && (
							<>
								{measurements.length === 0 && (
									<Grid item xs={12}>
										<Typography variant="h5" className={classes.centerAlign}>
											No Measurements Found
										</Typography>
									</Grid>
								)}
								{measurements.length > 0 && (
									<>
										<Grid item xs={8}>
											<ResponsiveContainer
												width="100%"
												height={680}
												ref="chartContainer">
												<LineChart
													data={
														y_axis === "surf_elev"
															? filtered_measurements.filter(
																	(s) => s.surf_elev != null
															  )
															: filtered_measurements.filter(
																	(s) => s.measurement_depth != null
															  )
													}>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis
														dataKey="chart_date"
														name="Measurement Date"
														domain={[
															this.getMinDate(filtered_measurements),
															this.getMaxDate(filtered_measurements),
														]}
														type="number"
														tickFormatter={(unixTime) =>
															moment(unixTime).format("MM/DD/YYYY")
														}
													/>

													{y_axis === "surf_elev" && (
														<YAxis
															// domain has to be in functions otherwise it doesnt update?
															domain={[
																(dataMin) =>
																	min_value
																		? parseFloat(min_value)
																		: this.getMinMaxVal(
																				filtered_measurements,
																				y_axis,
																				site
																		  ).min,
																(dataMax) =>
																	max_value
																		? parseFloat(max_value)
																		: this.getMinMaxVal(
																				filtered_measurements,
																				y_axis,
																				site
																		  ).max,
															]} // 'auto' doesnt work instead of math.ceil
															dataKey={y_axis}
															type="number">
															<Label
																value="feet"
																position="middle"
																angle={-90}
															/>
														</YAxis>
													)}

													{y_axis === "measurement_depth" && (
														<YAxis
															//flip the min/max
															domain={[
																(dataMax) =>
																	max_value
																		? parseFloat(max_value)
																		: this.getMinMaxVal(
																				filtered_measurements,
																				y_axis,
																				site
																		  ).max,
																(dataMin) =>
																	min_value ? parseFloat(min_value) : 0,
															]} // 'auto' doesnt work instead of math.ceil
															dataKey={y_axis}
															type="number">
															<Label
																value="feet"
																position="middle"
																angle={-90}
															/>
														</YAxis>
													)}

													<Tooltip
														labelFormatter={(value) =>
															new Date(value).toLocaleDateString()
														}
													/>
													<Legend />
													<Line
														connectNulls
														isAnimationActive={false} // Dots are broken on re-render if true https://github.com/recharts/recharts/issues/804
														name={y_axis_label}
														type="linear"
														dataKey={y_axis}
														stroke="#0080be"
													/>
													{y_axis === "surf_elev" && site.gsp_threshold && (
														<ReferenceLine
															y={site.gsp_threshold}
															label={
																"Minimum Threshold " +
																this.round(site.gsp_threshold, 1)
															}
															stroke="red"
														/>
													)}

													{y_axis === "surf_elev" &&
														parseFloat(site.measurable_objective) && (
															<ReferenceLine
																y={parseFloat(site.measurable_objective)}
																label={
																	"Measureable Objective " +
																	this.round(
																		parseFloat(site.measurable_objective),
																		1
																	)
																}
																stroke="green"
															/>
														)}

													{y_axis === "surf_elev" &&
														site.ground_surface_elev && (
															<ReferenceLine
																y={site.ground_surface_elev}
																label={
																	"Ground Surface Elevation " +
																	this.round(site.ground_surface_elev, 1)
																}
																stroke="brown"
															/>
														)}
												</LineChart>
											</ResponsiveContainer>
										</Grid>
										<Grid item xs={4}>
											<Form
												key={this.formKey}
												dontValidateOnMount={true}
												validateOnSubmit={true}
												defaultValues={this.state}>
												{(formApi) => (
													<form onSubmit={formApi.submitForm}>
														<Grid
															container
															spacing={8}
															className={classes.borderContainer2}>
															<Grid item xs={12} className={classes.chartTitle}>
																<Typography variant="h6">
																	Y Axis Values
																</Typography>
															</Grid>
															<Grid item xs={12}>
																<Select
																	field="y_axis"
																	label=""
																	options={[
																		{
																			label: "Surface Elevation",
																			value: "surf_elev",
																		},
																		{
																			label: "Measurement Depth",
																			value: "measurement_depth",
																		},
																	]}
																	fullWidth
																	eventHandle={(val) => {
																		var lab;
																		if (val === "measurement_depth")
																			lab = "Measurement Depth";
																		if (val === "surf_elev")
																			lab = "Surface Elevation";
																		this.setState({
																			y_axis: val,
																			y_axis_label: lab,
																		});
																	}}
																/>
															</Grid>
															<Grid item xs={12} className={classes.chartTitle}>
																<Typography variant="h6">
																	Summary Statistics
																</Typography>
															</Grid>
															<Grid item xs={12}>
																<Typography>
																	<b>Date Range</b>:&nbsp;
																	{minDate.toLocaleDateString()}&nbsp;-&nbsp;
																	{maxDate.toLocaleDateString()}
																</Typography>
															</Grid>
															<Grid item xs={12} className={classes.chartTitle}>
																<Typography variant="h6">
																	Filter Monitor Data
																</Typography>
															</Grid>
															<Grid item xs={12}>
																<Select
																	field="start_date"
																	label="Start Date"
																	eventHandle={(val) =>
																		this.setState({ start_date: val })
																	}
																	options={yearoptions}
																	fullWidth
																/>
															</Grid>
															<Grid item xs={12}>
																<Select
																	field="end_date"
																	label="End Date"
																	eventHandle={(val) =>
																		this.setState({ end_date: val })
																	}
																	options={yearoptions}
																	fullWidth
																/>
															</Grid>
															<Grid item xs={12} className={classes.chartTitle}>
																<Typography variant="h6">
																	Chart Settings
																</Typography>
															</Grid>
															<Grid item xs={12}>
																<TextField
																	field="min_value"
																	type="number"
																	label="Y Axis Min Value"
																	eventHandle={(val) =>
																		this.setState({ min_value: val })
																	}
																	fullWidth
																/>
															</Grid>
															<Grid item xs={12}>
																<TextField
																	field="max_value"
																	type="number"
																	label="Y Axis Max Value"
																	eventHandle={(val) =>
																		this.setState({ max_value: val })
																	}
																	fullWidth
																/>
															</Grid>
															<Grid item xs={12}>
																<Button
																	fullWidth
																	variant="contained"
																	color="primary"
																	onClick={this.printChart}>
																	<Typography
																		style={{
																			textAlign: "left",
																			width: "100%",
																			color: "#fff",
																		}}>
																		Print Chart
																	</Typography>
																	<ChevronRight style={{ float: "right" }} />
																</Button>
															</Grid>
														</Grid>
													</form>
												)}
											</Form>
										</Grid>
									</>
								)}
							</>
						)}
					</Grid>
				</AppContainer>
			);
		}
	}
}
WaterLevelDetail = connect((state, ownProps) => ({}), {})(WaterLevelDetail);

export default withStyles(styles)(WaterLevelDetail);
