import React from "react";
import { MapControl, withLeaflet } from "react-leaflet"; //, Map, TileLayer, WMSTileLayer type MapControlProps,
import { Control, DomUtil, DomEvent } from "leaflet";
import Button from "@material-ui/core/Button";
import * as app_settings from "../../../app_settings";

const DC = Control.extend({
    // Adapted from react-leaflet-control: https://github.com/LiveBy/react-leaflet-control/blob/master/lib/control.jsx
    options: {
        className: "ctrlb globe",
        onOff: "",
        handleOff: function noop() {},
        //bounds: this.props.bounds, //[[36.33, -120.44], [37.02, -118.91]], // TODO, abstract this out
        //zoom: this.props.zoom,
    },
    onAdd(map) {
        var _controlDiv = DomUtil.create("div", this.options.className);
        _controlDiv.title = "Return to Full Extent";
        DomEvent.addListener(_controlDiv, "click", function () {
            map.flyTo(
                [app_settings.map_initial_state.lat, app_settings.map_initial_state.lng],
                app_settings.map_initial_state.zoom
            );
            map.invalidateSize();
        });
        DomEvent.disableClickPropagation(_controlDiv);
        return _controlDiv;
    },
    onRemove(map) {
        if (this.options.onOff) {
            map.off(this.options.onOff, this.options.handleOff, this);
        }
        return this;
    },
});

class ZoomEx extends MapControl {
    constructor(props) {
        super(props);
        this.leafletElement = this.createLeafletElement(props); // This.props should be assumed.
    }
    createLeafletElement(props) {
        return new DC(Object.assign({}, props));
    }
    updateLeafletElement(fromProps, toProps): void {
        if (toProps.position !== fromProps.position) {
            this.leafletElement.setPosition(toProps.position);
        }
    }
    onAdd() {
        var _controlDiv = DomUtil.create("div", "ctrla_globe"); //this.options.className
        DomEvent.disableClickPropagation(_controlDiv);
        return _controlDiv;
    }
    componentDidUpdate(prevProps) {
        this.updateLeafletElement(prevProps, this.props);
    }
    componentWillUnmount() {
        this.leafletElement.remove();
    }
    render(): * {
        return <Button onClick={this.zoomExtent}>Zoom</Button>;
    }
}
const ZoomExtent = withLeaflet(ZoomEx);

export default ZoomExtent;
