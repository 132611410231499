import { fk } from "redux-orm";
import orm, { Model } from "../common/orm";

export class GSA extends Model {
	static get apiUrl() {
		return "/gsas/";
	}
	static get source() {
		return "/gsas/";
	}
	static get fields() {
		return {};
	}
	static get modelName() {
		return "GSA";
	}
}
orm.register(GSA);

export class GSP extends Model {
	static get apiUrl() {
		return "/gsps/";
	}
	static get source() {
		return "/gsps/";
	}
	static get fields() {
		return {
			authorized_reporter: fk("User", "gsps"),
		};
	}
	static get modelName() {
		return "GSP";
	}
}
orm.register(GSP);

export class Agency extends Model {
	static get apiUrl() {
		return "/agencys/";
	}
	static get source() {
		return "/agencys/";
	}
	static get fields() {
		return {};
	}
	static get modelName() {
		return "Agency";
	}
}
orm.register(Agency);

export class User extends Model {
	static get apiUrl() {
		return "/users/";
	}
	static get source() {
		return "/users/";
	}
	static get fields() {
		return {
			agency: fk("Agency", "users"),
		};
	}
	static get modelName() {
		return "User";
	}
}
orm.register(User);

export class GSAAssociation extends Model {
	static get apiUrl() {
		return "/gsaassociations/";
	}
	static get source() {
		return "/gsaassociations/";
	}
	static get fields() {
		return {
			user: fk("User", "gsaassociations"),
			gsa: fk("GSA", "gsaassociations"),
		};
	}
	static get modelName() {
		return "GSAAssociation";
	}
}
orm.register(GSAAssociation);

export class GSPAssociation extends Model {
	static get apiUrl() {
		return "/gspassociations/";
	}
	static get source() {
		return "/gspassociations/";
	}
	static get fields() {
		return {
			user: fk("User", "gspassociations"),
			gsp: fk("GSP", "gspassociations"),
		};
	}
	static get modelName() {
		return "GSPAssociation";
	}
}
orm.register(GSPAssociation);

export class WaterYear extends Model {
	static get apiUrl() {
		debugger;
		return "/wateryears/";
	}
	static get source() {
		return "/wateryears/";
	}
	static get fields() {
		return {};
	}
	static get modelName() {
		return "WaterYear";
	}
}
orm.register(WaterYear);

export class WaterReporting extends Model {
	static get apiUrl() {
		return "/waterreportings/";
	}
	static get source() {
		return "/waterreportings/";
	}
	static get fields() {
		return {
			wateryear: fk("WaterYear", "waterreportings"),
			gsp: fk("GSP", "waterreportings"),
		};
	}
	static get modelName() {
		return "WaterReporting";
	}
}
orm.register(WaterReporting);

export class GroundwaterStorage extends Model {
	static get apiUrl() {
		return "/groundwaterstorages/";
	}
	static get source() {
		return "/groundwaterstorages/";
	}
	static get fields() {
		return {
			wateryear: fk("WaterYear", "groundwaterstorages"),
			gsp: fk("GSP", "groundwaterstorages"),
		};
	}
	static get modelName() {
		return "GroundwaterStorage";
	}
}
orm.register(GroundwaterStorage);

export class GroundwaterExtraction extends Model {
	static get apiUrl() {
		return "/groundwaterextractions/";
	}
	static get source() {
		return "/groundwaterextractions/";
	}
	static get fields() {
		return {
			wateryear: fk("WaterYear", "groundwaterextractions"),
			gsp: fk("GSP", "groundwaterextractions"),
		};
	}
	static get modelName() {
		return "GroundwaterExtraction";
	}
}
orm.register(GroundwaterExtraction);

export class GroundwaterRecharge extends Model {
	static get fields() {
		return {};
	}
	static get modelName() {
		return "GroundwaterRecharge";
	}
}
orm.register(GroundwaterRecharge);

export class Evapotranspiration extends Model {
	static get fields() {
		return {};
	}
	static get modelName() {
		return "Evapotranspiration";
	}
}
orm.register(Evapotranspiration);

export class SurfaceWaterSupply extends Model {
	static get apiUrl() {
		return "/surfacewatersupplys/";
	}
	static get source() {
		return "/surfacewatersupplys/";
	}
	static get fields() {
		return {
			wateryear: fk("WaterYear", "surfacewatersupplys"),
			gsp: fk("GSP", "surfacewatersupplys"),
		};
	}
	static get modelName() {
		return "SurfaceWaterSupply";
	}
}
orm.register(SurfaceWaterSupply);

export class TotalWaterUse extends Model {
	static get apiUrl() {
		return "/totalwateruses/";
	}
	static get source() {
		return "/totalwateruses/";
	}
	static get fields() {
		return {
			wateryear: fk("WaterYear", "totalwateruses"),
			gsp: fk("GSP", "totalwateruses"),
		};
	}
	static get modelName() {
		return "TotalWaterUse";
	}
}
orm.register(TotalWaterUse);

export class SurfaceWaterMeasurement extends Model {
	static get apiUrl() {
		return "/surfacewatermeasurements/";
	}
	static get source() {
		return "/surfacewatermeasurements/";
	}
	static get fields() {
		return {};
	}
	static get modelName() {
		return "SurfaceWaterMeasurement";
	}
	static get initialLoad() {
		return false;
	}
}
orm.register(SurfaceWaterMeasurement);
