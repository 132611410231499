import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
// import TextField from "@material-ui/core/TextField";
import TextField from "../common/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
// import { BlueOnClick } from "../common/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import BreadcrumbNav from "../common/BreadCrumb";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TotalWaterUse } from "./models";
import { createSelector } from "../common/orm";
import HelpLabel from "../common/HelpLabel";
import NumberFormat from "react-number-format";
/* Calc Method Start */
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Form } from "react-form";
const getOptions = TotalWaterUse.getOptions();

const CalcMethod = (props) => {
	// props: fieldName, currentValue
	const [value, setValue] = React.useState(props.currentValue); // eslint-disable-line no-unused-vars

	/* TODO: Figure out how to set the field's value so that the value of the field and make sure the form can read it */
	return (
		<FormControl component="fieldset">
			<RadioGroup
				style={{ flexWrap: "nowrap", marginLeft: "10px" }}
				aria-label="direct"
				name={props.fieldName}
				row>
				<FormControlLabel
					value="Direct"
					disabled={props.disabled}
					onChange={props.onChange}
					style={{ alignItems: "center" }}
					control={
						<Radio
							checked={props.value === "Direct"}
							style={{
								padding: "6px 0px 3px 7px",
								color: props.value === "Direct" ? "primary" : "",
							}}
						/>
					}
					label="Direct"
					labelPlacement="end"
				/>
				<FormControlLabel
					value="Estimated"
					disabled={props.disabled}
					onChange={props.onChange}
					style={{ alignItems: "center" }}
					control={
						<Radio
							checked={props.value === "Estimated"}
							style={{
								padding: "6px 0 3px 7px",
								color: props.value === "Estimated" ? "primary" : "",
							}}
						/>
					}
					label="Estimated"
					labelPlacement="end"
				/>
			</RadioGroup>
		</FormControl>
	);
};
/* Calc Method End */

const getUserData = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let user = session.User.filter((u) => u.id === options.id).toRefArray()[0];

		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		return user && association && gsp
			? {
					...user,
					association: association ? association : {},
					gsp: gsp ? gsp : {},
			  }
			: {};
	}
);
//eslint-disable-next-line
function formatDate(date) {
	var monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	var day = date.getUTCDate();
	var monthIndex = date.getUTCMonth();
	var year = date.getUTCFullYear();

	return monthNames[monthIndex] + " " + day + ", " + year;
}

const getWaterYear = createSelector((session, id) => {
	let wy = session.WaterYear.filter(
		(y) => y.is_active === true
	).toRefArray()[0];
	return {
		...wy,
	};
});

const currentTotalWaterUse = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
		twu_id: parseInt(ownProps.match.params["id"]),
	}),
	(session, options) => {
		let gsp = session.GSP.filter((u) => u.id === options.gsp).toRefArray()[0];

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		if (options.twu_id) {
			let twu = session.TotalWaterUse.filter(
				(y) => y.gspp_id === gsp.id && y.id === options.twu_id
			).toRefArray()[0];

			let wy =
				twu && twu.water_year_id
					? session.WaterYear.filter(
							(y) => y.id === twu.water_year_id
					  ).toRefArray()[0]
					: {};

			twu = { ...twu, wy: wy };

			return gsp && twu && wy ? twu : {};
		} else {
			return gsp && thisWaterYear
				? session.TotalWaterUse.filter(
						(y) => y.water_year_id === thisWaterYear.id && y.gspp_id === gsp.id
				  ).toRefArray()[0]
				: {};
		}
	}
);

const historicTotalWaterUse = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		return thisWaterYear && gsp
			? session.TotalWaterUse.filter(
					(y) => y.water_year_id !== thisWaterYear.id && y.gspp_id === gsp.id
			  ).toRefArray()
			: {};
	}
);

const styles = (theme) => ({
	container: {
		paddingLeft: "10px",
	},
	hrclass: {
		borderColor: "primary",
	},
	tc: {
		padding: "4px 4px 4px 4px",
		maxWidth: "60px",
	},
	tcNumber: {
		padding: "4px 4px 4px 4px",
		maxWidth: "40px",
	},
	rightAligned: {
		textAlign: "right",
		padding: "4px 4px 4px 4px",
		maxWidth: "40px",
	},
	tcAccuracy: {
		padding: "4px 4px 4px 15px",
		maxWidth: "20px",
	},
	mdHeader: {
		fontSize: "18px",
		textDecoration: "underline",
		fontWeight: "normal",
	},
	lgHeader: {
		fontSize: "25px",
	},
	buttonActive: {
		backgroundColor: theme.palette.primary.dark + " !important",
		color: theme.palette.primary.contrastText + " !important",
	},
	buttonInactive: {
		backgroundColor: theme.palette.primary.light + " !important",
		color: theme.palette.primary.contrastText + " !important",
	},
});

class ReportingTotalWaterUse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			csrftoken: localStorage.auth_token,
			loader: null,
			agency: "Not Available",
			waterYearStart: "Not Available",
			waterYearEnd: "Not Available",
			districtName: "Not Available",
			submitted: false,
			saved: false,
			authorized: false,
			helpOpen: false,
			callOptions: false,
			
		};
	}

	componentDidMount() {
		const { authState, history, watercur } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}

	//Check for: user && user.role === "GSP Representative" ?

	setAuthorized = () => {
		const { user } = this.props;
		const { authorized } = this.state;

		if (user && user.association && user.association.authorized_reporter) {
			if (!authorized && user && user.role === "GSP Representative") {
				this.setState({ authorized: true });
			}
		}
	};

	pf = (val) => {
		if (val === "-" || val === "" || val === " ") {
			return parseFloat(0);
		} else if (typeof val === "string") {
			return parseFloat(val.replace(/,/g, ""));
		} else {
			return parseFloat(val);
		}
	};

	MAP_OPTIONS = (table) => {
		var t = table.map((row) => ({
			label: row.display_name,
			value: row.value,
		}));

		return t;
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		/* Auto-calculating fields after they're updated */
	}	

 
	handleChange = (evt) => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});
	};

	//set decimals and default styling
	NumberFormatCustom = (props) => {
		const { inputRef, onChange, ...other } = props;
		return (
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				onValueChange={(values) => {
					onChange({
						target: {
							value: values.value,
							name: other.name,
						},
					});
				}}
				thousandSeparator
				decimalScale={2}
			/>
		);
	};

	handleChangeNumeric = (evt) => {
		const re = /^-?[-0-9\b]+$/;
		const val = evt.target.value.replace(/,/g, "");
		if (val === "" || re.test(val)) {
			this.setState({
				[evt.target.name]: val,
			});
		}
	};

	handleSave = (evt) => {
		evt.preventDefault();
		const { ormTotalWaterUseCreate, ormTotalWaterUseUpdate } = this.props;
		const { user, wateryr, history } = this.props;
		const { curdata } = this.state;

		this.setState({
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		});

		const stObj = {
			...this.state,
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		};

		if (!curdata) {
			ormTotalWaterUseCreate(stObj);
		} else {
			ormTotalWaterUseUpdate(stObj);
		}

		history.push("/reporting");
	};

	submitForm = (evt) => {
		evt.preventDefault();
		const { ormTotalWaterUseUpdate, history } = this.props;

		this.setState({
			submitted: true,
		});

		const stObj = {
			...this.state,
			submitted: true,
		};

		ormTotalWaterUseUpdate(stObj);

		history.push("/reporting");
	};

	defaultValues = (id) => {
	 
		const { watercur} = this.props;
		//set totals
		if(watercur){
			watercur['t_suf_water'] = watercur['t_agg_surf_water'] + watercur['t_dom_surf_water'] + watercur['t_ind_surf_water'] + watercur['t_wet_surf_water'] + watercur['t_veg_surf_water'] + watercur['t_other_surf_water'] + watercur['t_dist_surf_water']
			watercur['t_total'] = watercur['t_agg_total'] + watercur['t_dom_total'] + watercur['t_ind_total'] + watercur['t_wet_total'] + watercur['t_veg_total'] + watercur['t_other_total'] + watercur['t_dist_total']
			watercur['t_precip'] = watercur['t_agg_precip'] + watercur['t_dom_precip'] + watercur['t_ind_precip'] + watercur['t_wet_precip'] + watercur['t_veg_precip'] + watercur['t_other_precip'] + watercur['t_dist_man_precip']
			watercur['t_reused'] = watercur['t_agg_reused'] + watercur['t_dom_reused'] + watercur['t_ind_reused'] + watercur['t_wet_reused'] + watercur['t_veg_reused'] + watercur['t_other_reused'] + watercur['t_dist_man_reused']
			watercur['t_recycled'] = watercur['t_agg_recycled'] + watercur['t_dom_recycled'] + watercur['t_ind_recycled'] + watercur['t_wet_recycled'] + watercur['t_veg_recycled'] + watercur['t_other_recycled'] + watercur['t_dist_man_recycled']
			watercur['t_gnd_water'] = watercur['t_agg_gw'] + watercur['t_dom_gw'] + watercur['t_ind_gw'] + watercur['t_wet_gw'] + watercur['t_veg_gw'] + watercur['t_other_gw'] + watercur['t_dist_man_gw']
		}
		//get all fields for to check for autopopulate on date
		let defaultValues = {};
	 
		return watercur ? watercur : defaultValues;
	};
	render() {
		this.setAuthorized();
 

		const {
			submitted,
			saved,
			authorized,
			helpOpen,
			siteChoices,
		} = this.state;
		const { classes, user, wateryr, watercur, waterhist, optionsReq } =
			this.props;

		let submitActive =
			(saved || this.state.curdata) && !submitted && authorized;

		//OPTIONS comes back as a promise
		// if (optionsReq && !siteChoices) {
		// 	optionsReq.then((data) => {
		// 		if (!this.state.siteChoices) {
		// 			this.setState({
		// 				siteChoices: data,
		// 			});
		// 		}
		// 	});
		// }

		return (
			<AppContainer authenticated>
				<div className={classes.container}>
					<Form
						key={watercur && watercur.id}
						dontValidateOnMount="false"
						getApi={(el) => (this.formApi = el)}
						// validateOnSubmit="true"
						defaultValues={this.defaultValues()}
						>
						{(formApi) => (
							<form onSubmit={formApi.submitForm}>
								{watercur && watercur.wy ? (
									<>
										<BreadcrumbNav
											level1="Total Water Use Data"
											level1link="/totalwateruse"
											level2="Total Water Use Details"
											level2link={
												"/reporting/totaluse/" +
												this.props.match.params["gsp"] +
												"/" +
												this.props.match.params["id"]
											}
										/>
										<br />
									</>
								) : (
									<>
										<BreadcrumbNav
											level1="Associated GSP's"
											level1link="/reporting"
											level2="Report Total Water Use Data"
											level2link={
												"/reporting/totaluse/" + this.props.match.params["gsp"]
											}
										/>
										<br />
									</>
								)}

								<Grid container spacing={24}>
									<Grid item xs={12}>
										{watercur && JSON.stringify(watercur) !== "{}" && (
											<>
											<span className={classes.lgHeader}>
												Water Year:{" "}
												{watercur.water_year}{" "}
												/ Measurement Month:{" "}
												{watercur.month}
												
												</span>
											</>
										)} 
									</Grid>
									{watercur && watercur.gsp_label && (
										<Grid item xs={12}>
											<>
												<span>
													<b>GSP:</b> {watercur.gsp_label}  
												</span>
												<span style={{marginLeft:4}}>
													{"  |  "}<b> GSA: </b>{watercur.gsa_name_label}
												</span>
											</>
										</Grid>
									)}	
									{watercur && watercur.wy ? (
										<></>
									) : (
										<>
											<Grid item xs={12}>
												<b>Role:</b> Your user account is{" "}
												{authorized ? "currently" : <b>NOT</b>} authorized to report
												data for:{" "}
												<b>
													{user && user.gsp
														? user.gsp.name
														: "(User not associated with GSP)"}
												</b>
												.
											</Grid>
											<Grid item xs={12}>
												<b>Status:</b> {submitted ? "Data" : "No data"} has been
												imported into the DMS for the current water year.{" "}
												{waterhist && waterhist.length && user && user.gsp ? (
													<a
														href={
															"/#/reportinghistory/totalwateruse/" +
															+this.props.match.params["gsp"]
														}>
														View Historic Data
													</a>
												) : (
													""
												)}
											</Grid>
										</>
									)}
									<hr />
									<Grid item xs={12}>
										<hr className={classes.hrclass} />
										<h2 className={classes.mdHeader}>
											Total Water Use Data
											<HelpLabel
												open={helpOpen}
												question="Total Water Use"
												showHelp={true}
												inputLabel={true}
												helpText={
													<>
														Volume of water used by each water use sector. This
														volume may be equal to or less than the volume of
														groundwater or surface water supplies delivered. A water
														use volume less than the volume of supplies delivered
														may indicate a loss of water during delivery and/or use
														of precipitation. Estimated water use shall consider all
														water sources available, including precipitation,
														surface water seepage, and passive groundwater use.
													</>
												}
											/>
										</h2>

										<Table>
											<TableBody>
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Water Use Sector
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Surface Water - District Deliveries{" "}
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Surface Water - Water Rights Diversions{" "}
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Surface Water{" "}
													</TableCell>
												 
													<TableCell xs={1} className={classes.tcNumber}>
														Ground Water
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Recycled Water
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Reused Water
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Precipitation
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Other
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Other Source Description
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														Total
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														Source Notes
													</TableCell>
													<TableCell xs={3} className={classes.tc}>
														Calculation Method
													</TableCell>
													<TableCell xs={1} className={classes.tcAccuracy}>
														Calculation Accuracy
													</TableCell>
												</TableRow>

												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Urban/Domestic:
														<HelpLabel
															open={helpOpen}
															question="Urban/Domestic Water Use"
															showHelp={true}
															inputLabel={true}
															helpText={
																<>
																	Municipal, domestic, commercial and/or
																	institutional water use, either a metered delivery
																	or an estimate of water delivered. Note that
																	industrial water use is reported as a separate
																	category.
																</>
															}
														/>
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_surf_water"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_gw"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_recycled"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_reused"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_precip"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_other"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_other_desc"}
															disabled={true} 
															fullWidth
															InputProps 
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_total"}
															fullWidth
															disabled={true} 
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tcNumber}>
														<TextField
															field={"t_dom_notes"}
															disabled={true} 
															fullWidth
															rows={1}
															rowsMax="4"
															multiline={true}
															InputProps
															inputProps={{
															maxLength: 4000,	
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dom_calcm"}
															disabled={true} 
															fullWidth
															InputProps
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_dom_acc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Industrial:
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_surf_water"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_gw"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_recycled"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_reused"}
															disabled={true}  
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_precip"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_other"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
												
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_other_desc"}
															disabled={true} 
															fullWidth
															InputProps 
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_total"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_ind_notes"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_calcm"}
															disabled={true} 
															fullWidth
															InputProps 
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_ind_acc"}
															disabled={true} 
															fullWidth
															InputProps 
														/>
													</TableCell>
											 
												</TableRow>
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Agricultural:
														<HelpLabel
															open={helpOpen}
															question="Agricultural Water Use"
															showHelp={true}
															inputLabel={true}
															helpText={
																<>
																	Surface water delivered and metered at a lateral
																	turnout and used for agricultural purposes. (Note
																	that this should be the same volume as is reported
																	by each contract holder to a state or federal
																	agency each year.) If metered data are not
																	available, estimated water used by agricultural
																	should be provided.
																</>
															}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_dist_del"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_sw_water_right"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_surf_water"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_gw"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_recycled"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_reused"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_precip"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_other"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_other_desc"}
															disabled={true} 
															fullWidth
															InputProps 
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_agg_total"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tcNumber}>
														<TextField
															field={"t_agg_notes"}
															disabled={true} 
															fullWidth	
															multiline={true}
															rowsMax="4"
															InputProps 
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_agg_calcm"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_agg_acc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
											 
												</TableRow>
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Managed Wetlands:
														<HelpLabel
															open={helpOpen}
															question="Managed Wetlands Water Use"
															showHelp={true}
															inputLabel={true}
															helpText={
																<>
																	Water delivered and metered at a lateral turnout
																	or diversion and used for environmental purposes
																	on managed wetland areas such as wildlife refuges.
																	If metered data are not available, estimated water
																	used by the managed wetlands should be provided.
																</>
															}
														/>
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_surf_water"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_gw"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_recycled"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_reused"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_precip"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_other"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_other_desc"}
															disabled={true} 
															fullWidth
															InputProps 
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_total"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_wet_notes"}
															disabled={true} 
															fullWidth
															multiline
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_wet_calcm"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_wet_acc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													 
												</TableRow>
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														District Managed Recharge:
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_surf_water"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_gw"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_recycled"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_reused"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_precip"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_other"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_other_desc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}} 
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_total"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_notes"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}} 
															 
														/>
													</TableCell>
												 
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_dist_man_calcm"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_dist_man_acc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
											
												</TableRow>
												
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Native Vegetation:
														<HelpLabel
															open={helpOpen}
															question="Native Vegetation Water Use"
															showHelp={true}
															inputLabel={true}
															helpText={
																<>
																	Estimated water use (uptaken) by native vegetation
																	regardless of the source.
																</>
															}
														/>
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_surf_water"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_gw"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_recycled"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_reused"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_precip"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_other"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_other_desc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_total"}
															disabled={true}  
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_notes"}
															disabled={true} 
															fullWidth
															multiline
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_veg_calcm"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_veg_acc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
	 
												</TableRow>
												 
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Other:
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}> 
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_surf_water"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_gw"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_recycled"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_reused"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_precip"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_other"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_other_desc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_total"}
															disabled={true} 
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_other_notes"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_other_calcm"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={2} className={classes.tc}>
														<TextField
															field={"t_other_acc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
										 
												</TableRow>
												<TableRow>
													<TableCell xs={3} className={classes.tc}>
														Other Use Description:
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}
														colSpan={6}>
														<TextField
															field={"t_other_use_desc"}
															disabled={true} 
															fullWidth
															multiline={true}
															rows={1}
															rowsMax="4"
															InputProps
															inputProps={{
																maxLength: 4000,
															}}
														/>
													</TableCell>
													<TableCell xs={3} className={classes.tc}></TableCell>
													<TableCell xs={2} className={classes.tc}></TableCell>
													<TableCell xs={3} className={classes.tc}></TableCell>
													<TableCell xs={2} className={classes.tc}></TableCell>
													<TableCell xs={3} className={classes.tc}></TableCell>
													<TableCell xs={2} className={classes.tc}></TableCell>
													<TableCell xs={3} className={classes.tc}></TableCell>
													<TableCell xs={3} className={classes.tc}></TableCell>
												</TableRow>
												<TableRow>
													<TableCell xs={2} className={classes.tcNumber}>
														Total:
													</TableCell>
													 <TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_dist_del"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_agg_sw_water_right"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_suf_water"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_gnd_water"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_recycled"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_reused"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_precip"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell xs={3} className={classes.tcNumber}>
														<TextField
															field={"t_other"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}></TableCell>
													<TableCell xs={1} className={classes.tcNumber}>
														<TextField
															field={"t_total"}
															disabled={true}
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">AF</InputAdornment>
																),
																inputComponent: this.NumberFormatCustom,
															}}
														/>
													</TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}></TableCell> 
													<TableCell
														xs={3}
														className={classes.tcNumber}></TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}></TableCell>
													<TableCell
														xs={3}
														className={classes.tcNumber}></TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</Grid>
									{/* {watercur && watercur.wy ? (
										<></>
									) : (
										<>
											<Grid item xs={3}>
												<Button
													fullWidth
													variant="contained"
													type="button"
													label="Save"
													disabled={submitted || !authorized}
													onClick={this.handleSave}
													className={
														!submitted && authorized
															? classes.buttonActive
															: classes.buttonInactive
													}>
													Save
												</Button>
											</Grid>
											<Grid item xs={9}>
												<Button
													type="button"
													onClick={this.submitForm}
													disabled={!submitActive}
													className={
														submitActive
															? classes.buttonActive
															: classes.buttonInactive
													}>
													Submit Data as Final for Current Reporting Water Year
												</Button>
											</Grid>
										</>
									)} */}
								</Grid>
						</form>
						)}
					</Form>	
				</div>
			</AppContainer>
		);
	}
}
ReportingTotalWaterUse = connect(
	(state, ownProps) => ({
		optionsReq: getOptions(state, ownProps),
		wateryr: getWaterYear(state, ownProps),
		user: getUserData(state, ownProps),
		watercur: currentTotalWaterUse(state, ownProps),
		waterhist: historicTotalWaterUse(state, ownProps),
		authState: state.auth,
	}),
	{
		...TotalWaterUse.actions,
		...authActions,
		...navActions,
	}
)(ReportingTotalWaterUse);
export default withStyles(styles)(withRouter(ReportingTotalWaterUse));
