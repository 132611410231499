import React, { Component } from "react";
import { connect } from "react-redux";

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://chowchilladms.houstoneng.net";

class WellSiteDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
		};
	}
	render = () => <></>;

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		//options for apitarget: [wellsitemeasurements, waterqualitymeasurements, surfacewatermeasurements, subsidencemeasurements]

		const { authState } = this.props;

		let token = authState && authState.user ? authState.user.auth_token : "";

		let URL = isLocalhost ? LOCAL_URL : PROD_URL;

		this.setState({ ...this.state, isFetching: true });
		fetch(
			URL +
				"/wellsitedetails/" +
				this.props.apitarget +
				"/" +
				this.props.siteid,
			{
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Token " + token,
				},
			}
		)
			.then((response) => response.json())
			.then((result) => {
				this.props.handler(result);
			})
			.catch((e) => {
				console.log(e);
				this.setState({ ...this.state, isFetching: false });
			});
	};
}
WellSiteDetails = connect(
	(state, ownProps) => ({
		authState: state.auth,
	}),
	{}
)(WellSiteDetails);

export default WellSiteDetails;
