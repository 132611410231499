import React from "react";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
import TextField from "../common/TextField";
import TablePaginationActionsWrapped from "../common/Paginator";
import Icon from "@mdi/react";
import { ProjectForm } from "../wells/models";
import { mdiEye } from "@mdi/js";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
import DashboardMap from "../common/map/Map";
import { createSelector } from "../common/orm";

const projForm = ProjectForm.selectAll();

const repeatLastStatus = createSelector((session, form) => {
	return session.ProjectRepeatableTable.all()
		.orderBy("id", "desc")
		.toRefArray();
});
const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},

	deleteWidth: {
		minWidth: 34,
		width: 34,
		marginRight: 8,
	},
	table: {
		width: "100%",
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	actionCell: {
		minWidth: 90,
	},
	divideButton: {
		marginBottom: 8,
		width: "50%",
		marginTop: 16,
		minWidth: 44,
	},
	iconSelected: {
		color: theme.palette.primary.main,
	},
	iconNotSelected: {
		color: "#666",
	},
	buttonSelected: {
		border: "3px solid " + theme.palette.primary.main,
		minWidth: "44px",
	},
	buttonNotSelected: {
		border: "3px solid #666",
		minWidth: "44px",
	},
	gridHeight: {},
	mapHeight: {
		height: "100%",
	},
	mapHeight2: {
		height: "calc(100% - 220px)",
	},
});

const columnData = [
	{ id: "actions", numeric: false, label: "Actions", allowSort: false },
	{
		id: "project_id",
		numeric: false,
		label: "Project ID",
		allowSort: true,
	},
	{
		id: "project_name",
		numeric: false,
		label: "Project Name",
		allowSort: true,
	},
	{
		id: "source_gsa_label",
		numeric: false,
		label: "GSA",
		allowSort: true,
	},
	{
		id: "project_status",
		numeric: false,
		label: "Project Status",
		allowSort: true,
	},
	{
		id: "project_mechanism",
		numeric: false,
		label: "Project Mechanism",
		allowSort: true,
	},
];

class GridViewImplementationProjects extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: null,
			order: "asc",
			orderBy: null,
			page: 0,
			rowsPerPage: 25,
			showDelete: false,
			deleteId: null,
			deleteName: null,
			buttonState: "table",
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		// if (
		// 	authState &&
		// 	authState.user &&
		// 	authState.user.role !== "Administrator"
		// ) {
		// 	history.push("/dashboard");
		// }
	}

	filterByValue = (array, string) => {
		return array.filter((o) =>
			Object.keys(o).some(
				(k) =>
					o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())
			)
		);
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		//  null values break the sorting so set null values to ""
		this.props.projforms.forEach((s) => {
			if (s[orderBy] === null) {
				s[orderBy] = "";
			}
		});

		order === "desc"
			? this.props.projforms.sort((a, b) =>
					b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1
			  )
			: this.props.projforms.sort((a, b) =>
					a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1
			  );

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	render() {
		const {
			classes,

			projforms,
			ormProjectFormDelete,
			repeatlaststatus,
		} = this.props;
		const {
			order,
			orderBy,
			filter,
			page,
			rowsPerPage,
			showDelete,
			deleteName,
			deleteId,
			buttonState,
		} = this.state;

		var filtered_items;
		if (filter) {
			filtered_items = this.filterByValue(projforms, filter);
		} else {
			filtered_items = projforms;
			if (repeatlaststatus) {
				//get last status to add to record on table
				let temp = [];
				filtered_items.forEach((fi) => {
					let projstatus = repeatlaststatus.find((i) =>
						i.projectform_id === fi.id ? i : null
					);
					if (projstatus) fi.projectstatus = projstatus.project_status;
					temp.push(fi);
				});
				filtered_items = temp;
			}
		}
		let level1 = "Implementation Projects";
		let level1link = "/implementationprojects";

		return (
			<AppContainer authenticated>
				<Grid
					container
					spacing={24}
					className={
						buttonState === "map" ? classes.mapHeight : classes.gridHeight
					}>
					<Grid item xs={12} className={classes.breadCrumb}>
						<BreadcrumbNav level1={level1} level1link={level1link} />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">
							Submitted Implementation Projects Data
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h6">Keyword Filter</Typography>
					</Grid>
					<Grid item xs={7} style={{ backgroundColor: "#ddd", marginLeft: 12 }}>
						<Form dontValidateOnMount={true} validateOnSubmit={true}>
							{(formApi) => (
								<form style={{ float: "left" }} onSubmit={formApi.submitForm}>
									<TextField
										rounded
										style={{ borderRadius: 114 }}
										placeholder="Filter By Project ID"
										startAdornment={
											<InputAdornment style={{ marginRight: 8 }}>
												<SearchIcon />
											</InputAdornment>
										}
										eventHandle={(val) => {
											this.setState({ filter: val });
										}}
										field="filter"
										label=""
									/>
								</form>
							)}
						</Form>
					</Grid>
					<Grid
						item
						xs={1}
						style={{ marginLeft: -12, marginRight: -12 }}></Grid>
					<Grid
						item
						xs={4}
						style={{ backgroundColor: "#ddd", marginRight: 12 }}>
						<Button
							title="Map View"
							onClick={() => this.setState({ buttonState: "map" })}
							className={classNames(
								classes.divideButton,
								buttonState === "map"
									? classes.buttonSelected
									: classes.buttonNotSelected
							)}>
							<MapIcon
								className={
									buttonState === "map"
										? classes.iconSelected
										: classes.iconNotSelected
								}
							/>
						</Button>
						<Button
							title="Table View"
							onClick={() => this.setState({ buttonState: "table" })}
							className={classNames(
								classes.divideButton,
								buttonState === "table"
									? classes.buttonSelected
									: classes.buttonNotSelected
							)}>
							<ListIcon
								className={
									buttonState === "table"
										? classes.iconSelected
										: classes.iconNotSelected
								}
							/>
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
						className={
							buttonState === "map" ? classes.mapHeight2 : classes.gridHeight
						}>
						{buttonState === "map" && (
							<DashboardMap
								pageType="Implementation Projects"
								filtered_data={filtered_items}
							/>
						)}
						{buttonState === "table" && (
							<Table className={classes.table}>
								<EnhancedTableHead
									columnData={columnData}
									order={order}
									orderBy={orderBy}
									onRequestSort={this.handleRequestSort}
								/>
								<TableBody>
									{filtered_items
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((item) => (
											<TableRow key={item.id}>
												<TableCell>
													<Tooltip title={"View Data"}>
														<Button
															className={classes.tableCellIcon}
															onClick={() =>
																this.props.history.push({
																	pathname: "/implementationdetail/" + item.id,
																})
															}>
															<Icon
																path={mdiEye}
																size={1}
																color="primary"></Icon>{" "}
														</Button>
													</Tooltip>
												</TableCell>
												<TableCell>{item.project_id}</TableCell>
												<TableCell>{item.project_name}</TableCell>
												<TableCell>{item.source_gsa_label}</TableCell>
												<TableCell>{item.projectstatus}</TableCell>{" "}
												<TableCell>{item.project_mechanism}</TableCell>
											</TableRow>
										))}
									{filtered_items.length < 1 && (
										<TableRow>
											<TableCell colSpan={4} className={classes.centerAlign}>
												No Projects Found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
								{filtered_items.length > 25 && (
									<TableFooter>
										<TableRow>
											<TablePagination
												colSpan={4}
												count={filtered_items.length}
												rowsPerPage={rowsPerPage}
												page={page}
												onChangePage={this.handleChangePage}
												onChangeRowsPerPage={this.handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActionsWrapped}
											/>
										</TableRow>
									</TableFooter>
								)}
							</Table>
						)}
					</Grid>
				</Grid>
				<WarningDialog
					confirmAction={() => {
						ormProjectFormDelete(deleteId);
						this.setState({ showDelete: false });
					}}
					cancelAction={() => this.setState({ showDelete: false })}
					open={showDelete}
					title="Delete Project"
					confirmText="Delete Project"
					text={"Are you sure you wish to delete " + deleteName + "?"}
				/>
			</AppContainer>
		);
	}
}

GridViewImplementationProjects = connect(
	(state, ownProps) => ({
		projforms: projForm(state),
		repeatlaststatus: repeatLastStatus(state),
		authState: state.auth,
	}),
	{
		...ProjectForm.actions,
	}
)(GridViewImplementationProjects);
export default withStyles(styles)(withRouter(GridViewImplementationProjects));
