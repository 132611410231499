import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitIcon from "@material-ui/icons/ExitToApp";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import pack from "../../../package.json";

import MaterialIcon from "material-icons-react";

import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import * as navActions from "./actions";
import { reloadAll } from "./orm";

import * as app_settings from "../../app_settings";

const styles = {
    divWrapper: {
        width: "70%",
        textAlign: "right",
    },
    inlineRight: {
        display: "inline-block",
        marginRight: "40px",
        marginTop: 22,
    },
    inlineLeft: {
        display: "inline-block",
        marginLeft: "20px",
        marginTop: 17,
        marginBottom: 17,
        float: "left",
    },
    headline: {
        textAlign: "right",
        zIndex: 2,
    },
    zIndex: {
        zIndex: 1300,
        height: "50px",
        minHeight: "50px",
    },
    listItem: {
        backgroundColor: "#eaeaea",
        borderBottom: "1px solid #666666",
        padding: "5px",
    },
    alertText: {
        color: "#666666",
    },
    topListItem: {
        borderBottom: "1px solid #666666",
    },
    topAlertText: {
        color: "#666666",
        fontSize: "21px",
        fontWeight: "200",
    },
    list: {
        "& ul": {
            padding: 0,
        },
    },
    updateReady: {
        color: "#fff",
        backgroundColor: "#4e4e4e",
        position: "absolute",
        width: "100%",
        height: 50,
        zIndex: 9999,
    },
    materialicon: {
        width: "0px",
        paddingRight: "25px",
    },
};

class Header extends Component {
    constructor() {
        super();
        this.state = {
            userMenuAnchor: null,
        };
    }

    handleUserMenu = event => {
        this.setState({ userMenuAnchor: event.currentTarget });
    };

    handleUserMenuClose = () => {
        this.setState({ userMenuAnchor: null });
    };

    logOut = () => {
        this.props.well_selected(undefined, undefined);
        this.props.authLogout();
        this.props.navSelectPage("");
        this.props.history.push("/");
    };

    editUserProfile = () => {
        this.props.navSelectPage("");
        this.props.history.push("/userprofile");
    };

    render() {
        const { classes, authenticated, authState, navState } = this.props;
        const { userMenuAnchor } = this.state;
        const userMenuOpen = Boolean(userMenuAnchor);

        if (!authState) return "...";

        var { user } = authState;
        if (!user) {
            user = {};
            if (authenticated) {
                // Page requires authentication
                if (!navState.storeReady) {
                    // Give store a chance to reload
                    user.first_name = "...";
                } else {
                    user.first_name = "Not Logged In!";
                }
            }
        }

        const updateReady = navState.sw && navState.sw.update;

        const UpdateHeader = () => (
            <Toolbar className={classes.updateReady}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="subheading" color="inherit">
                            A newer version of the application is available. Please save your work and refresh your
                            browser. &nbsp;
                            <Button variant="contained" onClick={() => window.location.reload()}>
                                Refresh Now
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        );

        return authenticated ? ( // Logged In
            <AppBar position="fixed" style={{ minHeight: "50px", maxHeight: "50px" }} className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                <Toolbar style={{ maxHeight: "50px", minHeight: "50px" }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={classes.headline}>
                            <Typography variant="h4" style={{ color: "black" }} className={classes.inlineLeft}>
                                {app_settings.app_title}
                            </Typography>
                            <IconButton
                                aria-owns={userMenuOpen ? "menu-appbar" : null}
                                aria-haspopup="true"
                                onClick={this.handleUserMenu}
                                color="inherit">
                                <AccountCircle />
                            </IconButton>
                            <Typography variant="subtitle1" className={classes.inlineRight}>
                                Hi, {user.first_name} {user.role === "Administrator" && "(Administrator)"}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Menu
                        id="menu-appbar"
                        anchorEl={userMenuAnchor}
                        classes={{
                            paper: classes.list,
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={userMenuOpen}
                        onClose={this.handleUserMenuClose}>
                        <ListItem title="User Options" button className={classes.topListItem}>
                            <ListItemText
                                primary="User Options"
                                classes={{
                                    primary: classes.topAlertText,
                                }}
                            />
                        </ListItem>
                        <ListItem
                            title="Update Profile"
                            button
                            onClick={this.editUserProfile}
                            className={classes.listItem}>
                            <ListItemIcon className={classes.materialicon}>
                                <MaterialIcon icon="create" />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primary="Update Profile"
                                classes={{
                                    primary: classes.alertText,
                                }}
                            />
                        </ListItem>
                        <ListItem title="Log Out" button onClick={this.logOut} className={classes.listItem}>
                            <ListItemIcon className={classes.alertText}>
                                <ExitIcon />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primary="Log Out"
                                classes={{
                                    primary: classes.alertText,
                                }}
                            />
                        </ListItem>
                        <ListItem title={"Version " + pack.version} button className={classes.listItem}>
                            <ListItemIcon className={classes.materialicon}>
                                <MaterialIcon icon="keyboard_arrow_right" />
                            </ListItemIcon>
                            <ListItemText
                                inset
                                primary={"Version " + pack.version}
                                classes={{
                                    primary: classes.alertText,
                                }}
                            />
                        </ListItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        ) : (
            // Public
            <AppBar position="fixed" className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                <Toolbar>
                    <Grid container spacing={24}>
                        <Grid item xs={9} className={classes.headline}>
                            <div style={{ paddingBottom: "28px" }}>
                                <Typography
                                    variant="h4"
                                    style={{ color: "black", marginTop: "0px" }}
                                    className={classes.inlineLeft}>
                                    {app_settings.app_title}
                                </Typography>
                                <div className={classes.divWrapper}>
                                    <Typography
                                        variant="caption"
                                        color="inherit"
                                        style={{ position: "absolute", right: "10px", top: "30px" }}>
                                        Version {pack.version}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

Header = connect(state => ({ authState: state.auth, navState: state.nav }), {
    ...authActions,
    ...navActions,
    reload: reloadAll,
})(Header);

export default withStyles(styles)(withRouter(Header));
