const app_title = "Chowchilla Subbasin DMS";
const basin = "Chowchilla Subbasin";
const contact_email = "test@test.org";
const nav_components = [
	{
		name: "dashboard_map",
		show: true,
		role: "none",
		title: "Dashboard Map",
		link: "/dashboard",
		navSelectPage: "dashboard",
		icon: {
			type: "mdi",
			name: "mdiMap",
		},
	},
	{
		name: "water_level_monitoring",
		show: true,
		role: "none",
		title: "Water Level Monitoring",
		link: "/waterlevellist",
		navSelectPage: "waterlevels",
		icon: {
			type: "mdi",
			name: "mdiWaves",
		},
	},
	{
		name: "water_quality_monitoring",
		show: true,
		role: "none",
		title: "Water Quality Monitoring",
		link: "/waterqualitylist",
		navSelectPage: "waterqualitylist",
		icon: {
			type: "material-ui",
			name: "OpacityIcon",
		},
		index: 2,
	},
	{
		name: "swgw_interaction_monitoring",
		show: true,
		role: "none",
		title: "SW - GW Interaction Monitoring",
		link: "/swgwlist",
		navSelectPage: "wellslist",
		icon: {
			type: "mdi",
			name: "mdiViewDashboard",
		},
	},
	{
		name: "subsidence_monitoring",
		show: true,
		role: "none",
		title: "Subsidence Monitoring",
		link: "/subsidencelist",
		navSelectPage: "subsidencelist",
		icon: {
			type: "mdi",
			name: "mdiAlertOutline",
		},
	},
	{
		name: "groundwater_storage",
		show: true,
		role: "none",
		title: "GW Change in Storage",
		link: "/groundwaterstorage",
		navSelectPage: "groundwaterstorage",
		icon: {
			type: "mdi",
			name: "mdiViewAgenda",
		},
	},
	{
		name: "groundwater_extraction",
		show: true,
		role: "none",
		title: "Groundwater Extraction",
		link: "/groundwaterextraction",
		navSelectPage: "groundwaterextraction",
		icon: {
			type: "material-ui",
			name: "LocalDrink",
		},
	},
	{
		name: "surface_water_supply",
		show: true,
		role: "none",
		title: "Surface Water Supply",
		link: "/surfacewatersupply",
		navSelectPage: "surfacewatersupply",
		icon: {
			type: "material-ui",
			name: "ListAlt",
		},
	},
	{
		name: "total_water_supply",
		show: true,
		role: "none",
		title: "Total Water Use",
		link: "/totalwateruse",
		navSelectPage: "totalwateruse",
		icon: {
			type: "material-ui",
			name: "Storage",
		},
	},
	{
		name: "import_monitoring_data",
		show: true,
		role: "Administrator",
		title: "Import Monitoring Data",
		link: "/import",
		navSelectPage: "import",
		icon: {
			type: "mdi",
			name: "mdiSwapVertical",
		},
	},
	{
		name: "reporting",
		show: true,
		role: "NotAgency",
		title: "Report Water Supply<br/> and Storage Data",
		link: "/reporting",
		navSelectPage: "reporting",
		icon: {
			type: "mdi",
			name: "mdiCheckDecagram",
		},
	},
	{
		name: "gs_reporting_status",
		show: false,
		role: "NotAgency",
		title: "GSP Reporting Status",
		link: "/gspreportingstatus",
		navSelectPage: "gspreportingstatus",
		icon: {
			type: "mdi",
			name: "mdiCheckDecagram",
		},
	},
	{
		name: "administration",
		show: true,
		role: "Administrator",
		title: "Administration",
		link: "/administration",
		navSelectPage: "",
		icon: {
			type: "mdi",
			name: "mdiSettings",
		},
	},
];
const map_initial_state = {
	lat: 37.0545,
	lng: -120.25,
	zoom: 10,
	collapsed: false,
	showLayerList: true,
};

const map_layers = [
	{
		id: "esri_topographic",
		add: true,
		checked: true,
		type: "baselayer",
		type2: "topographic",
		name: "ESRI Topographic",
		attribution: "ESRI",
		url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
	},
	{
		id: "esri_streets",
		add: true,
		checked: false,
		type: "baselayer",
		type2: "street",
		name: "ESRI Streets",
		attribution: "ESRI",
		url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
	},
	{
		id: "esri_imagery",
		add: true,
		checked: false,
		type: "baselayer",
		type2: "imagery",
		name: "ESRI Imagery",
		attribution: "ESRI",
		url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
	},
	// {
	// 	id: "well_sites",
	// 	add: true,
	// 	checked: true,
	// 	title: "Well Sites",
	// 	type: "maplayer",
	// 	type2: "well_sites",
	// 	page_type: "Water Level",
	// 	link: "waterleveldetail/",
	// 	color: {
	// 		hex: "#0000FF",
	// 		rgb: "blue",
	// 	},
	// 	markerSize: 8,
	// },
	{
		id: "water_level_sites",
		add: true,
		checked: true,
		title: "Water Level - Representative Monitoring Sites",
		type: "maplayer",
		type2: "water_level_sites",
		page_type: "Water Level",
		link: "waterleveldetail/",
		color: {
			hex: "#CF382D",
			rgb: "red",
		},
		markerSize: 8,
		identify: true,
		index: 0,
	},
	{
		id: "waater_level_sites_other",
		add: true,
		checked: false,
		title: "Water Level - Other Monitoring Sites",
		type: "maplayer",
		type2: "waater_level_sites_other",
		page_type: "Water Level",
		link: "waterleveldetail/",
		color: {
			//uses color in maplayers.js from svg icon this doesnt matter
			hex: "#e59400",
			rgb: "orange",
		},
		square: true,
		markerSize: 8,
		identify: true,
		index: 1,
	},
	{
		id: "water_quality_monitoring_sites",
		add: true,
		checked: false,
		title: "Water Quality Monitoring Sites",
		type: "maplayer",
		type2: "water_quality_monitoring_sites",
		page_type: "Water Quality",
		link: "waterqualitydetail/",
		color: {
			hex: "#2dd137",
			rgb: "green",
		},
		markerSize: 8,
		identify: true,
		index: 2,
	},
	{
		id: "project_forms",
		add: true,
		checked: false,
		title: "Implementation Projects",
		type: "maplayer",
		type2: "project_forms",
		page_type: "Implementation Projects",
		link: "implementationprojects/",
		color: {
			hex: "#ff00ff",
			rgb: "magenta",
		},
		markerSize: 8,
		identify: true,
		star: true,
		index: 3,
	},
	{
		id: "subsidence_monitoring_sites",
		add: true,
		checked: false,
		title: "Subsidence Monitoring Sites",
		type: "maplayer",
		type2: "subsidence_monitoring_sites",
		page_type: "Subsidence",
		link: "subsidencedetail/",
		filter: { gsp_mon_network: false },
		color: {
			hex: "#EF8D2F",
			rgb: "orange",
		},
		markerSize: 8,
	},
	{
		id: "streamflow_monitoring_sites",
		add: true,
		checked: false,
		title: "Streamflow Monitoring Sites",
		type: "maplayer",
		type2: "streamflow_monitoring_sites",
		page_type: "Streamflow",
		link: "streamflowdetail/",
		color: {
			hex: "#1c69c6",
			rgb: "blue",
		},
		markerSize: 8,
		identify: true,
	},
	{
		id: "surface_water_monitoring_sites",
		add: false,
		checked: true,
		title: "Surface Water Monitoring Sites",
		type: "maplayer",
		type2: "surface_water_monitoring_sites",
		page_type: "SWGW",
		link: "swgwdetail/",
		color: {
			hex: "#6DA42E",
			rgb: "green",
		},
	},

	{
		id: "chowchilla_subbasin_boundary",
		add: true,
		title: "Chowchilla Subbasin Boundary",
		checked: true,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ title: "Boundary", color: "rgb(75,75,75)" }],
		layers: "chowchilla_subbasin",
		map: "/var/www/chowchilla/maps/map.map",
		url: "https://chowchilladms.houstoneng.net/cgi-bin/mapserv",
		link: "swgwdetail/",
		identify: true,
	},
	{
		id: "water_budget_subregions",
		add: true,
		title: "Water Budget Subregions",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ title: "Water Budget Subregions", color: "rgb(204,204,0)" }],
		layers: "chowchilla_subbasin_wb_subareas_wm",
		map: "/var/www/chowchilla/maps/map.map",
		url: "https://chowchilladms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
	{
		id: "rivers_creeks_sloughs",
		add: true,
		title: "Rivers, Creeks, Sloughs",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ color: "rgb(2,2,255)", title: "Rivers, Creeks, Sloughs" }],
		layers: "rivers_creeks_sloughs",
		map: "/var/www/chowchilla/maps/map.map",
		url: "https://chowchilladms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
	{
		id: "gsa_boundaries",
		add: true,
		title: "GSA Boundaries",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ title: "GSA Boundaries", color: "#0000FF" }],
		layers: "chowchilla_gsa",
		map: "/var/www/chowchilla/maps/map.map",
		url: "https://chowchilladms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
	{
		id: "clay",
		add: true,
		title: "E Clay Eastern Extent",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ color: "rgb(179,98,0)", title: "E Clay Eastern Extent" }],
		layers: "clay",
		map: "/var/www/kings/maps/map.map",
		url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
		identify: false,
	},
	{
		id: "depth_base",
		add: false,
		title: "Depth to Base of Unconfined Groundwater",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		layers: "depth_base",
		legend: [
			{
				color: "rgb(0,171,179",
				title: "Depth to Base of Unconfined Groundwater",
			},
		],
		map: "/var/www/kings/maps/map.map",
		url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
		identify: false,
	},
	{
		id: "yield_units",
		add: false,
		title: "Specific Yield Units",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [{ color: "rgb(179,0,82)", title: "Specific Yield Units" }],
		layers: "yield_units",
		map: "/var/www/kings/maps/map.map",
		url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
		identify: false,
	},
	{
		id: "landuse_2014",
		add: true,
		title: "Land Use DWR 2014",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [
			{ color: "rgba(166,206,227,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
			{ color: "rgba(115,76,0,0.5)", title: "Almonds" },
			{ color: "rgba(178,223,138,0.5)", title: "Apples" },
			{ color: "rgba(51,160,44,0.5)", title: "Bush Berries" },
			{ color: "rgba(251,154,153,0.5)", title: "Carrots" },
			{ color: "rgba(227,26,28,0.5)", title: "Cherries" },
			{ color: "rgba(252,170,0,0.5)", title: "Citrus" },
			{ color: "rgba(255,127,0,0.5)", title: "Corn, Sorghum and Sudan" },
			{ color: "rgba(202,178,214,0.5)", title: "Cotton" },
			{
				color: "rgba(106,61,154,0.5)",
				title: "Flowers, Nursery and Christmas Tree Farms",
			},
			{ color: "rgba(185,112,224,0.5)", title: "Grapes" },
			{ color: "rgba(177,89,40,0.5)", title: "Idle" },
			{ color: "rgba(141,211,199,0.5)", title: "Kiwis" },
			{ color: "rgba(65,171,93,0.5)", title: "Lettuce/Leafy Greens" },
			{ color: "rgba(190,186,218,0.5)", title: "Managed Wetland" },
			{ color: "rgba(251,128,114,0.5)", title: "Melons, Squash and Cucumbers" },
			{ color: "rgba(128,177,211,0.5)", title: "Miscellaneous Deciduous" },
			{ color: "rgba(253,180,98,0.5)", title: "Miscellaneous Grain and Hay" },
			{ color: "rgba(179,222,105,0.5)", title: "Miscellaneous Grasses" },
			{
				color: "rgba(252,205,229,0.5)",
				title: "Miscellaneous Subtropical Fruits",
			},
			{ color: "rgba(217,217,217,0.5)", title: "Miscellaneous Truck Crops" },
			{ color: "rgba(188,128,189,0.5)", title: "Mixed Pasture" },
			{ color: "rgba(204,235,197,0.5)", title: "Olives" },
			{ color: "rgba(255,237,111,0.5)", title: "Onions and Garlic" },
			{ color: "rgba(127,59,8,0.5)", title: "Peaches/Nectarines" },
			{ color: "rgba(179,88,6,0.5)", title: "Pears" },
			{ color: "rgba(194,82,60,0.5)", title: "Pistachios" },
			{ color: "rgba(253,184,99,0.5)", title: "Plums, Prunes and Apricots" },
			{ color: "rgba(254,224,182,0.5)", title: "Pomegranates" },
			{ color: "rgba(227,26,28,0.5)", title: "Potatoes and Sweet Potatoes" },
			{ color: "rgba(206,18,86,0.5)", title: "Strawberries" },
			{ color: "rgba(178,171,210,0.5)", title: "Tomatoes" },
			{ color: "rgba(128,115,172,0.5)", title: "Urban" },
			{ color: "rgba(84,39,136,0.5)", title: "Walnuts" },
			{ color: "rgba(45,0,75,0.5)", title: "Wheat" },
			{ color: "rgba(142,1,82,0.5)", title: "Young Perennials" },
		],
		layers: "landuse2014",
		map: "/var/www/chowchilla/maps/map.map",
		url: "https://chowchilladms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
	{
		id: "landuse_2016",
		add: true,
		title: "Land Use DWR 2016",
		checked: false,
		type: "wmstilelayer",
		transparent: true,
		opacity: "1",
		format: "image/png",
		legend: [
			{ color: "rgba(252,170,0,0.5", title: "Citrus" },
			{ color: "rgba(185,112,224,0.5)", title: "Grapes" },
			{ color: "rgba(194,82,60,0.5)", title: "Pistachios" },
			{ color: "rgba(115,76,0,0.5)", title: "Almonds" },
			{ color: "rgba(168,122,29,0.5)", title: "Misc Deciduous" },
			{ color: "rgba(247,215,7,0.5)", title: "Cotton" },
			{ color: "rgba(168,168,0,0.5)", title: "Corn,Sorghum and Sudan" },
			{ color: "rgba(38 ,115,0,0.5)", title: "Alfalfa and Alfalfa Mixtures" },
			{ color: "rgba(145,217,0,0.5)", title: "Misc Grain and Hay" },
			{ color: "rgba(163,255,115,0.5)", title: "Mixed Pasture" },
			{ color: "rgba(11,44,122,0.5)", title: "Misc Field Crops" },
			{
				color: "rgba(255,115,223,0.5)",
				title: "Truck,Nursery,and Berry Crops",
			},
			{ color: "rgba(255,167,127,0.5)", title: "Young Perennials" },
			{ color: "rgba(64,101,235,0.5)", title: "Managed Wetland" },
			{ color: "rgba(137,205,102,0.5)", title: "Native Vegetation" },
			{ color: "rgba(225,225,225,0.5)", title: "Semi Agricultural" },
			{ color: "rgba(178,178,178,0.5)", title: "Urban" },
			{ color: "rgba(255,235 ,175,0.5)", title: "Idle" },
		],
		layers: "landuse2016",
		map: "/var/www/chowchilla/maps/map.map",
		url: "https://chowchilladms.houstoneng.net/cgi-bin/mapserv",
		identify: true,
	},
	// {
	// 	id: "wlc_spring_1996",
	// 	add: true,
	// 	title: "Spring 1996",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1996" },
	// 	],
	// 	layers: "spring_1996",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_1997",
	// 	add: true,
	// 	title: "Spring 1997",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1997" },
	// 	],
	// 	layers: "spring_1997",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_1998",
	// 	add: true,
	// 	title: "Spring 1998",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1998" },
	// 	],
	// 	layers: "spring_1998",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_1999",
	// 	add: true,
	// 	title: "Spring 1999",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 1999" },
	// 	],
	// 	layers: "spring_1999",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2000",
	// 	add: true,
	// 	title: "Spring 2000",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2000" },
	// 	],
	// 	layers: "spring_2000",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2001",
	// 	add: true,
	// 	title: "Spring 2001",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2001" },
	// 	],
	// 	layers: "spring_2001",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2002",
	// 	add: true,
	// 	title: "Spring 2002",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2002" },
	// 	],
	// 	layers: "spring_2002",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2003",
	// 	add: true,
	// 	title: "Spring 2003",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2003" },
	// 	],
	// 	layers: "spring_2003",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2004",
	// 	add: true,
	// 	title: "Spring 2004",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2004" },
	// 	],
	// 	layers: "spring_2004",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2005",
	// 	add: true,
	// 	title: "Spring 2005",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2005" },
	// 	],
	// 	layers: "spring_2005",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2006",
	// 	add: true,
	// 	title: "Spring 2006",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2006" },
	// 	],
	// 	layers: "spring_2006",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2007",
	// 	add: true,
	// 	title: "Spring 2007",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2007" },
	// 	],
	// 	layers: "spring_2007",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2008",
	// 	add: true,
	// 	title: "Spring 2008",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2008" },
	// 	],
	// 	layers: "spring_2008",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2009",
	// 	add: true,
	// 	title: "Spring 2009",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2009" },
	// 	],
	// 	layers: "spring_2009",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2011",
	// 	add: true,
	// 	title: "Spring 2011",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2011" },
	// 	],
	// 	layers: "spring_2011",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2012",
	// 	add: true,
	// 	title: "Spring 2012",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2012" },
	// 	],
	// 	layers: "spring_2012",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2013",
	// 	add: true,
	// 	title: "Spring 2013",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2013" },
	// 	],
	// 	layers: "spring_2013",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2015",
	// 	add: true,
	// 	title: "Spring 2015",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2015" },
	// 	],
	// 	layers: "spring_2015",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2016",
	// 	add: true,
	// 	title: "Spring 2016",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2016" },
	// 	],
	// 	layers: "spring_2016",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2017",
	// 	add: true,
	// 	title: "Spring 2017",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2017" },
	// 	],
	// 	layers: "spring_2017",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2018",
	// 	add: true,
	// 	title: "Spring 2018",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2018" },
	// 	],
	// 	layers: "spring_2018",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_spring_2019",
	// 	add: true,
	// 	title: "Spring 2019",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2019" },
	// 	],
	// 	layers: "spring_2019",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_fall_2017",
	// 	add: true,
	// 	title: "Fall 2017",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2017" },
	// 	],
	// 	layers: "fall_2017",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
	// {
	// 	id: "wlc_Fall_2018",
	// 	add: true,
	// 	title: "Fall 2018",
	// 	checked: false,
	// 	selector: "contourSelector",
	// 	type: "wmstilelayer",
	// 	transparent: true,
	// 	opacity: "1",
	// 	format: "image/png",
	// 	legend: [
	// 		{ color: "rgb(45,41,160)", title: "Water Level Contours - Fall 2018" },
	// 	],
	// 	layers: "fall_2018",
	// 	map: "/var/www/kings/maps/map.map",
	// 	url: "https://kingsdms.houstoneng.net/cgi-bin/mapserv",
	// 	identify: false,
	// },
];

export {
	app_title,
	basin,
	contact_email,
	nav_components,
	map_initial_state,
	map_layers,
};
