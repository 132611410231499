import React, { Component } from "react";
import { connect } from "react-redux";
// import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";
import NumberFormat from "react-number-format";
import WarningDeleteDialog from "../../api/warningDeleteDialogHOC";
import MeasurementsHOC from "../../api/measurementsHOC";
import NotReady from "../common/NotReady";

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	expansionBorder: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
	},
	lastBorder: {
		[theme.breakpoints.up("xl")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	lastBorder2: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	expandedMargin: {
		marginTop: "12px",
		minHeight: "48px !important",
		maxHeight: 48,
	},
	borderContainer: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
		borderRight: "2px solid " + theme.palette.primary.light,
		borderLeft: "2px solid " + theme.palette.primary.light,
	},
	borderContainer2: {
		border: "1px solid rgb(221, 221, 221)",
	},
	buttonSelected: {
		border: "3px solid " + theme.palette.primary.main,
		minWidth: "44px",
	},
	buttonNotSelected: {
		border: "3px solid #666",
		minWidth: "44px",
	},
	iconSelected: {
		color: theme.palette.primary.main,
	},
	iconNotSelected: {
		color: "#666",
	},
	divideButton: {
		marginBottom: 16,
		width: "calc(50% - 16px)",
		marginTop: 16,
		minWidth: 44,
	},
	divideContainer: {
		backgroundColor: "#ddd",
		textAlign: "center",
		margin: "0 -4px",
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		minWidth: 500,
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	marginLeft: {
		marginLeft: -16,
	},
	chartTitle: {
		backgroundColor: "rgb(221, 221, 221)",
		textAlign: "center",
		padding: "8px 4px !important",
	},
	buttons: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginRight: "15px",
		marginTop: "10px",
		marginBottom: "10px",
	},
});

class ImportMonitoringHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: "desc",
			orderBy: "water_year",
			page: 0,
			rowsPerPage: 10,
			showDelete: false,
		};
	}

	componentDidMount() {
		const { authState, history } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}

	columnData(id) {
		const groundwaterLevelDataCols = [
			{
				id: "dms_site_id",
				numeric: false,
				label: "DMS Site",
				allowSort: true,
			},
			{
				id: "measurement_date",
				numeric: false,
				label: "Measurement Date",
				allowSort: true,
			},
			{
				id: "measurement_time",
				numeric: false,
				label: "Measurement Time",
				allowSort: true,
			},
			{
				id: "measurement_depth",
				numeric: true,
				label: "Measurement Depth",
				allowSort: true,
			},
			{
				id: "surf_elev",
				numeric: true,
				label: "Surface Elevation",
				allowSort: true,
			},
			{
				id: "vert_datum",
				numeric: false,
				label: "Vertical Datum",
				allowSort: true,
			},
			{
				id: "meas_method",
				numeric: false,
				label: "Measurement Method",
				allowSort: true,
			},
			{
				id: "meas_accuracy",
				numeric: false,
				label: "Measurement Accuracy",
				allowSort: true,
			},
			{
				id: "quest_meas",
				numeric: false,
				label: "Questionable Measurement",
				allowSort: true,
			},
			{
				id: "measured_by",
				numeric: false,
				label: "Measured By",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: true,
				label: "Submitted",
				allowSort: true,
			},
		];

		const groundwaterstorageDataCols = [
			{
				id: "gsp_id",
				numeric: false,
				label: "GSP Name",
				allowSort: true,
			},
			{
				id: "gw_stor_ua",
				numeric: false,
				label: "GW Upper Aquifer",
				allowSort: true,
			},
			{
				id: "gw_stor_la",
				numeric: false,
				label: "GW Lower Aquifier",
				allowSort: true,
			},
			{
				id: "gw_stor_total",
				numeric: true,
				label: "GW Storage Total",
				allowSort: true,
			},
			{
				id: "gw_stor_acc_notes",
				numeric: true,
				label: "GW Storage Notes",
				allowSort: true,
			},
			{
				id: "water_year",
				numeric: false,
				label: "Water Year",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: true,
				label: "Submitted",
				allowSort: true,
			},
		];
		
		const surfacewaterDataCols = [
			{
				id: "gsp_id",
				numeric: false,
				label: "GSP Name",
				allowSort: true,
			},
			{
				id: "gsa_name_id",
				numeric: false,
				label: "GSA Name",
				allowSort: true,
			},
			{
				id: "month",
				numeric: false,
				label: "Month",
				allowSort: true,
			},
			{
				id: "water_year",
				numeric: false,
				label: "Year",
				allowSort: true,
			},
			{
				id: "sw_import_cv",
				numeric: false,
				label: "Import Central Valley",
				allowSort: true,
			},
			{
				id: "sw_import_sw",
				numeric: false,
				label: "Import State Water",
				allowSort: true,
			},
			{
				id: "sw_localsup",
				numeric: true,
				label: "Managed Imported Location Imported Supplies",
				allowSort: true,
			},
			 
			{
				id: "sw_recycled",
				numeric: false,
				label: "Recycled Water",
				allowSort: true,
			},
			{
				id: "sw_flood_water",
				numeric: true,
				label: "Floor water of any Water Source",
				allowSort: true,
			},
			{
				id: "sw_other",
				numeric: true,
				label: "Other",
				allowSort: true,
			},
			{
				id: "sw_other_desc",
				numeric: true,
				label: "Other Description",
				allowSort: true,
			},
			{
				id: "sw_calcm",
				numeric: false,
				label: "Explain Methods",
				allowSort: true,
			},
		 
		];
		const waterQualityDataCols = [
			{
				id: "dms_site_id",
				numeric: false,
				label: "DMS Site ID",
				allowSort: true,
			},
			{
				id: "meas_date",
				numeric: false,
				label: "Measurement Date",
				allowSort: true,
			},
			{
				id: "meas_time",
				numeric: false,
				label: "Measurement Time",
				allowSort: true,
			},
			{
				id: "lab_name",
				numeric: false,
				label: "Lab Name",
				allowSort: true,
			},
			{
				id: "analytical_method",
				numeric: false,
				label: "Analytical Method",
				allowSort: true,
			},
			{
				id: "characteristic",
				numeric: false,
				label: "Characteristic (Chemical)",
				allowSort: true,
			},
			{
				id: "result_value",
				numeric: true,
				label: "Result Value",
				allowSort: true,
			},
			{
				id: "result_units",
				numeric: false,
				label: "Result Units",
				allowSort: true,
			},
			{
				id: "result_qualifier",
				numeric: false,
				label: "Result Qualifier",
				allowSort: true,
			},
			{
				id: "sampled_by",
				numeric: false,
				label: "Sampled By",
				allowSort: true,
			},
			{
				id: "meas_comments",
				numeric: false,
				label: "Comments",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: true,
				label: "Submitted",
				allowSort: true,
			},
		];

		const surfaceWaterCols = [
			{
				id: "dms_site_id",
				numeric: true,
				label: "Subsidence Site",
				allowSort: true,
			},
			{
				id: "meas_date",
				numeric: false,
				label: "Measurement Date",
				allowSort: true,
			},
			{
				id: "meas_time",
				numeric: false,
				label: "Measurement Time",
				allowSort: true,
			},
			{
				id: "ua_compaction",
				numeric: true,
				label: "Compaction",
				allowSort: true,
			},
			{
				id: "meas_elevation",
				numeric: true,
				label: "Measurement Elevation",
				allowSort: true,
			},
			{
				id: "total_subsidence",
				numeric: true,
				label: "Total Subsidence",
				allowSort: true,
			},
			{
				id: "meas_accuracy",
				numeric: true,
				label: "Measurement Accuracy",
				allowSort: true,
			},
			{
				id: "meas_comments",
				numeric: false,
				label: "Measurement Comments",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: true,
				label: "Submitted",
				allowSort: true,
			},
		];
		const totalwaterCols = [
			{
				id: "gsp_id",
				numeric: false,
				label: "GSP Name",
				allowSort: true,
			},
			{
				id: "gsa_name_id",
				numeric: false,
				label: "GSA Name",
				allowSort: true,
			},
			{
				id: "month",
				numeric: false,
				label: "Month",
				allowSort: true,
			},
			{
				id: "water_year",
				numeric: false,
				label: "Year",
				allowSort: true,
			},
			{
				id: "total",
				numeric: true,
				label: "Total",
				allowSort: true,
			},
			{
				id: "t_dom_total",
				numeric: true,
				label: "Urban",
				allowSort: true,
			},
			{
				id: "t_ind_total",
				numeric: true,
				label: "Industrial",
				allowSort: true,
			},
			{
				id: "t_agg_total",
				numeric: true,
				label: "Agricultural",
				allowSort: true,
			},
			{
				id: "t_wet_total",
				numeric: false,
				label: "Managed Wetlands",
				allowSort: true,
			},
			{
				id: "t_dist_total",
				numeric: true,
				label: "District Managed Recharge",
				allowSort: true,
			},
			{
				id: "t_veg_total",
				numeric: false,
				label: "Native Vegetation",
				allowSort: true,
			},
			{
				id: "t_other_total",
				numeric: true,
				label: "Other",
				allowSort: true,
			},
		];
		const evapotranspirationCols = [
			{
				id: "gsp_name_label",
				numeric: false,
				label: "GSP Organization Name",
				allowSort: true,
			},
			{
				id: "gsa_name_label",
				numeric: false,
				label: "GSA Organization Name",
				allowSort: true,
			},	
			{ id: "month", numeric: true, label: "Month", allowSort: true },
			{ id: "water_year", date: true, label: "Year", allowSort: true },
			{
				id: "ur_total",
				numeric: true,
				label: "Urban Total",
				allowSort: true,
			},

			{
				id: "agg_total",
				numeric: true,
				label: "Agricultural Total",
				allowSort: true,
			},
			{
				id: "man_total",
				numeric: true,
				label: "Managed Recharge Total",
				allowSort: true,
			},
			{
				id: "nat_total",
				numeric: true,
				label: "Native Vegetation Total",
				allowSort: true,
			},
			{
				id: "sur_total",
				numeric: true,
				label: "Surface Water System Total",
				allowSort: true,
			},

			{
				id: "other_total",
				numeric: true,
				label: "Other Total",
				allowSort: true,
			},
		];
		const subsidenceCols = [
			{
				id: "dms_site_id",
				numeric: true,
				label: "Subsidence Site",
				allowSort: true,
			},
			{
				id: "meas_date",
				numeric: false,
				label: "Measurement Date",
				allowSort: true,
			},
			{
				id: "meas_time",
				numeric: false,
				label: "Measurement Time",
				allowSort: true,
			},
			{
				id: "ua_compaction",
				numeric: true,
				label: "Compaction",
				allowSort: true,
			},
			{
				id: "meas_elevation",
				numeric: true,
				label: "Measurement Elevation",
				allowSort: true,
			},
			{
				id: "total_subsidence",
				numeric: true,
				label: "Total Subsidence",
				allowSort: true,
			},
			{
				id: "meas_accuracy",
				numeric: true,
				label: "Measurement Accuracy",
				allowSort: true,
			},
			{
				id: "meas_comments",
				numeric: false,
				label: "Measurement Comments",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: true,
				label: "Submitted",
				allowSort: true,
			},
		];

		const streamCols = [
			{
				id: "dms_site_id",
				numeric: true,
				label: "Subsidence Site",
				allowSort: true,
			},
			{
				id: "meas_date",
				numeric: false,
				label: "Measurement Date",
				allowSort: true,
			},
			{
				id: "meas_time",
				numeric: false,
				label: "Measurement Time",
				allowSort: true,
			},
			{
				id: "stream_stage",
				numeric: true,
				label: "Stream Stage",
				allowSort: true,
			},
			{
				id: "meas_comments",
				numeric: false,
				label: "Measurement Comments",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: true,
				label: "Submitted",
				allowSort: true,
			},
		];

		const gweCols = [
			{
				id: "gsa_name_id",
				numeric: false,
				label: "GSA/Subregion Name",
				allowSort: true,
				width: 200,
			},
			{
				id: "gsp_name_id",
				numeric: false,
				label: "GSP Name",
				allowSort: true,
				width: 200,
			},
			{
				id: "measurement_month",
				numeric: false,
				label: "Month",
				allowSort: true,
				width: 80,
			},
			{
				id: "gw_agg_acc",
				numeric: true,
				label: "Agricultural Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_agg_calcm",
				numeric: true,
				label: "Agricultural Calculation Method (Direct or Estimate)",
				allowSort: true,
			},
			{
				id: "gw_agg_la",
				numeric: true,
				label: "Agricultural - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_agg_method",
				numeric: true,
				label: "Agricultural Method",
				allowSort: true,
			},
			{
				id: "gw_agg_method_notes",
				numeric: true,
				label: "Agricultural Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_agg_total",
				numeric: true,
				label: "Agricultural - Total (AF)",
				allowSort: true,
			},
			{
				id: "gw_agg_ua",
				numeric: true,
				label: "Agricultural - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_dom_acc",
				numeric: true,
				label: "Urban/Domestic Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_dom_calcm",
				numeric: true,
				label: "Urban/Domestic Calculation Method (Direct or Estimate)",
				allowSort: true,
			},
			{
				id: "gw_dom_la",
				numeric: true,
				label: "Urban/Domestic - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_dom_method",
				numeric: true,
				label: "Urban/Domestic Method",
				allowSort: true,
			},
			{
				id: "gw_dom_method_notes",
				numeric: true,
				label: "Urban/Domestic Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_dom_total",
				numeric: true,
				label: "Urban/Domestic - Total (AF)",
				allowSort: true,
			},
			{
				id: "gw_dom_ua",
				numeric: true,
				label: "Urban/Domestic - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_ind_acc",
				numeric: true,
				label: "Industrial Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_ind_calcm",
				numeric: true,
				label: "Industrial Calculation Method (Direct or Estimated)",
				allowSort: true,
			},
			{
				id: "gw_ind_la",
				numeric: true,
				label: "Industrial - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_ind_method",
				numeric: true,
				label: "Industrial Method",
				allowSort: true,
			},
			{
				id: "gw_ind_method_notes",
				numeric: true,
				label: "Industrial Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_ind_total",
				numeric: true,
				label: "Industrial - Total (AF)",
				allowSort: true,
			},
			{
				id: "gw_ind_ua",
				numeric: true,
				label: "Industrial - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_mr_acc",
				numeric: true,
				label: "Managed Recharge Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_mr_calcm",
				numeric: true,
				label: "Managed Recharge Calculation Method (Direct or Estimated)",
				allowSort: true,
			},
			{
				id: "gw_mr_la",
				numeric: true,
				label: "Managed Recharge - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_mr_method",
				numeric: true,
				label: "Managed Recharge Method",
				allowSort: true,
			},
			{
				id: "gw_mr_method_notes",
				numeric: true,
				label: "Managed Recharge Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_mr_total",
				numeric: true,
				label: "Managed Recharge - Total (AF)",
				allowSort: true,
			},
			{
				id: "gw_mr_ua",
				numeric: true,
				label: "Managed Recharge - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_other_acc",
				numeric: true,
				label: "Other Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_other_calcm",
				numeric: true,
				label: "Other Calculation Method (Direct or Estimated)",
				allowSort: true,
			},
			{
				id: "gw_other_desc",
				numeric: true,
				label: "Other Description / Name",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_other_la",
				numeric: true,
				label: "Other - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_other_method",
				numeric: true,
				label: "Other Method",
				allowSort: true,
			},
			{
				id: "gw_other_method_notes",
				numeric: true,
				label: "Other Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_other_total",
				numeric: true,
				label: "Other - Total (AF)",
				allowSort: true,
			},
			{
				id: "gw_other_ua",
				numeric: true,
				label: "Other - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_veg_acc",
				numeric: true,
				label: "Native Vegetation Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_veg_calcm",
				numeric: true,
				label: "Native Vegetation Calculation Method (Direct or Estimated)",
				allowSort: true,
			},
			{
				id: "gw_veg_la",
				numeric: true,
				label: "Native Vegetation - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_veg_method",
				numeric: true,
				label: "Native Vegetation Method",
				allowSort: true,
			},
			{
				id: "gw_veg_method_notes",
				numeric: true,
				label: "Native Vegetation Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_veg_total",
				numeric: true,
				label: "Native Vegetation - Total (AF)",
				allowSort: true,
			},
			{
				id: "gw_veg_ua",
				numeric: true,
				label: "Native Vegetation - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_wet_acc",
				numeric: true,
				label: "Managed Wetlands Calculation Accuracy",
				allowSort: true,
			},
			{
				id: "gw_wet_calcm",
				numeric: true,
				label: "Managed Wetlands Calculation Method (Direct or Estimated)",
				allowSort: true,
			},
			{
				id: "gw_wet_la",
				numeric: true,
				label: "Managed Wetlands - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_wet_method",
				numeric: true,
				label: "Managed Wetlands Method",
				allowSort: true,
			},
			{
				id: "gw_wet_method_notes",
				numeric: true,
				label: "Managed Wetlands Method Description",
				allowSort: true,
				width: 800,
			},
			{
				id: "gw_wet_total",
				numeric: true,
				label: "Managed Wetlands - Total (AF)",
				allowSort: true,
			},
			{
				id: "gw_wet_ua",
				numeric: true,
				label: "Managed Wetlands - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_total_la",
				numeric: true,
				label: "Total Groundwater Extractions - Lower Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_total_ua",
				numeric: true,
				label: "Total Groundwater Extractions - Upper Aquifer (AF)",
				allowSort: true,
			},
			{
				id: "gw_total_total",
				numeric: true,
				label: "Total Groundwater Extractions - Total (AF)",
				allowSort: true,
			},
			{
				id: "submitted",
				numeric: true,
				label: "Submitted",
				allowSort: true,
			},
		];
		const groundwaterrechargeCols = [
			{
				id: "gsp_id",
				numeric: false,
				label: "GSP Name",
				allowSort: true,
			},
			{
				id: "gsa_name_id",
				numeric: false,
				label: "GSA Name",
				allowSort: true,
			},
			{
				id: "month",
				numeric: false,
				label: "Month",
				allowSort: true,
			},
			{
				id: "water_year",
				numeric: false,
				label: "Year",
				allowSort: true,
			},
			{
				id: "dom_total",
				numeric: false,
				label: "Urban",
				allowSort: true,
			},
			{
				id: "agg_total",
				numeric: false,
				label: "Agricultural",
				allowSort: true,
			},
			{
				id: "man_total",
				numeric: true,
				label: "Managed Recharge",
				allowSort: true,
			},
			{
				id: "nat_total",
				numeric: false,
				label: "Native Vegetation",
				allowSort: true,
			},
			{
				id: "sur_total",
				numeric: true,
				label: "Surface Water System",
				allowSort: true,
			},
			{
				id: "other_total",
				numeric: true,
				label: "Other",
				allowSort: true,
			},
		];
		//return which columns to use based on id of URL
		if (id === "wellsitemeasurements") {
			return groundwaterLevelDataCols;
		} else if (id === "waterqualitymeasurements") {
			return waterQualityDataCols;
		} else if (id === "surfacewatermeasurements") {
			return surfaceWaterCols;
		} else if (id === "subsidencemeasurements") {
			return subsidenceCols;
		} else if (id === "streammonitoring") {
			return streamCols;
		} else if (id === "groundwaterextraction") {
			return gweCols;
		} else if (id === "groundwaterstorage") {
			return groundwaterstorageDataCols;
		}else if (id === "surfacewatersupply") {
			return surfacewaterDataCols;
		}else if (id === "totalwateruse") {
			return totalwaterCols;
		}else if (id === "groundwaterrecharge") {
			return groundwaterrechargeCols;
		}else if (id === "evapotranspiration") {
			return evapotranspirationCols;
		} else {
			return [];
		}
	}

	handleRequestSort = (event, property) => {
		const { existingData } = this.state;

		const orderBy = property;
		let order = "desc";

		const colType = this.props.match.params["id"];

		const columnData = this.columnData(colType);

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		existingData.sort(function (a, b) {
			var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
			if (numeric) {
				if (order === "desc")
					return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
				else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
			} else {
				if (order === "desc")
					return (b[orderBy] || "").toUpperCase() <
						(a[orderBy] || "").toUpperCase()
						? -1
						: 1;
				else
					return (a[orderBy] || "").toUpperCase() <
						(b[orderBy] || "").toUpperCase()
						? -1
						: 1;
			}
		});

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	handleAPIChange(data) {
		if (data.user_role === "GSP Representative") {
			this.setState({ authorized: true });
		}

		this.setState({
			existingData: data.measurements,
			submitted: data.submitted,
			gsp: data.gsp,
			water_year: data.water_year,
			showDelete: false,
		});
	}

	render() {
		const { classes } = this.props;
		const {
			page,
			rowsPerPage,
			order,
			orderBy,
			authorized,
			showDelete,
			existingData,
			gsp,
			water_year,
			submitted,
		} = this.state;

		let histName = "";
		const histId = this.props.match.params["id"];
		const gspId = parseInt(this.props.match.params["gsp"]);
		//breadcrumb names to display
		if (histId === "wellsitemeasurements") {
			histName = "Groundwater Level";
		} else if (histId === "waterqualitymeasurements") {
			histName = "Water Quality";
		} else if (histId === "surfacewatermeasurements") {
			histName = "Surfacewater - Groundwater Interaction";
		} else if (histId === "subsidencemeasurements") {
			histName = "Subsidence Monitoring";
		} else if (histId === "groundwaterextraction") {
			histName = "Groundwater Extraction";
		}else if (histId === "groundwaterstorage") {
			histName = "Groundwater Storage";
		}else if (histId === "surfacewatersupply") {
			histName = "Surface Water Supply";
		}else if (histId === "totalwateruse") {
			histName = "Total Water Use";
		}else if (histId === "groundwaterrecharge") {
			histName = "Ground Water Recharge";
		}else if (histId === "evapotranspiration") {
			histName = "Evapotranspiration";
		}

		let level1 = "Associated GSP's";
		let level1link = "/import";
		let level2 = histName;
		let level2link = "/importHistory/" + histId + "/" + gspId;
		let level3 = "";
		let level3link = "";

		if (this.props.location.pathname.indexOf("gspReportingHistory") > -1) {
			level1 = "GSP Reporting Status";
			level1link = "/gspreportingstatus";
			level2 = histName;
			level2link = "/gspReportingHistory/" + histId + "/" + gspId;
		} else if (
			this.props.location.pathname.indexOf("importMonitoringHistory") > -1
		) {
			level1 = "Associated GSP's";
			level1link = "/import";
			level2 = "Import Monitoring Data";
			level2link = "/import/" + gspId;
			level3 = histName;
			level3link = "/importMonitoringHistory/" + histId + "/" + gspId;
		}

		if (existingData === undefined) {
			return (
				<AppContainer authenticated>
					<MeasurementsHOC
						apitarget={histId}
						gsp_id={gspId}
						handler={this.handleAPIChange.bind(this)}
					/>

					<NotReady message="Loading..." />
				</AppContainer>
			);
		} else {
			return (
				<AppContainer authenticated>
					<Grid container spacing={24}>
						<Grid item xs={12} className={classes.breadCrumb}>
							{level3 === "" ? (
								<BreadcrumbNav
									level1={level1}
									level1link={level1link}
									level2={level2}
									level2link={level2link}
								/>
							) : (
								<BreadcrumbNav
									level1={level1}
									level1link={level1link}
									level2={level2}
									level2link={level2link}
									level3={level3}
									level3link={level3link}
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">
								{gsp && gsp.name ? gsp.name + ": " : ""}
								{histName} ({water_year && water_year.water_year})
							</Typography>
						</Grid>
						{/* {authorized && !submitted && existingData.length > 0 && (
							<Grid item xs={12}>
								<Button
									className={classes.buttons}
									style={{
										backgroundColor:
											authorized && !submitted ? "primary" : "#7ed2f5",
										color: "white",
									}}
									disabled={!authorized || submitted}
									onClick={() => this.setState({ showDelete: true })}>
									Delete Existing Records
								</Button>
							</Grid>
						)} */}

						{histId === "wellsitemeasurements" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.dms_site_id}</TableCell>
														<TableCell>{item.measurement_date}</TableCell>
														<TableCell>{item.measurement_time}</TableCell>
														<TableCell>{item.measurement_depth}</TableCell>
														<TableCell>{item.surf_elev}</TableCell>
														<TableCell>{item.vert_datum}</TableCell>
														<TableCell>{item.meas_method}</TableCell>
														<TableCell>{item.meas_accuracy}</TableCell>
														<TableCell>{item.quest_meas}</TableCell>
														<TableCell>{item.measured_by}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "waterqualitymeasurements" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.dms_site_id}</TableCell>
														<TableCell>{item.meas_date}</TableCell>
														<TableCell>{item.meas_time}</TableCell>
														<TableCell>{item.lab_name}</TableCell>
														<TableCell>{item.analytical_method}</TableCell>
														<TableCell>{item.characteristic}</TableCell>
														<TableCell>{item.result_value}</TableCell>
														<TableCell>{item.result_units}</TableCell>
														<TableCell>{item.result_qualifier}</TableCell>
														<TableCell>{item.sampled_by}</TableCell>
														<TableCell>{item.meas_comments}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "surfacewatermeasurements" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.dms_site_id}</TableCell>
														<TableCell>{item.meas_date}</TableCell>
														<TableCell>{item.meas_time}</TableCell>
														<TableCell>{item.ua_compaction}</TableCell>
														<TableCell>{item.meas_elevation}</TableCell>
														<TableCell>{item.total_subsidence}</TableCell>
														<TableCell>{item.meas_accuracy}</TableCell>
														<TableCell>{item.meas_comments}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "subsidencemeasurements" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.dms_site_id}</TableCell>
														<TableCell>{item.meas_date}</TableCell>
														<TableCell>{item.meas_time}</TableCell>
														<TableCell>{item.ua_compaction}</TableCell>
														<TableCell>{item.meas_elevation}</TableCell>
														<TableCell>{item.total_subsidence}</TableCell>
														<TableCell>{item.meas_accuracy}</TableCell>
														<TableCell>{item.meas_comments}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "streammonitoring" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.dms_site_id}</TableCell>
														<TableCell>{item.meas_date}</TableCell>
														<TableCell>{item.meas_time}</TableCell>
														<TableCell>{item.stream_stage}</TableCell>
														<TableCell>{item.meas_comments}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "groundwaterextraction" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.gsa_name}</TableCell>
														<TableCell>{item.gsp_name}</TableCell>
														<TableCell>{item.measurement_month}</TableCell>
														<TableCell>{item.gw_agg_acc}</TableCell>
														<TableCell>{item.gw_agg_calcm}</TableCell>
														<TableCell>{item.gw_agg_la}</TableCell>
														<TableCell>{item.gw_agg_method}</TableCell>
														<TableCell>{item.gw_agg_method_notes}</TableCell>
														<TableCell>{item.gw_agg_total}</TableCell>
														<TableCell>{item.gw_agg_ua}</TableCell>
														<TableCell>{item.gw_dom_acc}</TableCell>
														<TableCell>{item.gw_dom_calcm}</TableCell>
														<TableCell>{item.gw_dom_la}</TableCell>
														<TableCell>{item.gw_dom_method}</TableCell>
														<TableCell>{item.gw_dom_method_notes}</TableCell>
														<TableCell>{item.gw_dom_total}</TableCell>
														<TableCell>{item.gw_dom_ua}</TableCell>
														<TableCell>{item.gw_ind_acc}</TableCell>
														<TableCell>{item.gw_ind_calcm}</TableCell>
														<TableCell>{item.gw_ind_la}</TableCell>
														<TableCell>{item.gw_ind_method}</TableCell>
														<TableCell>{item.gw_ind_method_notes}</TableCell>
														<TableCell>{item.gw_ind_total}</TableCell>
														<TableCell>{item.gw_ind_ua}</TableCell>
														<TableCell>{item.gw_mr_acc}</TableCell>
														<TableCell>{item.gw_mr_calcm}</TableCell>
														<TableCell>{item.gw_mr_la}</TableCell>
														<TableCell>{item.gw_mr_method}</TableCell>
														<TableCell>{item.gw_mr_method_notes}</TableCell>
														<TableCell>{item.gw_mr_total}</TableCell>
														<TableCell>{item.gw_mr_ua}</TableCell>
														<TableCell>{item.gw_other_acc}</TableCell>
														<TableCell>{item.gw_other_calcm}</TableCell>
														<TableCell>{item.gw_other_desc}</TableCell>
														<TableCell>{item.gw_other_la}</TableCell>
														<TableCell>{item.gw_other_method}</TableCell>
														<TableCell>{item.gw_other_method_notes}</TableCell>
														<TableCell>{item.gw_other_total}</TableCell>
														<TableCell>{item.gw_other_ua}</TableCell>
														<TableCell>{item.gw_veg_acc}</TableCell>
														<TableCell>{item.gw_veg_calcm}</TableCell>
														<TableCell>{item.gw_veg_la}</TableCell>
														<TableCell>{item.gw_veg_method}</TableCell>
														<TableCell>{item.gw_veg_method_notes}</TableCell>
														<TableCell>{item.gw_veg_total}</TableCell>
														<TableCell>{item.gw_veg_ua}</TableCell>
														<TableCell>{item.gw_wet_acc}</TableCell>
														<TableCell>{item.gw_wet_calcm}</TableCell>
														<TableCell>{item.gw_wet_la}</TableCell>
														<TableCell>{item.gw_wet_method}</TableCell>
														<TableCell>{item.gw_wet_method_notes}</TableCell>
														<TableCell>{item.gw_wet_total}</TableCell>
														<TableCell>{item.gw_wet_ua}</TableCell>
														<TableCell>{item.gw_total_la}</TableCell>
														<TableCell>{item.gw_total_ua}</TableCell>
														<TableCell>{item.gw_total_total}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
									{histId === "groundwaterrecharge" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.gsp_name_label}</TableCell>
														<TableCell>{item.gsa_name_label}</TableCell>
														<TableCell>{item.month}</TableCell>
														<TableCell>{item.w_year.w_year}</TableCell>
														<TableCell>{item.dom_total}</TableCell>
														<TableCell>{item.agg_total}</TableCell>
														<TableCell>{item.man_total}</TableCell>
														<TableCell>{item.nat_total}</TableCell>
														<TableCell>{item.sur_total}</TableCell>
														<TableCell>{item.other_total}</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}		
						{histId === "surfacewatermeasurements" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.dms_site_id}</TableCell>
														<TableCell>{item.meas_date}</TableCell>
														<TableCell>{item.meas_time}</TableCell>
														<TableCell>{item.ua_compaction}</TableCell>
														<TableCell>{item.meas_elevation}</TableCell>
														<TableCell>{item.total_subsidence}</TableCell>
														<TableCell>{item.meas_accuracy}</TableCell>
														<TableCell>{item.meas_comments}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "groundwaterstorage" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.gsp_label}</TableCell>
														<TableCell>{item.gw_stor_ua}</TableCell>
														<TableCell>{item.gw_stor_la}</TableCell>
														<TableCell>{item.gw_stor_total}</TableCell>
														<TableCell>{item.gw_stor_acc_notes}</TableCell>
														<TableCell>{item.w_year.w_year}</TableCell>
														<TableCell>
															{item.submitted ? "True" : "False"}
														</TableCell>
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}		
						{histId === "surfacewatersupply" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.gsp_label}</TableCell>
														<TableCell>{item.gsa_name_label}</TableCell>
														<TableCell>{item.month}</TableCell>
														<TableCell>{item.w_year.water_year}</TableCell>
														<TableCell>{item.sw_import_cv}</TableCell>
														<TableCell>{item.sw_import_sw}</TableCell>
														<TableCell>{item.sw_localsup}</TableCell>
														<TableCell>{item.sw_recycled}</TableCell>
														<TableCell>{item.sw_flood_water}</TableCell>
														<TableCell>{item.sw_other}</TableCell>
														<TableCell>{item.sw_other_desc}</TableCell>
														<TableCell>{item.sw_calcm}</TableCell>
													 
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "totalwateruse" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>{item.gsp_label}</TableCell>
														<TableCell>{item.gsa_name_label}</TableCell>
														<TableCell>{item.month}</TableCell>
														<TableCell>{item.w_year.water_year}</TableCell>
														<TableCell>{item.total}</TableCell>
														<TableCell>{item.t_dom_total}</TableCell>
														<TableCell>{item.t_ind_total}</TableCell>
														<TableCell>{item.t_agg_total}</TableCell>
														<TableCell>{item.t_wet_total}</TableCell>
														<TableCell>{item.t_dist_total}</TableCell>
														<TableCell>{item.t_veg_total}</TableCell>
														<TableCell>{item.t_other_total}</TableCell>
													 
													</TableRow>
												))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}
						{histId === "evapotranspiration" && (
							<Grid item xs={12}>
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={this.columnData(histId)}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{existingData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow>
													<TableCell>{item.gsp_name_label}</TableCell>
													<TableCell>{item.gsa_name_label}</TableCell>
													<TableCell>{item.month}</TableCell>
													<TableCell>{item.w_year.w_year}</TableCell>
													<TableCell>
														<NumberFormat
															value={item.ur_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={2}
														/> 
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.agg_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={2}
														/> 
														</TableCell>
													<TableCell>
														<NumberFormat
															value={item.man_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={2}
														/> 
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.nat_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={2}
														/> 
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.sur_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={2}
														/> 
													</TableCell>
													<TableCell>
														<NumberFormat
															value={item.other_total}
															displayType={"text"}
															thousandSeparator={true}
															decimalScale={2}
														/> 
													</TableCell> 
													</TableRow>
											))}
											{existingData.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={7}
														className={classes.centerAlign}>
														No Historical Data Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{existingData.length > 10 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={7}
														count={existingData.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							</Grid>
						)}

						<WarningDeleteDialog
							cancelAction={() => this.setState({ showDelete: false })}
							open={showDelete}
							title="Delete Data"
							confirmText="Delete Data"
							text={"Are you sure you wish to delete this data?"}
							datatarget={histId}
							gsp={gspId}
							handlerAction={this.handleAPIChange.bind(this)}
						/>
					</Grid>
				</AppContainer>
			);
		}
	}
}
ImportMonitoringHistory = connect((state, ownProps) => ({
	authState: state.auth,
}))(ImportMonitoringHistory);

export default withStyles(styles)(ImportMonitoringHistory);
