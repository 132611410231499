import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import BreadcrumbNav from "../common/BreadCrumb";
import InputAdornment from "@material-ui/core/InputAdornment";
import { GroundwaterStorage } from "./models";
import { createSelector } from "../common/orm";
import NumberFormat from "react-number-format";
/* Calc Method Start */
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
import HelpLabel from "../common/HelpLabel";
const getOptions = GroundwaterStorage.getOptions();
// const CalcMethod = (props) => {
// 	// props: fieldName, currentValue
// 	const [value, setValue] = React.useState(props.currentValue); // eslint-disable-line no-unused-vars
// 	/* TODO: Figure out how to set the field's value so that the value of the field and make sure the form can read it */
// 	return (
// 		<FormControl component="fieldset">
// 			<RadioGroup
// 				style={{ flexWrap: "nowrap", marginLeft: "10px" }}
// 				aria-label="direct"
// 				name={props.fieldName}
// 				row>
// 				<FormControlLabel
// 					value="Direct"
// 					disabled={props.disabled}
// 					onChange={props.onChange}
// 					style={{ alignItems: "center" }}
// 					control={
// 						<Radio
// 							checked={props.value === "Direct"}
// 							style={{
// 								padding: "6px 0px 3px 7px",
// 								color: props.value === "Direct" ? "#13aff1" : "",
// 							}}
// 						/>
// 					}
// 					label="Direct"
// 					labelPlacement="end"
// 				/>
// 				<FormControlLabel
// 					value="Estimated"
// 					disabled={props.disabled}
// 					onChange={props.onChange}
// 					style={{ alignItems: "center" }}
// 					control={
// 						<Radio
// 							checked={props.value === "Estimated"}
// 							style={{
// 								padding: "6px 0 3px 7px",
// 								color: props.value === "Estimated" ? "#13aff1" : "",
// 							}}
// 						/>
// 					}
// 					label="Estimated"
// 					labelPlacement="end"
// 				/>
// 			</RadioGroup>
// 		</FormControl>
// 	);
// };
/* Calc Method End */
const getUserData = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let user = session.User.filter((u) => u.id === options.id).toRefArray()[0];
		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];
		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};
		return user && association && gsp
			? {
					...user,
					association: association ? association : {},
					gsp: gsp ? gsp : {},
			  }
			: {};
	}
);
// function formatDate(date) {
//   var monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   var day = date.getUTCDate();
//   var monthIndex = date.getUTCMonth();
//   var year = date.getUTCFullYear();
//   return monthNames[monthIndex] + " " + day + ", " + year;
// }

const getWaterYear = createSelector((session, id) => {
	let wy = session.WaterYear.filter(
		(y) => y.is_active === true
	).toRefArray()[0];
	return {
		...wy,
	};
});

const currentGroundwaterStorage = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
		gs_id: parseInt(ownProps.match.params["id"]),
	}),
	(session, options) => {
		let gsp = session.GSP.filter((u) => u.id === options.gsp).toRefArray()[0];

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		if (options.gs_id) {
			let gs = session.GroundwaterStorage.filter(
				(y) => y.gspp_id === gsp.id && y.id === options.gs_id
			).toRefArray()[0];

			let wy =
				gs && gs.water_year_id
					? session.WaterYear.filter(
							(y) => y.id === gs.water_year_id
					  ).toRefArray()[0]
					: {};

			gs = { ...gs, wy: wy };

			return gsp && gs && wy ? gs : {};
		} else {
			return gsp && thisWaterYear
				? session.GroundwaterStorage.filter(
						(y) => y.water_year_id === thisWaterYear.id && y.gspp_id === gsp.id
				  ).toRefArray()[0]
				: {};
		}
	}
);
const historicGroundwaterStorage = createSelector(
	(state, ownProps) => ({
		id: state.auth && state.auth.user ? state.auth.user.id : {},
		gsp: parseInt(ownProps.match.params["gsp"]),
	}),
	(session, options) => {
		let association = session.GSPAssociation.filter(
			(u) => u.user === options.id && u.gsp === options.gsp
		).toRefArray()[0];

		let gsp = association
			? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
			: {};

		let thisWaterYear = session.WaterYear.filter(
			(y) => y.is_active === true
		).toRefArray()[0];

		return thisWaterYear && gsp
			? session.GroundwaterStorage.filter(
					(y) => y.water_year_id !== thisWaterYear.id && y.gspp_id === gsp.id
			  ).toRefArray()
			: {};
	}
);
const styles = (theme) => ({
	container: {
		paddingLeft: "10px",
	},
	hrclass: {
		borderColor: "primary",
	},
	tc: {
		padding: "4px 4px 4px 4px",
		maxWidth: "60px",
	},
	tcNumber: {
		padding: "4px 4px 4px 4px",
		maxWidth: "40px",
	},
	tcAccuracy: {
		padding: "4px 4px 4px 15px",
		maxWidth: "20px",
	},
	mdHeader: {
		fontSize: "18px",
		textDecoration: "underline",
		fontWeight: "normal",
	},
	lgHeader: {
		fontSize: "25px",
	},
	helpIcon: {
		marginLeft: "4px",
	},
});
class ReportingGWStorage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			csrftoken: localStorage.auth_token,
			loader: null,
			agency: "Not Available",
			waterYearStart: "Not Available",
			waterYearEnd: "Not Available",
			districtName: "Not Available",
			submitted: false,
			saved: false,
			authorized: false,
			gw_stor_ua: 0,
			gw_stor_la: 0,
			gw_stor_total: 0,
			gw_stor_acc: "",
			water_year_id: null,
			gsp_id: null,
			curdata: false,
			helpOpen: false,
			callOptions: false,
		};
	}
	componentDidMount() {
		const { authState, history } = this.props;
		if (authState && authState.user && authState.user.role === "Agency") {
			history.push("/dashboard");
		}
	}
	populateStateFromHistory = () => {
		const { watercur, user } = this.props;
		if (watercur && watercur.id && !this.state.curdata) {
			let isAuth = false;
			if (
				user &&
				user.association &&
				user.association.authorized_reporter &&
				user &&
				user.role === "GSP Representative"
			) {
				isAuth = true;
			}
			if (watercur.submitted || isAuth) {
				this.setState({
					...this.state,
					...watercur,
					curdata: true,
					authorized: isAuth,
				});
			}
		}
	};
	setAuthorized = () => {
		const { user } = this.props;
		const { authorized } = this.state;
		if (user && user.association && user.association.authorized_reporter) {
			if (!authorized && user && user.role === "GSP Representative") {
				this.setState({ authorized: true });
			}
		}
	};
	pf = (val) => {
		if (val === "" || val === " " || val === null) {
			return parseFloat(0);
		} else if (typeof val === "string") {
			return parseFloat(val.replace(/,/g, ""));
		} else {
			return parseFloat(val);
		}
	};
	NumberFormatCustom = (props) => {
		const { inputRef, onChange, ...other } = props;
		return (
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				onValueChange={(values) => {
					onChange({
						target: {
							value: values.value,
							name: other.name,
						},
					});
				}}
				thousandSeparator
			/>
		);
	};
	handleChange = (evt) => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});
	};
	handleChangeNumeric = (evt) => {
		const re = /^-?[-0-9\b]+$/;
		if (evt.target.value === "" || re.test(evt.target.value)) {
			var val = parseInt(evt.target.value);
			this.setState({
				[evt.target.name]: val,
			});
		}
	};
	handleSave = (evt) => {
		evt.preventDefault();
		const { ormGroundwaterStorageCreate, ormGroundwaterStorageUpdate } =
			this.props;
		const { user, wateryr, history } = this.props;
		const { curdata } = this.state;
		this.setState({
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		});
		const stObj = {
			...this.state,
			submitted: false,
			saved: true,
			water_year_id: wateryr.id,
			gsp_id: user.gsp.id,
		};
		if (!curdata) {
			ormGroundwaterStorageCreate(stObj);
		} else {
			ormGroundwaterStorageUpdate(stObj);
		}
		history.push("/reporting");
	};
	submitForm = (evt) => {
		evt.preventDefault();
		const { ormGroundwaterStorageUpdate, history } = this.props;
		this.setState({
			submitted: true,
		});
		const stObj = {
			...this.state,
			submitted: true,
		};
		ormGroundwaterStorageUpdate(stObj);
		history.push("/reporting");
	};
	render() {
		this.setAuthorized();
		this.populateStateFromHistory();
		const { submitted, saved, authorized, helpOpen, siteChoices } = this.state;
		const { classes, user, wateryr, watercur, waterhist, optionsReq } =
			this.props;
		let submitActive =
			(saved || this.state.curdata) && !submitted && authorized;
		//OPTIONS comes back as a promise
		if (optionsReq && !siteChoices) {
			optionsReq.then((data) => {
				if (!this.state.siteChoices) {
					this.setState({
						siteChoices: data,
					});
				}
			});
		}
		return (
			<AppContainer authenticated>
				<div className={classes.container}>
					<form>
						{watercur && watercur.wy ? (
							<>
								<BreadcrumbNav
									level1="Submitted Groundwater Change in Storage Data"
									level1link="/groundwaterstorage"
									level2="Groundwater Change in Storage Details"
									level2link={
										"/reporting/gwstorage/" +
										this.props.match.params["gsp"] +
										"/" +
										this.props.match.params["id"]
									}
								/>
								<br />
							</>
						) : (
							<>
								<BreadcrumbNav
									level1="Associated GSP's"
									level1link="/reporting"
									level2="Report Groundwater Change in Storage Data"
									level2link={
										"/reporting/gwstorage/" + this.props.match.params["gsp"]
									}
								/>
								<br />
							</>
						)}
						<Grid container spacing={24}>
							<Grid item xs={12}>
								{watercur &&    
									<>
										<span className={classes.lgHeader}>
											Water Year: {watercur.water_year}
										</span>
									</>
							 }
							</Grid>
							{watercur && watercur.wy ? (
								<></>
							) : (
								<>
									<Grid item xs={12}>
										<b>Role:</b> Your user account is{" "}
										{authorized ? "currently" : <b>NOT</b>} authorized to report
										data for:{" "}
										<b>
											{user && user.gsp
												? user.gsp.name
												: "(User not associated with GSP)"}
										</b>
										.
									</Grid>
									<Grid item xs={12}>
										<b>Status:</b> {submitted ? "Data" : "No data"} has been
										imported into the DMS for the current water year.{" "}
										{waterhist && waterhist.length && user && user.gsp ? (
											<a
												href={
													"/#/reportinghistory/groundwaterstorage/" +
													this.props.match.params["gsp"]
												}>
												View Historic Data
											</a>
										) : (
											""
										)}
									</Grid>
								</>
							)}
							<Grid item xs={12}>
								<hr className={classes.hrclass} />
								<h2 className={classes.mdHeader}>
									Groundwater Change in Storage Data
									<HelpLabel
										open={helpOpen}
										question="Groundwater Change in Storage"
										showHelp={true}
										inputLabel={true}
										helpText={
											<>
												Annual (Water Year) change in the volume of groundwater
												in storage between seasonal high groundwater conditions
												in each principal aquifer. Calculated from water surface
												elevation contour maps and/or hydrographs with storage
												coefficients.
											</>
										}
									/>
								</h2>{" "}
								<Table>
									<TableBody>
										<TableRow>
											<TableCell
												xs={3}
												className={classes.tcNumber}></TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												Combined Upper Aquifier and Undifferentiated Unconfined Zone (AF)
											</TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												Lower Aquifier
											</TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												Total
											</TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												Explain the method used to calculate change in storage
											</TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												Explain Calculation Accuracy
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell xs={3} className={classes.tcNumber}>
												Change in Storage
											</TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												<TextField
													disabled={submitted || !authorized}
													value={this.state.gw_stor_ua}
													name="gw_stor_ua"
													fullWidth
													onChange={this.handleChangeNumeric}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">AF</InputAdornment>
														),
														inputComponent: this.NumberFormatCustom,
													}}
												/>
											</TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												<TextField
													disabled={submitted || !authorized}
													value={this.state.gw_stor_la}
													name="gw_stor_la"
													fullWidth
													onChange={this.handleChangeNumeric}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">AF</InputAdornment>
														),
														inputComponent: this.NumberFormatCustom,
													}}
												/>
											</TableCell>
											<TableCell xs={3} className={classes.tcNumber}>
												<TextField
													disabled={submitted || !authorized}
													value={this.state.gw_stor_total}
													name="gw_stor_total"
													fullWidth
													onChange={this.handleChangeNumeric}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">AF</InputAdornment>
														),
														inputComponent: this.NumberFormatCustom,
													}}
												/>
											</TableCell>
											<TableCell xs={2} className={classes.tc}>
												<TextField
													name="gw_stor_method_notes"
													disabled={submitted || !authorized}
													value={this.state.gw_stor_method_notes}
													onChange={this.handleChange}
													multiline={true}
													rows={1}
													rowsMax="4"
													inputProps={{
														maxLength: 4000,
													}}
												/>
											</TableCell>
											<TableCell xs={2} className={classes.tc}>
												<TextField
													name="gw_stor_acc_notes"
													disabled={submitted || !authorized}
													value={this.state.gw_stor_acc_notes}
													onChange={this.handleChange}
													multiline={true}
													rows={1}
													rowsMax="4"
													inputProps={{
														maxLength: 4000,
													}}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
							{watercur && watercur.wy ? (
								<></>
							) : (
								<>
									<Grid item xs={3}>
										<Button
											fullWidth
											variant="contained"
											type="button"
											label="Save"
											disabled={submitted || !authorized}
											onClick={this.handleSave}
											style={{
												backgroundColor:
													!submitted && authorized ? "#13aff1" : "#7ed2f5",
												color: "white",
											}}>
											Save
										</Button>
									</Grid>
									<Grid item xs={9}>
										<Button
											type="button"
											onClick={this.submitForm}
											disabled={!submitActive}
											style={{
												backgroundColor: submitActive ? "#13aff1" : "#7ed2f5",
												color: "white",
											}}>
											Submit Data as Final for Current Reporting Water Year
										</Button>
									</Grid>
								</>
							)}
						</Grid>
					</form>
				</div>
			</AppContainer>
		);
	}
}
ReportingGWStorage = connect(
	(state, ownProps) => ({
		optionsReq: getOptions(state, ownProps),
		wateryr: getWaterYear(state, ownProps),
		user: getUserData(state, ownProps),
		watercur: currentGroundwaterStorage(state, ownProps),
		waterhist: historicGroundwaterStorage(state, ownProps),
		authState: state.auth,
	}),
	{
		...GroundwaterStorage.actions,
		...authActions,
		...navActions,
	}
)(ReportingGWStorage);
export default withStyles(styles)(withRouter(ReportingGWStorage));
