import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LocalDrink from "@material-ui/icons/LocalDrink";
import Storage from "@material-ui/icons/Storage";
import ListAlt from "@material-ui/icons/ListAlt";
import OpacityIcon from "@material-ui/icons/Opacity";
import ReactHtmlParser from "react-html-parser";
import requestConfig from "../../api/fetchConfig";
import NotReady from "../common/NotReady";

import { withStyles } from "@material-ui/core";
import Icon from "@mdi/react";
import {
	mdiMap,
	mdiSettings,
	mdiWaves,
	mdiSwapVertical,
	mdiCheckDecagram,
	mdiChevronLeft,
	mdiChevronRight,
	mdiViewDashboard,
	mdiAlertOutline,
	mdiViewAgenda,
	mdiFileChart,
	mdiHydroPower,
	mdiChartDonutVariant,
	mdiWaterPump,
} from "@mdi/js"; //mdiWaterPump,
import * as navActions from "./actions";

const DEFAULT_WELL = {
	well_id: "Current Well",
	id: "new",
};

const styles = (theme) => ({
	drawerPaper: {
		height: "calc(100% - 50px)", //calc(100% - 100px)", // 50 = height of Header
		marginTop: 50, // 50 = height of Header
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing.unit * 7,
		whiteSpace: "nowrap",
		backgroundColor: "#242424",
	},
	drawerOpen: {
		[theme.breakpoints.up("lg")]: {
			width: 325,
		},
		[theme.breakpoints.down("md")]: {
			width: 275,
		},
		"& $listItemSecond": {
			marginLeft: theme.spacing.unit * 3,
			paddingRight: theme.spacing.unit * 4,
		},
		"& $listItemThird": {
			marginLeft: theme.spacing.unit * 6,
			paddingRight: theme.spacing.unit * 7,
		},
		"& $overflow": {
			overflowY: "auto",
		},
	},
	selectedPage: {
		border: "1px solid " + theme.palette.primary.main,
	},
	pageText: {
		color: "#aaaaaa !important",
	},
	topPageText: {
		color: "#ffffff",
	},
	listItem: {
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
		"&:hover": {
			backgroundColor: "#4e4e4e !important",
		},
	},
	listItemDashboard: {
		borderBottom: "1px solid #00adee",
	},
	listItemField: {
		borderBottom: "1px solid #34a853",
	},
	listItemCrop: {
		borderBottom: "1px solid #7ac143",
	},
	listItemProject: {
		borderBottom: "1px solid #ff7d32",
	},
	listItemSupport: {
		borderBottom: "1px solid #aaaaaa",
	},
	listItemDashboardSelected: {
		borderBottom: "3px solid #00adee",
	},
	listItemCropSelected: {
		borderBottom: "3px solid #7ac143",
	},
	listItemProjectSelected: {
		borderBottom: "3px solid #ff7d32",
	},
	listItemSupportSelected: {
		borderBottom: "3px solid #aaaaaa",
	},
	listItemSecond: {
		transition: theme.transitions.create("margin-left", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	listItemThird: {
		transition: theme.transitions.create("margin-left", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	overflow: {
		overflowY: "hidden",
	},
	noFlex: {
		padding: 0,
	},
	iconSize: {
		flexShrink: 0,
		width: "1em",
		height: "1em",
		fontSize: "24px",
	},
	iconSizeSmaller: {
		flexShrink: 0,
		width: ".8em",
		height: ".8em",
		fontSize: "24px",
	},
	farmHalf: {
		width: "50%",
		display: "inline-block",
		height: 48,
	},
	farmHalfText: {
		display: "inline-block",
		verticalAlign: "top",
		maxWidth: "110%",
	},
	farmHalfLarge: {
		[theme.breakpoints.up("lg")]: {
			width: "74%",
		},
		[theme.breakpoints.down("md")]: {
			width: "68%",
		},
	},
	supportHalfLarge: {
		[theme.breakpoints.up("lg")]: {
			width: "82%",
		},
		[theme.breakpoints.down("md")]: {
			width: "76%",
		},
	},
	textOverflow: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	textOverflowField: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "110%",
	},
	hidden: {
		display: "none",
	},
});

class Navigation extends React.Component {
	continueDashboard = () => {
		this.props.navSelectPage("dashboard");
		this.props.history.push("/dashboard");
	};
	navigationClick = () => {
		this.props.navToggleNavigation();
	};
	wellLibraryClick = () => {
		this.props.navToggleWellLibrary();
	};
	supportClick = () => {
		this.props.navToggleSupport();
	};
	projectClick = () => {
		this.props.navToggleProject();
	};
	renderThumb({ style, ...props }) {
		const thumbStyle = {
			backgroundColor: "#f15d22",
		};
		return <div style={{ ...style, ...thumbStyle }} />;
	}
	constructor(props) {
		super(props);
		this.state = { ...DEFAULT_WELL };
	}

	render() {
		var { classes, navState, navSelectPage, authState } = this.props;
		const { appConfig, isFetching } = this.state;

		let token = authState && authState.user ? authState.user.auth_token : "";

		if (!navState.navigationOpen && navState.navigationOpen !== false) {
			this.props.navToggleNavigation();
		}

		//get the config data
		if (token !== "" && appConfig === undefined && !isFetching) {
			this.setState({ ...this.state, isFetching: true });
			requestConfig(token).then((data) => {
				this.setState({ appConfig: data, isFetching: false });
			});
		}

		var { user } = authState;

		if (appConfig !== undefined) {
			return (
				<Drawer
					id="drawContainer"
					variant="permanent"
					open={true}
					classes={{
						paper: classNames(
							classes.drawerPaper,
							navState.navigationOpen && classes.drawerOpen
						),
					}}>
					<Scrollbars
						renderThumbVertical={this.renderThumb}
						autoHide
						autoHideTimeout={1000}
						autoHideDuration={200}>
						<div>
							<div>
								{appConfig.MAP && appConfig.MAP.enabled === true ? (
									<ListItem
										title="Dashboard Map"
										button
										component={Link}
										to="/dashboard"
										onClick={() => navSelectPage("dashboard")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "dashboard" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon path={mdiMap} size={1} color="white">
												{" "}
											</Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Dashboard Map"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.WLM && appConfig.WLM.enabled === true ? (
									<ListItem
										title="Water Level Monitoring"
										button
										component={Link}
										to="/waterlevellist"
										onClick={() => navSelectPage("waterlevels")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "waterlevels" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon path={mdiWaves} size={1} color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Water Level Monitoring"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.WQM && appConfig.WQM.enabled === true ? (
									<ListItem
										title="Water Quality Monitoring"
										button
										component={Link}
										to="/waterqualitylist"
										onClick={() => navSelectPage("waterqualitylist")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "waterqualitylist" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<OpacityIcon style={{ color: "#fff" }} />
										</ListItemIcon>
										<ListItemText
											inset
											primary="Water Quality Monitoring"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.ISM && appConfig.ISM.enabled === true ? (
									<ListItem
										title="Interconnected SW Monitoring"
										button
										component={Link}
										to="/swgwlist"
										onClick={() => navSelectPage("wellslist")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "wellslist" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon
												path={mdiViewDashboard}
												size={1}
												color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Interconnected SW Monitoring"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.SM.enabled === true ? (
									<ListItem
										title="Subsidence Monitoring"
										button
										component={Link}
										to="/subsidencelist"
										onClick={() => navSelectPage("subsidencelist")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "subsidencelist" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon
												path={mdiAlertOutline}
												size={1}
												color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Subsidence Monitoring"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.SFM && appConfig.SFM.enabled === true ? (
									<ListItem
										title="Streamflow Monitoring"
										button
										component={Link}
										to="/streamflowlist"
										onClick={() => navSelectPage("streamflowlist")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "streamflowlist" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon
												path={mdiChartDonutVariant}
												size={1}
												color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Streamflow Monitoring"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.GWCS && appConfig.GWCS.enabled === true ? (
									<ListItem
										title="Groundwater Change in Storage"
										button
										component={Link}
										to="/groundwaterstorage"
										onClick={() => navSelectPage("groundwaterstorage")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "groundwaterstorage" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon path={mdiViewAgenda} size={1} color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="GW Change in Storage"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.GE && appConfig.GE.enabled === true ? (
									<ListItem
										button
										component={Link}
										to="/groundwaterextraction"
										onClick={() => navSelectPage("groundwaterextraction")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "groundwaterextraction" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<LocalDrink style={{ color: "#fff" }} />
										</ListItemIcon>
										<ListItemText
											inset
											primary="GW Extraction"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.GWR && appConfig.GWR.enabled === true ? (
									<ListItem
										button
										component={Link}
										to="/gwrecharge"
										onClick={() => navSelectPage("gwrecharge")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "gwrecharge" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon
												path={mdiCheckDecagram}
												size={1}
												color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="GW Recharge"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.SWS && appConfig.SWS.enabled === true ? (
									<ListItem
										button
										component={Link}
										to="/surfacewatersupply"
										onClick={() => navSelectPage("surfacewatersupply")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "surfacewatersupply" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<ListAlt style={{ color: "#fff" }} />
										</ListItemIcon>
										<ListItemText
											inset
											primary="Surface Water Supply"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.SWS && appConfig.SWS.enabled === true ? (
									<ListItem
										button
										// component={Link}
										// to="/surfacewatersupplyavail"
										onClick={() => navSelectPage("surfacewatersupplyavail")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "surfacewatersupplyavail" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon path={mdiWaterPump} size={1} color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Surface Water Supply"
											secondary="Used or Available for Use"
											classes={{
												primary: classes.topPageText,
												secondary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.TWU && appConfig.TWU.enabled === true ? (
									<ListItem
										button
										component={Link}
										to="/totalwateruse"
										onClick={() => navSelectPage("totalwateruse")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "totalwateruse" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Storage style={{ color: "#fff" }} />
										</ListItemIcon>
										<ListItemText
											inset
											primary="Total Water Use"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.EVT && appConfig.EVT.enabled === true ? (
									<ListItem
										button
										component={Link}
										to="/evapotranspiration"
										onClick={() => navSelectPage("evapotranspiration")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "evapotranspiration" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon path={mdiHydroPower} size={1} color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Evapotranspiration"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
								{appConfig.IP && appConfig.IP.enabled === true && (
									<ListItem
										button
										component={Link}
										to="/implementationprojects"
										onClick={() => navSelectPage("implementationprojects")}
										className={classNames(
											classes.listItem,
											navState.selectedPage === "implementationprojects" &&
												classes.selectedPage
										)}>
										<ListItemIcon className={classes.pageText}>
											<Icon path={mdiFileChart} size={1} color="white"></Icon>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Implementation Projects"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								)}

								{user &&
									user.role !== "Agency" &&
									appConfig.IMD.enabled === true && (
										<ListItem
											title="Import Data"
											button
											component={Link}
											to="/import"
											onClick={() => navSelectPage("import")}
											className={classNames(
												classes.listItem,
												navState.selectedPage === "import" &&
													classes.selectedPage
											)}>
											<ListItemIcon className={classes.pageText}>
												<Icon
													path={mdiSwapVertical}
													size={1}
													color="white"></Icon>
											</ListItemIcon>
											<ListItemText
												inset
												primary="Import Data"
												classes={{
													primary: classes.topPageText,
												}}
											/>
										</ListItem>
									)}
								{user &&
									user.role !== "Agency" &&
									appConfig.RWSSD.enabled === true && (
										<ListItem
											title="reporting"
											button
											component={Link}
											to="/reporting"
											onClick={() => navSelectPage("reporting")}
											className={classNames(
												classes.listItem,
												navState.selectedPage === "reporting" &&
													classes.selectedPage
											)}>
											<ListItemIcon className={classes.pageText}>
												<Icon
													path={mdiCheckDecagram}
													size={1}
													color="white"></Icon>
											</ListItemIcon>
											<ListItemText
												inset
												primary={ReactHtmlParser(
													"Report Water Supply<br /> and Storage Data"
												)}
												classes={{
													primary: classes.topPageText,
												}}
											/>
										</ListItem>
									)}
								{user &&
									user.role === "Administrator" &&
									appConfig.GSPRS.enabled === true && (
										<ListItem
											title="gspreportingstatus"
											button
											component={Link}
											to="/gspreportingstatus"
											onClick={() => navSelectPage("gspreportingstatus")}
											className={classNames(
												classes.listItem,
												navState.selectedPage === "gspreportingstatus" &&
													classes.selectedPage
											)}>
											<ListItemIcon className={classes.pageText}>
												<Icon
													path={mdiCheckDecagram}
													size={1}
													color="white"></Icon>
											</ListItemIcon>
											<ListItemText
												inset
												primary="GSP Reporting Status"
												classes={{
													primary: classes.topPageText,
												}}
											/>
										</ListItem>
									)}
								{user &&
									user.role === "Administrator" &&
									appConfig.ADMIN.enabled === true && (
										<ListItem
											title="administration"
											button
											component={Link}
											to="/administration"
											onClick={() => navSelectPage("administration")}
											className={classNames(
												classes.listItem,
												navState.selectedPage === "administration" &&
													classes.selectedPage
											)}>
											<ListItemIcon className={classes.pageText}>
												<Icon path={mdiSettings} size={1} color="white"></Icon>
											</ListItemIcon>
											<ListItemText
												inset
												primary="Administration"
												classes={{
													primary: classes.topPageText,
												}}
											/>
										</ListItem>
									)}
								{appConfig.COLAPSE.enabled === true ? (
									<ListItem
										title="Collapse Panel"
										button
										onClick={this.navigationClick}
										className={classes.listItem}>
										<ListItemIcon className={classes.pageText}>
											<>
												<div
													className={
														navState.navigationOpen ? classes.hidden : ""
													}>
													<Icon
														path={mdiChevronRight}
														size={1}
														color="white"></Icon>
												</div>
												<div
													className={
														navState.navigationOpen ? "" : classes.hidden
													}>
													<Icon
														path={mdiChevronLeft}
														size={1}
														color="white"></Icon>
												</div>
											</>
										</ListItemIcon>
										<ListItemText
											inset
											primary="Collapse Panel"
											classes={{
												primary: classes.topPageText,
											}}
										/>
									</ListItem>
								) : (
									<></>
								)}
							</div>
						</div>
					</Scrollbars>
				</Drawer>
			);
		} else {
			return (
				<Drawer
					id="drawContainer"
					variant="permanent"
					open={true}
					classes={{
						paper: classNames(
							classes.drawerPaper,
							navState.navigationOpen && classes.drawerOpen
						),
					}}>
					<NotReady message=" " />
				</Drawer>
			);
		}
	}
}
Navigation = connect(
	(state) => ({
		navState: state.nav,
		authState: state.auth,
		syncState: state.sync,
	}),
	{
		...navActions,
	}
)(Navigation);
export default withStyles(styles)(withRouter(Navigation));
