import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter, Link } from "react-router-dom";
import { withStyles, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import ViewIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import DashboardMap from "../common/map/Map";
import TableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import NotReady from "../common/NotReady";
import WellSiteSummary from "../../api/wellsiteSummaryHOC";

import BreadcrumbNav from "../common/BreadCrumb";

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	deleteWidth: {
		minWidth: 34,
		width: 34,
		marginRight: 16,
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		minWidth: 500,
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	divideButton: {
		marginBottom: 8,
		width: "50%",
		marginTop: 16,
		minWidth: 44,
	},
	smallerIcon: {
		width: ".9em",
	},
	buttonSelected: {
		border: "3px solid " + theme.palette.primary.main,
		minWidth: "44px",
	},
	buttonNotSelected: {
		border: "3px solid #666",
		minWidth: "44px",
	},
	iconSelected: {
		color: theme.palette.primary.main,
	},
	iconNotSelected: {
		color: "#666",
	},
	gridHeight: {},
	mapHeight: {
		height: "100%",
	},
	mapHeight2: {
		height: "calc(100% - 220px)",
	},
});

const columnData = [
	{ id: "actions", numeric: false, label: "Actions", allowSort: false },
	{ id: "dms_site_id", numeric: false, label: "DMS Site ID", allowSort: true },
	{
		id: "local_site_id",
		numeric: false,
		label: "Local Site ID or Name",
		allowSort: true,
	},
	{
		id: "source_gsp_label",
		numeric: false,
		label: "Source GSP",
		allowSort: true,
	},
	{
		id: "monitor_site_type",
		numeric: false,
		label: "SGMA Site Monitoring Type",
		allowSort: true,
	},
	{
		id: "source_gsa_label",
		numeric: false,
		label: "Source GSA",
		allowSort: true,
	},
	{
		id: "num_measurements",
		numeric: true,
		label: "Total Number of Measurements",
		allowSort: true,
	},
	{
		id: "date_range",
		numeric: false,
		label: "Date Range of Measurements",
		allowSort: true,
	},
];

class WellList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonState: "table",
			filter: null,
			order: "asc",
			orderBy: null,
			page: 0,
			rowsPerPage: 25,
		};
	}

	filterByValue = (array, string) => {
		return array.filter((o) =>
			Object.keys(o).some(
				(k) =>
					o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())
			)
		);
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		this.state.sites.sort(function (a, b) {
			var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
			if (numeric) {
				if (order === "desc")
					return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
				else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
			} else {
				if (order === "desc")
					return (b[orderBy] || "").toUpperCase() <
						(a[orderBy] || "").toUpperCase()
						? -1
						: 1;
				else
					return (a[orderBy] || "").toUpperCase() <
						(b[orderBy] || "").toUpperCase()
						? -1
						: 1;
			}
		});

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	handleAPIChange(data) {
		this.setState({
			sites: data.sites.filter(
				(s) => s.wl_monitoring === "True" || s.wl_monitoring === true
			),
		});
	}

	render() {
		const { classes } = this.props;
		const { order, orderBy, filter, page, rowsPerPage, buttonState, sites } =
			this.state;

		var filtered_data;
		if (filter) {
			filtered_data = this.filterByValue(sites, filter);
		} else {
			filtered_data = sites;
		}

		if (sites === undefined) {
			return (
				<AppContainer authenticated>
					<WellSiteSummary
						apitarget={"wellsitemeasurements"}
						handler={this.handleAPIChange.bind(this)}
					/>
					<NotReady message="Loading..." />
				</AppContainer>
			);
		} else {
			return (
				<AppContainer au authenticated>
					<Grid
						container
						spacing={24}
						className={
							buttonState === "map" ? classes.mapHeight : classes.gridHeight
						}>
						<Grid item xs={12} className={classes.breadCrumb}>
							<BreadcrumbNav
								level1="Well Level Monitoring"
								level1link="/waterlevellist"
							/>
						</Grid>
						<Grid item xs={7}>
							<Typography variant="h6">Keyword Filter</Typography>
						</Grid>
						<Grid
							item
							xs={1}
							style={{ marginLeft: -12, marginRight: -12 }}></Grid>
						<Grid item xs={4}>
							<Typography variant="h6">Select View</Typography>
						</Grid>
						<Grid
							item
							xs={7}
							style={{ backgroundColor: "#ddd", marginLeft: 12 }}>
							<Form dontValidateOnMount={true} validateOnSubmit={true}>
								{(formApi) => (
									<form style={{ float: "left" }} onSubmit={formApi.submitForm}>
										<TextField
											rounded
											style={{ borderRadius: 114 }}
											placeholder="Filter Sites"
											startAdornment={
												<InputAdornment style={{ marginRight: 8 }}>
													<SearchIcon />
												</InputAdornment>
											}
											eventHandle={(val) => {
												this.setState({ filter: val });
											}}
											field="filter"
											label=""
										/>
									</form>
								)}
							</Form>
						</Grid>
						<Grid
							item
							xs={1}
							style={{ marginLeft: -12, marginRight: -12 }}></Grid>
						<Grid
							item
							xs={4}
							style={{ backgroundColor: "#ddd", marginRight: 12 }}>
							<Button
								title="Map"
								onClick={() => this.setState({ buttonState: "map" })}
								className={classNames(
									classes.divideButton,
									buttonState === "map"
										? classes.buttonSelected
										: classes.buttonNotSelected
								)}>
								<MapIcon
									className={
										buttonState === "map"
											? classes.iconSelected
											: classes.iconNotSelected
									}
								/>
							</Button>
							<Button
								title="Table"
								onClick={() => this.setState({ buttonState: "table" })}
								className={classNames(
									classes.divideButton,
									buttonState === "table"
										? classes.buttonSelected
										: classes.buttonNotSelected
								)}>
								<ListIcon
									className={
										buttonState === "table"
											? classes.iconSelected
											: classes.iconNotSelected
									}
								/>
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							className={
								buttonState === "map" ? classes.mapHeight2 : classes.gridHeight
							}>
							{buttonState === "map" && (
								<DashboardMap
									pageType="Water Level"
									// filtered_data={filtered_data} // we are using wellsdata from store as this filtered data doesnt have geometry was used to speed up table loading
								/>
							)}
							{buttonState === "table" && (
								<Paper className={classes.root}>
									<Table className={classes.table}>
										<EnhancedTableHead
											columnData={columnData}
											order={order}
											orderBy={orderBy}
											onRequestSort={this.handleRequestSort}
										/>
										<TableBody>
											{filtered_data
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell>
															<Tooltip title="View Site">
																<Button
																	className={classes.deleteWidth}
																	component={Link}
																	to={"/waterleveldetail/" + item.id}>
																	<ViewIcon color="primary" />
																</Button>
															</Tooltip>
														</TableCell>
														<TableCell>{item.dms_site_id}</TableCell>
														<TableCell>{item.local_site_id}</TableCell>
														<TableCell>{item.source_gsp_label}</TableCell>
														<TableCell>{item.monitor_site_type}</TableCell>
														<TableCell>{item.source_gsa_label}</TableCell>
														<TableCell>{item.num_measurements}</TableCell>
														<TableCell>{item.date_range}</TableCell>
													</TableRow>
												))}
											{filtered_data.length < 1 && (
												<TableRow>
													<TableCell
														colSpan={8}
														className={classes.centerAlign}>
														No Water Level Sites Found
													</TableCell>
												</TableRow>
											)}
										</TableBody>
										{filtered_data.length > 25 && (
											<TableFooter>
												<TableRow>
													<TablePagination
														colSpan={8}
														count={filtered_data.length}
														rowsPerPage={rowsPerPage}
														page={page}
														onChangePage={this.handleChangePage}
														onChangeRowsPerPage={this.handleChangeRowsPerPage}
														ActionsComponent={TablePaginationActionsWrapped}
													/>
												</TableRow>
											</TableFooter>
										)}
									</Table>
								</Paper>
							)}
						</Grid>
					</Grid>
				</AppContainer>
			);
		}
	}
}

WellList = connect((state) => ({}), {})(WellList);

export default withStyles(styles)(withRouter(WellList));
