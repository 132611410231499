import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Form } from "react-form";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import TableCell from "../common/TableCell";
import ListIcon from "@material-ui/icons/List";
import PollIcon from "@material-ui/icons/Poll";
import ChevronRight from "@material-ui/icons/ChevronRight";
import moment from "moment";
import Select from "../common/Select";
import TextField from "../common/TextField";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
	ReferenceLine,
} from "recharts";

const styles = (theme) => ({
	divideContainer: {
		backgroundColor: "#ddd",
		textAlign: "center",
		margin: "0 -4px",
	},
	expansionBorder: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
	},
	lastBorder: {
		[theme.breakpoints.up("xl")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	lastBorder2: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	expandedMargin: {
		marginTop: "12px",
		minHeight: "48px !important",
		maxHeight: 48,
		backgroundColor: "#eee",
	},
	borderContainer: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
		borderRight: "2px solid " + theme.palette.primary.light,
		borderLeft: "2px solid " + theme.palette.primary.light,
	},
	divideButton: {
		marginBottom: 16,
		width: "calc(50% - 16px)",
		marginTop: 16,
		minWidth: 44,
	},
	buttonSelected: {
		border: "3px solid " + theme.palette.primary.main,
		minWidth: "44px",
	},
	buttonNotSelected: {
		border: "3px solid #666",
		minWidth: "44px",
	},
	iconSelected: {
		color: theme.palette.primary.main,
	},
	iconNotSelected: {
		color: "#666",
	},
	root: {
		width: "100%",
		overflowX: "auto",
	},
	table: {
		minWidth: 500,
		"& tbody tr:nth-child(even)": {
			backgroundColor: "rgb(106,225,255,.2)",
		},
	},
	centerAlign: {
		textAlign: "center",
	},
	borderContainer2: {
		border: "1px solid rgb(221, 221, 221)",
	},
	chartTitle: {
		backgroundColor: "rgb(221, 221, 221)",
		textAlign: "center",
		padding: "8px 4px !important",
	},
});

class SiteDetailChartTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonState: "chart",
			order: "desc",
			orderBy: this.props.orderBy,
			page: 0,
			rowsPerPage: this.props.rowsPerPage,
			y_axis: this.props.y_axis,
			y_axis_label: this.props.y_axis_label,
			ground_surface_elev: this.props.ground_surface_elev,
			start_date: null,
			end_date: null,
			min_value: null,
			max_value: null,
			filtered: [],
			yearoptions: [],
			columns: this.props.columns,
		};
		this.formKey = window.performance.now();
		this.printChart = this.printChart.bind(this);
	}

	componentDidUpdate() {
		const { start_date } = this.state;

		if (
			this.props.measurements &&
			this.props.measurements.length > 0 &&
			!start_date
		) {
			this.setState({
				start_date: this.getMinDate(this.props.measurements).getFullYear(),
				end_date: this.getMaxDate(this.props.measurements).getFullYear(),
			});
			this.formKey = window.performance.now();
		}
	}

	getColumnType = (orderBy) => {
		const { columns } = this.state;
		return columns.find((cD) => cD.id === orderBy).numeric;
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		var numeric = this.getColumnType(orderBy);

		this.state.filtered.sort(function (a, b) {
			if (numeric) {
				if (order === "desc")
					return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
				else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
			} else {
				if (order === "desc")
					return (b[orderBy] || "").toUpperCase() <
						(a[orderBy] || "").toUpperCase()
						? -1
						: 1;
				else
					return (a[orderBy] || "").toUpperCase() <
						(b[orderBy] || "").toUpperCase()
						? -1
						: 1;
			}
		});

		this.setState({ order: order, orderBy: orderBy });
	};

	handleChangePageStrm = (event, page) => {
		this.setState({ page: page });
	};

	handleChangeRowsPerPageStrm = (event) => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	formatDate(dateString) {
		if (!dateString) {
			return null;
		}
		const d = new Date(dateString + " 12:00:00");
		return d.toLocaleDateString();
	}

	formatTime(timeString) {
		if (!timeString) {
			return null;
		}
		const d = new Date("2000-01-01 " + timeString);
		return d.toLocaleTimeString();
	}

	getMinDate(arr) {
		return new Date(
			arr.reduce(
				(min, p) =>
					new Date(p[this.props.dateField] + " 12:00:00") < min
						? new Date(p[this.props.dateField] + " 12:00:00")
						: min,
				new Date(arr[0][this.props.dateField] + " 12:00:00")
			)
		);
	}

	getMaxDate(arr) {
		return new Date(
			arr.reduce(
				(max, p) =>
					new Date(p[this.props.dateField] + " 12:00:00") > max
						? new Date(p[this.props.dateField] + " 12:00:00")
						: max,
				new Date(arr[0][this.props.dateField] + " 12:00:00")
			)
		);
	}

	isDate(value) {
		var dateFormat;
		if (toString.call(value) === "[object Date]") {
			return true;
		}
		if (value && typeof value.replace === "function") {
			value.replace(/^\s+|\s+$/gm, "");
		}
		dateFormat =
			/(^\d{1,4}[\.|\\/|-]\d{1,2}[\.|\\/|-]\d{1,4})(\s*(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d\s*[ap]m)?$/;
		return dateFormat.test(value);
	}

	createGridColumns = (item) => {
		return this.props.columns.map((col) => {
			if (this.isDate(item[col.id])) {
				return <TableCell>{this.formatDate(item[col.id])}</TableCell>;
			} else {
				return <TableCell>{item[col.id]}</TableCell>;
			}
		});
	};

	getMinMaxVal(arr, val) {
		const { ground_surface_elev, y_axis } = this.state;

		let min = 1000000000,
			max = 0,
			buffer = 5;

		for (let i = 1, len = arr.length; i < len; i++) {
			let v = arr[i][y_axis];
			min = v < min ? v : min;
			max = v > max ? v : max;
		}

		if (ground_surface_elev) {
			if (ground_surface_elev > max) {
				max = ground_surface_elev;
			}

			if (ground_surface_elev < min) {
				min = ground_surface_elev;
			}
		}

		if (max - min < 2) {
			buffer = 1;
		}

		if (min && min !== 0) {
			min = this.round(min - buffer, 0);
		}

		if (min && min > 0) {
			min = 0;
		}

		max = this.round(max + buffer, 0);

		return { min, max };
	}

	round(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	printChart = () => {
		var printWindow = window.open("", "PrintMap", "width=1200,height=850");
		printWindow.document.writeln(
			"DMS Site ID: " +
				this.props.site_label +
				this.refs.chartContainer.container.innerHTML
		);
		printWindow.document.close();
	};

	filterFormatter = (measurements, start_date, end_date) => {
		let dateField = this.props.dateField;
		let timeField = this.props.timeField;
		let y_axis = this.props.y_axis;

		// Custom formatting of values for chart
		if (measurements.length > 0) {
			var filtered_measurements = measurements.slice();
			filtered_measurements.forEach(function (m) {
				if (!m.chart_date && m[dateField]) {
					var d;
					if (m[timeField] && m[timeField] !== "")
						d = new Date(m[dateField] + " " + m[timeField]);
					else d = new Date(m[dateField] + " 12:00:00");
					m.chart_date = d.getTime();
				}
				if (m[y_axis]) m[y_axis] = parseFloat(m[y_axis]);
			});

			filtered_measurements.sort((m, n) => m.chart_date - n.chart_date);

			if (
				start_date &&
				end_date &&
				(start_date < end_date || start_date === end_date)
			) {
				filtered_measurements = filtered_measurements.filter(
					(m) => new Date(m[dateField]).getFullYear() >= parseInt(start_date)
				);
			}
			if (
				start_date &&
				end_date &&
				(end_date > start_date || start_date === end_date)
			) {
				filtered_measurements = filtered_measurements.filter(
					(m) => new Date(m[dateField]).getFullYear() <= parseInt(end_date)
				);
			}
		}

		return filtered_measurements;
	};

	measurementYears = (measurements) => {
		if (measurements.length > 0) {
			var minDate = this.getMinDate(measurements);
			var maxDate = this.getMaxDate(measurements);
			var yearoptions = [];
			var y = minDate.getFullYear();
			var z = maxDate.getFullYear();
			while (y <= z) {
				yearoptions.push({ label: y, value: y });
				y++;
			}
			return yearoptions;
		} else {
			return null;
		}
	};

	render() {
		const { classes } = this.props;
		const {
			buttonState,
			page,
			rowsPerPage,
			order,
			orderBy,
			min_value,
			max_value,
			y_axis,
			y_axis_label,
			ground_surface_elev,
			start_date,
			end_date,
			filtered,
			yearoptions,
			columns,
		} = this.state;

		if (this.props.measurements.length && !filtered.length) {
			this.setState({
				filtered: this.filterFormatter(
					this.props.measurements,
					start_date,
					end_date
				),
				yearoptions: this.measurementYears(this.props.measurements),
			});
		}

		return (
			<Grid item xs={12}>
				<ExpansionPanel defaultExpanded>
					<ExpansionPanelSummary
						classes={{ root: classes.expandedMargin }}
						expandIcon={<ExpandMoreIcon />}>
						<ContactMail style={{ marginTop: 5 }} />
						<Typography style={{ paddingLeft: 32 }} variant="h6">
							{this.props.title}: {" " + this.props.site_label}
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container spacing={16}>
							<Grid item xs={8}></Grid>
							<Grid item xs={4}>
								<Typography variant="h6">Select View</Typography>
							</Grid>
							<Grid item xs={8}></Grid>
							<Grid item xs={4}>
								<div className={classes.divideContainer}>
									<Button
										title="Table"
										onClick={() => this.setState({ buttonState: "table" })}
										className={classNames(
											classes.divideButton,
											buttonState === "table"
												? classes.buttonSelected
												: classes.buttonNotSelected
										)}>
										<ListIcon
											className={
												buttonState === "table"
													? classes.iconSelected
													: classes.iconNotSelected
											}
										/>
									</Button>
									<Button
										title="Chart"
										onClick={() => this.setState({ buttonState: "chart" })}
										className={classNames(
											classes.divideButton,
											buttonState === "chart"
												? classes.buttonSelected
												: classes.buttonNotSelected
										)}>
										<PollIcon
											className={
												buttonState === "chart"
													? classes.iconSelected
													: classes.iconNotSelected
											}
										/>
									</Button>
								</div>
							</Grid>
							{buttonState === "table" && (
								<Grid item xs={12}>
									<Paper className={classes.root}>
										<Table className={classes.table}>
											<EnhancedTableHead
												columnData={columns}
												order={order}
												orderBy={orderBy}
												onRequestSort={this.handleRequestSort}
											/>
											<TableBody>
												{filtered
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((item) => (
														<TableRow key={item.id}>
															{this.createGridColumns(item)}
														</TableRow>
													))}
												{filtered.length < 1 && (
													<TableRow>
														<TableCell
															colSpan={7}
															className={classes.centerAlign}>
															No Measurements Found
														</TableCell>
													</TableRow>
												)}
											</TableBody>
											{filtered.length > 10 && (
												<TableFooter>
													<TableRow>
														<TablePagination
															colSpan={7}
															count={filtered.length}
															rowsPerPage={rowsPerPage}
															page={page}
															onChangePage={this.handleChangePageStrm}
															onChangeRowsPerPage={
																this.handleChangeRowsPerPageStrm
															}
															ActionsComponent={TablePaginationActionsWrapped}
														/>
													</TableRow>
												</TableFooter>
											)}
										</Table>
									</Paper>
								</Grid>
							)}
							{buttonState === "chart" && (
								<>
									{filtered.length === 0 && (
										<Grid item xs={12}>
											<Typography variant="h5" className={classes.centerAlign}>
												No Measurements Found
											</Typography>
										</Grid>
									)}
									{filtered.length > 0 && (
										<>
											<Grid item xs={8}>
												<ResponsiveContainer
													width="100%"
													height={680}
													ref="chartContainer">
													<LineChart data={filtered}>
														<CartesianGrid strokeDasharray="3 3" />
														<XAxis
															dataKey="chart_date"
															name="Measurement Date"
															domain={[
																this.getMinDate(this.props.measurements),
																this.getMaxDate(this.props.measurements),
															]}
															type="number"
															tickFormatter={(unixTime) =>
																moment(unixTime).format("M/D/YY")
															}
														/>
														<YAxis
															// domain has to be in functions otherwise it doesnt update?
															domain={[
																(dataMin) =>
																	min_value
																		? parseFloat(min_value)
																		: this.getMinMaxVal(
																				this.props.measurements,
																				y_axis
																		  ).min,
																(dataMax) =>
																	max_value
																		? parseFloat(max_value)
																		: this.getMinMaxVal(
																				this.props.measurements,
																				y_axis
																		  ).max,
															]} // 'auto' doesnt work instead of math.ceil
															dataKey={y_axis}
															type="number">
															<Label
																value="feet"
																position="center"
																angle={-90}
															/>
														</YAxis>
														<Tooltip
															labelFormatter={(value) =>
																new Date(value).toLocaleDateString()
															}
														/>
														<Legend />
														<Line
															connectNulls
															isAnimationActive={false} // Dots are broken on re-render if true https://github.com/recharts/recharts/issues/804
															name={y_axis_label}
															type="linear"
															dataKey={y_axis}
															stroke="#0080be"
														/>
														{y_axis === "surf_elev" && ground_surface_elev && (
															<ReferenceLine
																y={ground_surface_elev}
																label={
																	"Ground Surface Elevation " +
																	this.round(ground_surface_elev, 1)
																}
																stroke="brown"
															/>
														)}
													</LineChart>
												</ResponsiveContainer>
											</Grid>
											<Grid item xs={4}>
												<Form
													key={this.formKey}
													dontValidateOnMount={true}
													validateOnSubmit={true}
													defaultValues={this.state}>
													{(formApi) => (
														<form onSubmit={formApi.submitForm}>
															<Grid
																container
																spacing={8}
																className={classes.borderContainer2}>
																<Grid
																	item
																	xs={12}
																	className={classes.chartTitle}>
																	<Typography variant="h6">
																		Y Axis Values
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<Select
																		field="y_axis"
																		label=""
																		options={[
																			{
																				label: this.props.y_axis_label,
																				value: this.props.y_axis,
																			},
																		]}
																		fullWidth
																		eventHandle={(val) => {
																			var lab;
																			if (val === this.props.y_axis)
																				lab = this.props.y_axis_label;
																			this.setState({
																				y_axis: val,
																				y_axis_label: lab,
																			});
																		}}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	className={classes.chartTitle}>
																	<Typography variant="h6">
																		Summary Statistics
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<Typography>
																		<b>Date Range</b>:&nbsp;
																		{this.getMinDate(
																			this.props.measurements
																		).toLocaleDateString()}
																		&nbsp;-&nbsp;
																		{this.getMaxDate(
																			this.props.measurements
																		).toLocaleDateString()}
																	</Typography>
																</Grid>
																<Grid
																	item
																	xs={12}
																	className={classes.chartTitle}>
																	<Typography variant="h6">
																		Filter Monitor Data
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<Select
																		field="start_date"
																		label="Start Date"
																		eventHandle={(val) =>
																			this.setState({
																				start_date: val,
																				filtered: [],
																			})
																		}
																		options={yearoptions}
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	<Select
																		field="end_date"
																		label="End Date"
																		eventHandle={(val) =>
																			this.setState({
																				end_date: val,
																				filtered: [],
																			})
																		}
																		options={yearoptions}
																		fullWidth
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	className={classes.chartTitle}>
																	<Typography variant="h6">
																		Chart Settings
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		field="min_value"
																		type="number"
																		label="Y Axis Min Value"
																		eventHandle={(val) =>
																			this.setState({ min_value: val })
																		}
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		field="max_value"
																		type="number"
																		label="Y Axis Max Value"
																		eventHandle={(val) =>
																			this.setState({ max_value: val })
																		}
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	<Button
																		fullWidth
																		variant="contained"
																		color="primary"
																		onClick={this.printChart}>
																		<Typography
																			style={{
																				textAlign: "left",
																				width: "100%",
																				color: "#fff",
																			}}>
																			Print Chart
																		</Typography>
																		<ChevronRight style={{ float: "right" }} />
																	</Button>
																</Grid>
															</Grid>
														</form>
													)}
												</Form>
											</Grid>
										</>
									)}
								</>
							)}
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Grid>
		);
	}
}

SiteDetailChartTable = connect(
	(state, ownProps) => ({
		authState: state.auth,
	}),
	{}
)(SiteDetailChartTable);

export default withStyles(styles)(SiteDetailChartTable);
