/*!
 * heigeo/legend-layers.js
 * Custom layer control with support for legends and custom collapsible panes
 * (c) 2019, Houston Engineering, Inc.
 */
import L, { Control, DomEvent, DomUtil, Util } from "leaflet";

var LegendLayers = Control.extend({
	options: {
		collapsed: false,
	},
	initialize: function (baseLayers, overlays, options) {
		Util.setOptions(this, options);
		this._layerControlInputs = [];
		this._contourSelectOptions = [];
		this._layers = [];
		this._lastZIndex = 0;
		this._handlingClick = false;
		for (var i in baseLayers) {
			this._addLayer(baseLayers[i], i);
		}
		for (i in overlays) {
			this._addLayer(overlays[i], i, true);
		}
	},
	_initLayout: function () {
		// Start from default layers control layout
		L.Control.Layers.prototype._initLayout.call(this);
		this._overlaysList2 = L.DomUtil.create(
			"div",
			"leaflet-control-layers-overlays",
			this._section
		);
		L.DomEvent.disableClickPropagation(this._container);
		// Custom toggle button (works on click rather than hover)
		L.DomUtil.remove(this._container.childNodes[0]);
		var open = L.DomUtil.create(
			"a",
			"leaflet-control-layers-toggle",
			this._container
		);
		open.href = "#";
		open.title = "Layers";
		L.DomEvent.on(
			open,
			"click",
			function (evt) {
				evt.preventDefault();
				this._expand();
			},
			this
		);
		// Custom top header with minimize button
		var topheader = L.DomUtil.create("h2", "legendlayers-top-header");
		topheader.innerHTML = "Map Layers";
		var close = L.DomUtil.create("a", "legendlayers-collapse");
		close.href = "#";
		close.innerHTML = "-";
		close.title = "Close Layers";
		L.DomEvent.on(
			close,
			"click",
			function (evt) {
				evt.preventDefault();
				this._collapse();
			},
			this
		);
		topheader.insertBefore(close, topheader.firstChild);
		this._container.insertBefore(topheader, this._section);
		// Make default sections collapsible
		collapsible("Base Maps", this._baseLayersList);
		collapsible("DMS Layers", this._overlaysList);
		collapsible("Reference Layers", this._overlaysList2);
		this._section.removeChild(this._separator);
		// Add any custom collapsible sections
		(this.options.sections || []).forEach(function (section) {
			var div = L.DomUtil.create(
				"div",
				"legendlayers-section leaflet-control-layers-list",
				this._container
			);
			Promise.resolve(section.html).then(function (html) {
				div.innerHTML = html;
			});
			collapsible(section.title, div);
		}, this);
		function collapsible(title, elem) {
			var header = L.DomUtil.create("h3", "legendlayers-header");
			elem.parentNode.insertBefore(header, elem);
			_toggle(header, title, elem, "", true);
		}
	},
	_addItem: function (obj) {
		if (
			obj.overlay2 &&
			obj.layer.options.info &&
			obj.layer.options.info.selector
		) {
			if (this._contourSelect) {
				// do nothing
			} else {
				var contourContainer = document.createElement("div");
				contourContainer.id = "contourContainer";

				var contourLabel = document.createElement("div");
				contourLabel.id = "contourlabel";
				contourLabel.innerHTML = "Water Level Contours: ";
				this._contourSelect = document.createElement("select");
				this._contourSelect.id = "contourSelect";
				DomEvent.on(this._contourSelect, "change", this._onInputClick, this);
				let emptyOption = document.createElement("option");
				emptyOption.value = "";
				emptyOption.text = "";
				this._contourSelect.appendChild(emptyOption);
				this._contourSelect.selectedIndex = 0;
				this._contourSelectOptions.push(null);
				contourContainer.appendChild(contourLabel);
				contourContainer.appendChild(this._contourSelect);
				this._overlaysList2.prepend(contourContainer);
			}

			if (
				obj.overlay2 &&
				obj.layer.options.info &&
				obj.layer.options.info.selector
			) {
				var option = document.createElement("option");
				option.value = Util.stamp(obj.layer);
				option.text = obj.name;
				this._contourSelectOptions.push(option);
				this._layerControlInputs.push(option);
				this._contourSelect.appendChild(option);
				return null;
			}
		}

		var label = document.createElement("label"),
			checked = this._map.hasLayer(obj.layer),
			input;
		if (obj.overlay) {
			input = document.createElement("input");
			input.type = "checkbox";
			input.className = "leaflet-control-layers-selector";
			input.defaultChecked = checked;
			this._layerControlInputs.push(input);
		} else if (obj.overlay2) {
			input = document.createElement("input");
			input.type = "checkbox";
			input.className = "leaflet-control-layers-selector";
			input.defaultChecked = checked;
			this._layerControlInputs.push(input);
		} else {
			input = this._createRadioElement(
				"leaflet-base-layers_" + Util.stamp(this),
				checked
			);
			this._layerControlInputs.push(input);
		}
		input.layerId = Util.stamp(obj.layer);
		DomEvent.on(input, "click", this._onInputClick, this);
		var name = document.createElement("span");
		name.innerHTML = " " + obj.name;
		// Helps from preventing layer control flicker when checkboxes are disabled
		// https://github.com/Leaflet/Leaflet/issues/2771
		var holder = document.createElement("div");
		label.appendChild(holder);
		holder.appendChild(input);
		holder.appendChild(name);
		var container = obj.overlay2
			? this._overlaysList2
			: obj.overlay
			? this._overlaysList
			: this._baseLayersList;
		container.appendChild(label);
		this._checkDisabledLayers();
		return label;
	},
	removeLayer: function (layer) {
		layer.off("add remove", this._onLayerChange, this);
		var obj = this._getLayer(Util.stamp(layer));
		if (obj) {
			this._layers.splice(this._layers.indexOf(obj), 1);
		}
		return this._map ? this._update() : this;
	},
	addOverlay: function (layer, name) {
		this._addLayer(layer, name, true);
		return this._map ? this._update() : this;
	},
	addOverlay2: function (layer, name) {
		this._addLayer(layer, name, false, true);
		return this._map ? this._update() : this;
	},
	_onInputClick: function () {
		var inputs = this._layerControlInputs,
			input,
			layer;
		var addedLayers = [],
			removedLayers = [];
		this._handlingClick = true;

		for (var i = inputs.length - 1; i >= 0; i--) {
			input = inputs[i];
			if (input.tagName === "OPTION") {
				layer = this._getLayer(parseInt(input.value)).layer;

				//  contour layer option
				//  get index of this option
				const selectedOptionIndex = this._contourSelect.selectedIndex;

				if (selectedOptionIndex === 0) {
					removedLayers.push(layer);
				} else {
					const selectedOptionValue =
						this._contourSelectOptions[selectedOptionIndex].value;
					for (var j = 1; j <= this._contourSelectOptions.length - 1; j++) {
						const val = this._contourSelectOptions[j].value;
						if (val === input.value) {
							if (selectedOptionValue === val) {
								addedLayers.push(layer);
							} else {
								removedLayers.push(layer);
							}
						}
					}
				}
			} else {
				//  not contour layer option
				layer = this._getLayer(input.layerId).layer;
				if (input.checked) {
					addedLayers.push(layer);
				} else if (!input.checked) {
					removedLayers.push(layer);
				}
			}
		}
		// Bugfix issue 2318: Should remove all old layers before readding new ones
		for (i = 0; i < removedLayers.length; i++) {
			if (this._map.hasLayer(removedLayers[i])) {
				this._map.removeLayer(removedLayers[i]);
			}
		}

		for (i = 0; i < addedLayers.length; i++) {
			if (!this._map.hasLayer(addedLayers[i])) {
				this._map.addLayer(addedLayers[i]);
			}
		}

		this._handlingClick = false;
		this._refocusOnMap();
	},
	_addLayer: function (layer, name, overlay, overlay2) {
		if (this._map) {
			layer.on("add remove", this._onLayerChange, this);
		}
		this._layers.push({
			layer: layer,
			name: name,
			overlay: overlay,
			overlay2: overlay2,
		});
		if (this.options.sortLayers) {
			this._layers.sort(
				Util.bind(function (a, b) {
					return this.options.sortFunction(a.layer, b.layer, a.name, b.name);
				}, this)
			);
		}
		if (this.options.autoZIndex && layer.setZIndex) {
			this._lastZIndex++;
			layer.setZIndex(this._lastZIndex);
		}

		this._expandIfNotCollapsed();
	},
	_getLayer: function (id) {
		for (var i = 0; i < this._layers.length; i++) {
			if (this._layers[i] && Util.stamp(this._layers[i].layer) === id) {
				return this._layers[i];
			}
		}
	},
	empty: function (el) {
		while (el.firstChild) {
			el.removeChild(el.firstChild);
		}
	},
	onAdd: function (map) {
		this._initLayout();
		this._update();
		this._map = map;
		map.on("zoomend", this._checkDisabledLayers, this);
		for (var i = 0; i < this._layers.length; i++) {
			this._layers[i].layer.on("add remove", this._onLayerChange, this);
		}
		return this._container;
	},
	expand: function () {
		DomUtil.addClass(this._container, "leaflet-control-layers-expanded");
		this._section.style.height = null;
		var acceptableHeight =
			this._map.getSize().y - (this._container.offsetTop + 50);
		if (acceptableHeight < this._section.clientHeight) {
			DomUtil.addClass(this._section, "leaflet-control-layers-scrollbar");
			this._section.style.height = acceptableHeight + "px";
		} else {
			DomUtil.removeClass(this._section, "leaflet-control-layers-scrollbar");
		}
		this._checkDisabledLayers();
		return this;
	},
	collapse: function () {
		DomUtil.removeClass(this._container, "leaflet-control-layers-expanded");
		return this;
	},
	addTo: function (map) {
		Control.prototype.addTo.call(this, map);
		// Trigger expand after Layers Control has been inserted into DOM so that is now has an actual height.
		return this._expandIfNotCollapsed();
	},
	onRemove: function () {
		this._map.off("zoomend", this._checkDisabledLayers, this);
		for (var i = 0; i < this._layers.length; i++) {
			this._layers[i].layer.off("add remove", this._onLayerChange, this);
		}
	},
	// @method addBaseLayer(layer: Layer, name: String): this
	// Adds a base layer (radio button entry) with the given name to the control.
	addBaseLayer: function (layer, name) {
		this._addLayer(layer, name);
		return this._map ? this._update() : this;
	},
	_update: function () {
		// Start from default checkbox/radio layout
		this.empty(this._overlaysList);
		this.empty(this._overlaysList2);
		L.Control.Layers.prototype._update.call(this);
		// Insert legends after any layer that has them
		/*
            HACK: nmapp has this._layers in the same order as layerId from this._overlaysList
            They aren't here for some reason...
            Just assume we always have 3 basemaps for now and the checkboxes/layers are in the same order
            i = 2
        */
		var checkboxes = this._overlaysList.getElementsByTagName("input"),
			layers = this._layers;
		let i = this._baseLayersList.getElementsByTagName("input").length - 1;
		Array.prototype.forEach.call(checkboxes, function (checkbox) {
			i++;
			if (layers[i] !== undefined) {
				var name = layers[i].name;
				var options = layers[i].layer.options || {};
				var span = checkbox.parentNode.lastChild;
				L.DomEvent.on(checkbox, "click", function () {
					/*if (this.checked)
                        this.checked = false;
                    else
                        this.checked = true;*/
				});
				if (!options.legend) {
					return;
				}
				span.className = "expandable";
				var legend;
				if (checkbox.checked) {
					span.innerHTML = " ▾ " + name;
					legend = L.DomUtil.create(
						"ul",
						"legend expanded",
						checkbox.parentNode
					);
				} else {
					span.innerHTML = " ▸ " + name;
					legend = L.DomUtil.create("ul", "legend", checkbox.parentNode);
				}
				options.legend.forEach(function (row) {
					var item = L.DomUtil.create("li", "legend", legend);
					var color, img, title;
					if (row.color) {
						color = L.DomUtil.create("span", "legend-color", item);
						color.style.backgroundColor = row.color;
					} else if (row.image) {
						img = L.DomUtil.create("img", "legend-image", item);
						img.src = row.image;
					}
					title = L.DomUtil.create("span", "legend-title", item);
					title.innerHTML = row.title;
				});
				_toggle2(span, checkbox, name, legend, " ");
			}
		});

		var checkboxes2 = this._overlaysList2.getElementsByTagName("input");

		layers = this._layers;
		i =
			this._baseLayersList.getElementsByTagName("input").length +
			this._overlaysList.getElementsByTagName("input").length -
			1;
		Array.prototype.forEach.call(checkboxes2, function (checkbox) {
			i++;
			if (layers[i] !== undefined) {
				var name = layers[i].name;
				var options = layers[i].layer.options || {};
				var span = checkbox.parentNode.lastChild;
				L.DomEvent.on(checkbox, "click", function () {
					/*if (this.checked)
                        this.checked = false;
                    else
                        this.checked = true;*/
				});
				if (!options.legend) {
					return;
				}
				span.className = "expandable";
				var legend;
				if (checkbox.checked) {
					span.innerHTML = " ▾ " + name;
					legend = L.DomUtil.create(
						"ul",
						"legend expanded",
						checkbox.parentNode
					);
				} else {
					span.innerHTML = " ▸ " + name;
					legend = L.DomUtil.create("ul", "legend", checkbox.parentNode);
				}
				options.legend.forEach(function (row) {
					var item = L.DomUtil.create("li", "legend", legend);
					var color, img, title;
					if (row.color) {
						color = L.DomUtil.create("span", "legend-color", item);
						color.style.backgroundColor = row.color;
					} else if (row.image) {
						img = L.DomUtil.create("img", "legend-image", item);
						img.src = row.image;
					}
					title = L.DomUtil.create("span", "legend-title", item);
					title.innerHTML = row.title;
				});
				_toggle2(span, checkbox, name, legend, " ");
			}
		});
	},
	_checkDisabledLayers: function () {
		var inputs = this._layerControlInputs,
			input,
			layer,
			zoom = this._map.getZoom();
		for (var i = inputs.length - 1; i >= 0; i--) {
			input = inputs[i];
			if (input.tagName === "OPTION") {
				layer = this._getLayer(parseInt(input.value)).layer;
			} else {
				layer = this._getLayer(input.layerId).layer;
			}
			input.disabled =
				(layer.options.minZoom !== undefined && zoom < layer.options.minZoom) ||
				(layer.options.maxZoom !== undefined && zoom > layer.options.maxZoom);
		}
	},
	_expandIfNotCollapsed: function () {
		if (this._map && !this.options.collapsed) {
			this.expand();
		}
		return this;
	},
	_onLayerChange: function (e) {
		if (!this._handlingClick) {
			this._update();
		}
		var obj = this._getLayer(Util.stamp(e.target));
		// @namespace Map
		// @section Layer events
		// @event baselayerchange: LayersControlEvent
		// Fired when the base layer is changed through the [layer control](#control-layers).
		// @event overlayadd: LayersControlEvent
		// Fired when an overlay is selected through the [layer control](#control-layers).
		// @event overlayremove: LayersControlEvent
		// Fired when an overlay is deselected through the [layer control](#control-layers).
		// @namespace Control.Layers
		var type = obj.overlay
			? e.type === "add"
				? "overlayadd"
				: "overlayremove"
			: e.type === "add"
			? "baselayerchange"
			: null;
		if (type) {
			this._map.fire(type, obj);
		}
	},
	_createRadioElement: function (name, checked) {
		var radioHtml =
			'<input type="radio" class="leaflet-control-layers-selector" name="' +
			name +
			'"' +
			(checked ? ' checked="checked"' : "") +
			"/>";
		var radioFragment = document.createElement("div");
		radioFragment.innerHTML = radioHtml;
		return radioFragment.firstChild;
	},
	_expand: function () {
		// Backward compatibility, remove me in 1.1.
		return this.expand();
	},
	_collapse: function () {
		// Backward compatibility, remove me in 1.1.
		return this.collapse();
	},
});
// Quick toggle plugin with unicode arrow
function _toggle(header, title, elem, prefix, open) {
	L.DomEvent.on(header, "click", update);
	update();
	function update() {
		if (open) {
			header.innerHTML = prefix + "▾ " + title;
			elem.className += " expanded";
		} else {
			header.innerHTML = prefix + "▸ " + title;
			elem.className = elem.className.replace(" expanded", "");
		}
		open = !open;
	}
}
function _toggle2(label, checkbox, title, elem, prefix) {
	L.DomEvent.on(checkbox, "click", update);
	function update() {
		if (!elem.className.includes("expanded")) {
			label.innerHTML = prefix + "▾ " + title;
			elem.className += " expanded";
		} else {
			label.innerHTML = prefix + "▸ " + title;
			elem.className = elem.className.replace(" expanded", "");
		}
	}
}
export default LegendLayers;
