import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import Grid from "@material-ui/core/Grid";
import NotReady from "../common/NotReady";
import WellSiteDetails from "../../api/wellsiteDetailsHOC";

import AppContainer from "../common/AppContainer";

import Icon from "@mdi/react";
import { mdiAlphaP } from "@mdi/js";

import BreadcrumbNav from "../common/BreadCrumb";
import SiteDetailChartTable from "../common/SiteDetailChartTable";

const styles = (theme) => ({
	breadCrumb: {
		width: "100vw",
		margin: "0 -20px",
		padding: "0px 35px !important",
		maxWidth: "105%",
		flexBasis: "auto",
		backgroundColor: "#eeeeee",
	},
	expansionBorder: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
	},
	lastBorder: {
		[theme.breakpoints.up("xl")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	lastBorder2: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	expandedMargin: {
		marginTop: "12px",
		minHeight: "48px !important",
		maxHeight: 48,
		backgroundColor: "#eee",
	},
	borderContainer: {
		borderTop: "1px solid " + theme.palette.primary.light,
		borderBottom: "1px solid " + theme.palette.primary.light,
		borderRight: "2px solid " + theme.palette.primary.light,
		borderLeft: "2px solid " + theme.palette.primary.light,
	},
	borderContainer2: {
		border: "1px solid rgb(221, 221, 221)",
	},
	marginLeft: {
		marginLeft: -16,
	},
});

const streamMonitoringColumnData = [
	{ id: "meas_date", numeric: false, label: "Date", allowSort: true },
	{ id: "meas_time", numeric: false, label: "Time", allowSort: true },
	{
		id: "stream_stage",
		numeric: true,
		label: "Stream Stage",
		allowSort: true,
	},
	{
		id: "meas_comments",
		numeric: true,
		label: "Comments",
		allowSort: true,
	},
];

const verticalGradientColumnData = [
	{
		id: "meas_date_stream",
		numeric: false,
		label: "Stream Measurement Date ",
		allowSort: true,
	},
	{
		id: "meas_time_stream",
		numeric: false,
		label: "Stream Measurement Time",
		allowSort: true,
	},
	{
		id: "stream_stage",
		numeric: true,
		label: "Height of water in stream",
		allowSort: true,
	},
	{
		id: "meas_date_sa_gwe",
		numeric: false,
		label: "Shallow Aquifer Measurement Date ",
		allowSort: true,
	},
	{
		id: "meas_time_sa_gwe",
		numeric: false,
		label: "Shallow Aquifer Measurement Time",
		allowSort: true,
	},
	{
		id: "sa_gwe",
		numeric: true,
		label: "Shallow Groundwater elevation (MSL)",
		allowSort: true,
	},
	{
		id: "meas_date_ua_gwe",
		numeric: false,
		label: "Upper Aquifer Measurement Date ",
		allowSort: true,
	},
	{
		id: "meas_time_ua_gwe",
		numeric: false,
		label: "Upper Aquifer Measurement Time",
		allowSort: true,
	},
	{
		id: "ua_gwe",
		numeric: true,
		label: "Upper Aquifer groundwater elevation (MSL)",
		allowSort: true,
	},
	{
		id: "meas_date_la_gwe",
		numeric: false,
		label: "Lower Aquifer Measurement Date ",
		allowSort: true,
	},
	{
		id: "meas_time_la_gwe",
		numeric: false,
		label: "Lower Aquifer Measurement Time",
		allowSort: true,
	},
	{
		id: "la_gwe",
		numeric: true,
		label: "Lower Aquifer groundwater elevation (MSL)",
		allowSort: true,
	},
	{
		id: "horiz_grad_sa",
		numeric: true,
		label:
			"Horizontal gradient between stream to Shallow groundwater in Upper Aquifer",
		allowSort: true,
	},
	{
		id: "horiz_grad_ua",
		numeric: true,
		label: "Horizontal gradient between stream to Upper Aquifer",
		allowSort: true,
	},
	{
		id: "horiz_grad_la",
		numeric: true,
		label: "Horizontal gradient between stream to Lower Aquifer",
		allowSort: true,
	},
	{
		id: "vert_grad_sa_ua",
		numeric: true,
		label:
			"Vertical gradient from shallow groundwater to deeper groundwater in the Upper Aquifer",
		allowSort: true,
	},
	{
		id: "vert_grad_ua_la",
		numeric: true,
		label: "Vertical gradient from Upper Aquifer to Lower Aquifer",
		allowSort: true,
	},
	{
		id: "meas_accuracy",
		numeric: false,
		label: "Measurement Accuracy",
		allowSort: true,
	},
	{
		id: "meas_comments",
		numeric: false,
		label: "Measurement Comments",
		allowSort: true,
	},
];

const wellSiteMeasurementsColumnData = [
	{ id: "measurement_date", numeric: false, label: "Date", allowSort: true },
	{ id: "measurement_time", numeric: false, label: "Time", allowSort: true },
	{
		id: "measurement_depth",
		numeric: true,
		label: (
			<>
				Meas. Depth
				<br />
				from RP (ft)
			</>
		),
		allowSort: true,
	},
	{
		id: "surf_elev",
		numeric: true,
		label: (
			<>
				Water Surface
				<br />
				Elevation (ft)
			</>
		),
		allowSort: true,
	},
	{
		id: "vert_datum",
		numeric: false,
		label: "Vertical Datum",
		allowSort: true,
	},
	{ id: "meas_method", numeric: false, label: "Meas. Method", allowSort: true },
	{
		id: "meas_accuracy",
		numeric: false,
		label: "Meas. Accuracy",
		allowSort: true,
	},
	{ id: "no_measurement", numeric: false, label: "No Meas.", allowSort: true },
	{
		id: "quest_meas",
		numeric: false,
		label: "Questionable Meas.",
		allowSort: true,
	},
	{ id: "measured_by", numeric: false, label: "Meas. By", allowSort: true },
	{ id: "meas_comments", numeric: false, label: "Comments", allowSort: true },
];

class SWGWSiteDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			strm_measurements: [],
			ua_measurements: [],
			la_measurements: [],
			sa_measurements: [],
			vert_grad_sa_ua_measurements: [],
		};
	}

	handleAPIChange(data) {
		const { site } = this.state;

		if (!site) {
			this.setState({
				site: data.site,
				strm_measurements: data.site.dms_strm_site_measurements
					? data.site.dms_strm_site_measurements
					: [],
				ua_measurements: data.site.dms_ua_site_measurements
					? data.site.dms_ua_site_measurements
					: [],
				la_measurements: data.site.dms_la_site_measurements
					? data.site.dms_la_site_measurements
					: [],
				sa_measurements: data.site.dms_sa_site_measurements
					? data.site.dms_sa_site_measurements
					: [],
				vert_grad_sa_ua_measurements: data.site.dms_vert_grad_sa_ua_measurements
					? data.site.dms_vert_grad_sa_ua_measurements
					: [],
			});
		}
	}

	render() {
		const { classes } = this.props;
		const {
			strm_measurements,
			ua_measurements,
			la_measurements,
			sa_measurements,
			vert_grad_sa_ua_measurements,
			site,
		} = this.state;

		let siteId = parseInt(this.props.match.params["id"]);

		if (site === undefined) {
			return (
				<AppContainer authenticated>
					<WellSiteDetails
						apitarget={"surfacewatermeasurements"}
						siteid={siteId}
						handler={this.handleAPIChange.bind(this)}
					/>
					<NotReady message="Loading..." />
				</AppContainer>
			);
		} else {
			return (
				<AppContainer authenticated>
					<Grid container spacing={24}>
						<Grid item xs={12} className={classes.breadCrumb}>
							<BreadcrumbNav
								level1="Interconnected SW Monitoring"
								level1link="/SWGWList"
								level2={"DMS ID: " + site.dms_site_id}
								level2link={"/SWGWdetail/" + site.id}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">
								Surface Water - Groundwater Interaction Site Details
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<ExpansionPanel defaultExpanded>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<ContactMail style={{ marginTop: 5 }} />
									<Typography style={{ paddingLeft: 32 }} variant="h6">
										Identification/Location
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>DMS ID</b>: {site.dms_site_id}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Local Site ID</b>: {site.local_site_id}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Alternative Site ID or Name</b>: {site.alt_site_id}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Owner or Operator</b>: {site.owner_label}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Monitored By</b>: {site.monitor_by_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Source GSA Organization</b>: {site.source_gsa_label}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Source GSP Organization</b>: {site.source_gsp_label}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Monitory Type</b>: {site.monitor_type_label}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>DMS Stream Site ID</b>: {site.dms_strm_site_label}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>DMS Shallow Groundwater Well Site ID</b>:{" "}
												{site.dms_sa_site_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>DMS Upper Aquifer Well Site ID)</b>:{" "}
												{site.dms_ua_site_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>DMS Lower Aquifer Well Site ID</b>:{" "}
												{site.dms_la_site_label}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Latitude</b>:{" "}
												{site.geometry && site.geometry.coordinates[1]}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Longitude</b>:{" "}
												{site.geometry && site.geometry.coordinates[0]}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>
													Original gradient btw stream and shallow groundwater
												</b>
												: {site.origin_horz_grad_sa}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Original gradient btw stream and UA</b>:{" "}
												{site.origin_horz_grad_ua}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Original gradient btw stream and LA</b>:{" "}
												{site.origin_horz_grad_la}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>
													Original vertical gradient between shallow groundwater
													and Upper Aquifer
												</b>
												: {site.origin_vert_grad_sa_ua}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>
													Original vertical gradient between Upper and Lower
													Aquifers
												</b>
												: {site.origin_vert_grad_ua_la}
											</Typography>
										</Grid>
										<Grid
											className={classNames(
												classes.expansionBorder,
												classes.lastBorder2
											)}
											item
											xs={6}
											xl={4}></Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						<Grid item xs={12}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									classes={{ root: classes.expandedMargin }}
									expandIcon={<ExpandMoreIcon />}>
									<Icon
										path={mdiAlphaP}
										className={classes.marginLeft}
										size={2}></Icon>
									<Typography
										style={{ paddingLeft: 16, marginTop: 8 }}
										variant="h6">
										{" "}
										Plan Information
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid
										container
										spacing={16}
										className={classes.borderContainer}>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>GSP Monitoring Network</b>: {site.gsp_mon_network}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>GSP Threshold</b>: {site.gsp_threshold}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>GSP Measurement Objective</b>: {site.meas_objective}
											</Typography>
										</Grid>

										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Year 5 Interim Milestone (2025)</b>:{" "}
												{site.yr5_milestone}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Year 10 Interim Milestone (2030)</b>:{" "}
												{site.yr10_milestone}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Year 15 Interim Milestone (2035)</b>:{" "}
												{site.yr15_milestone}
											</Typography>
										</Grid>
										<Grid
											className={classes.expansionBorder}
											item
											xs={12}
											md={6}
											xl={4}>
											<Typography>
												<b>Site Notes</b>: {site.site_notes}
											</Typography>
										</Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						{strm_measurements.length > 0 && (
							<SiteDetailChartTable
								title={"DMS Stream Site Measurement"}
								measurements={strm_measurements}
								columns={streamMonitoringColumnData}
								orderBy={"meas_date"}
								dateField={"meas_date"}
								timeField={"meas_time"}
								rowsPerPage={10}
								y_axis={"stream_stage"}
								y_axis_label={"Stream Stage"}
								site_label={site.dms_strm_site_label}
								geometry={site.geometry}></SiteDetailChartTable>
						)}
						{ua_measurements.length > 0 && (
							<SiteDetailChartTable
								title={"DMS Upper Aquifer Well Site Measurement"}
								measurements={ua_measurements}
								columns={wellSiteMeasurementsColumnData}
								orderBy={"measurement_date"}
								dateField={"measurement_date"}
								timeField={"measurement_time"}
								rowsPerPage={10}
								y_axis={"surf_elev"}
								y_axis_label={"Surface Elevation"}
								ground_surface_elev={site.dms_ua_site_ground_surface_elev}
								site_label={site.dms_ua_site_label}
								geometry={site.geometry}></SiteDetailChartTable>
						)}
						{la_measurements.length > 0 && (
							<SiteDetailChartTable
								title={"DMS Lower Aquifer Well Site Measurement"}
								measurements={la_measurements}
								columns={wellSiteMeasurementsColumnData}
								orderBy={"measurement_date"}
								dateField={"measurement_date"}
								timeField={"measurement_time"}
								rowsPerPage={10}
								y_axis={"surf_elev"}
								y_axis_label={"Surface Elevation"}
								ground_surface_elev={site.dms_la_site_ground_surface_elev}
								site_label={site.dms_la_site_label}
								geometry={site.geometry}></SiteDetailChartTable>
						)}
						{sa_measurements.length > 0 && (
							<SiteDetailChartTable
								title={"DMS Shallow Groundwater Well Site Measurement"}
								measurements={sa_measurements}
								columns={wellSiteMeasurementsColumnData}
								orderBy={"measurement_date"}
								dateField={"measurement_date"}
								timeField={"measurement_time"}
								rowsPerPage={10}
								y_axis={"surf_elev"}
								y_axis_label={"Surface Elevation"}
								ground_surface_elev={site.dms_sa_site_ground_surface_elev}
								site_label={site.dms_sa_site_label}
								geometry={site.geometry}></SiteDetailChartTable>
						)}
						{vert_grad_sa_ua_measurements.length > 0 && (
							<SiteDetailChartTable
								title={"Vertical Gradient Measurement"}
								measurements={vert_grad_sa_ua_measurements}
								columns={verticalGradientColumnData}
								orderBy={"meas_date_sa_gwe"}
								dateField={"meas_date_sa_gwe"}
								timeField={"meas_time_sa_gwe"}
								rowsPerPage={10}
								y_axis={"vert_grad_sa_ua"}
								y_axis_label={"Vertical Gradient"}
								ground_surface_elev={site.dms_sa_site_ground_surface_elev}
								site_label={site.dms_site_id}
								geometry={site.geometry}></SiteDetailChartTable>
						)}
					</Grid>
				</AppContainer>
			);
		}
	}
}
SWGWSiteDetail = connect((state, ownProps) => ({}))(SWGWSiteDetail);

export default withStyles(styles)(SWGWSiteDetail);
